import { styled } from "@mui/material/styles";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

interface IExpandMoreButtonProps {
    expanded: boolean;
    handleExpandClick: () => void;
}

const ExpandMore = styled((props: IExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

export function ExpandMoreButton(props: IExpandMoreButtonProps) {
    return (
        <ExpandMore
            expand={props.expanded}
            onClick={props.handleExpandClick}
            aria-expanded={props.expanded}
            aria-label="show more"
        >
            <ExpandMoreIcon />
        </ExpandMore>
    );
}
