import { IData } from "data/common/IData";

export interface IUserData extends IData {
    data: IUser;
}

export const NullSite: ISite = {
    id: 0,
    name: ""
}

export const NullAccount: IAccount = {
    id: 0,
    name: "null",
    sites: [NullSite]
}

export const NullUser: IUser = {
    accounts: [NullAccount],
};

export interface IUser {
    accounts: IAccount[];
}

export interface IAccount {
    id: number;
    name: string;
    sites: ISite[];
}

export interface ISite {
    id: number;
    name: string;
}
