import { PointAggregation } from "../PointAggregation";
import {
    ActionType,
    ActionTypeKey,
    IUpdateEnergyEstimationDataAction,
    IUpdateEnergyEstimationDataLoadingAction,
} from "./actions";
import { IEnergyEstimation, IEnergyEstimationData } from "./models";

const INITIAL_STATE: IEnergyEstimationData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    pointAggregation: PointAggregation.YEAR,
    fromDate: "2022-01-01",
    toDate: "2022-12-31",
    generationTotal: 0,
    consumptionTotal: 0,
};

export default function energyEstimationReducer(
    state: IEnergyEstimationData = INITIAL_STATE,
    action: ActionType
): IEnergyEstimationData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_ENERGY_ESTIMATION_DATA:
            return updateEnergyEstimationData(action);
        case ActionTypeKey.UPDATE_ENERGY_ESTIMATION_DATA_LOADING:
            return updateEnergyEstimationDataLoading(action, state);
        default:
            return state;
    }
}

function updateEnergyEstimationData(
    action: IUpdateEnergyEstimationDataAction
): IEnergyEstimationData {
    let generationTotal = 0;
    let consumptionTotal = 0;

    if (action.payload.data.length > 0) {
        generationTotal = action.payload.data
            .map((x) => x.generation)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        consumptionTotal = action.payload.data
            .map((x) => x.consumption)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
    }

    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        pointAggregation: action.payload.pointAggregation,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
        generationTotal: generationTotal,
        consumptionTotal: consumptionTotal,
    };
}

function updateEnergyEstimationDataLoading(
    action: IUpdateEnergyEstimationDataLoadingAction,
    currentState: IEnergyEstimationData
): IEnergyEstimationData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}
