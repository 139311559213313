import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import { routeNames } from "components/router/RouteNames";
import HomeIcon from "@mui/icons-material/Home";

export default function AppBreadcrumbs() {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            style={{
                marginTop: "var(--max-length-medium1)",
                marginLeft: "var(--max-length-medium1)",
            }}
        >
            <Link
                underline="hover"
                color="inherit"
                href="/"
            >
                <HomeIcon />
            </Link>

            {pathnames.map((path: string, index: number) => {
                const to = `${pathnames.slice(0, index + 1).join("/")}`;

                return (
                    <Link
                        key={index}
                        underline="hover"
                        color="inherit"
                        href={"/" + to}
                    >
                        {routeNames[to]}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
}
