import { IProject } from "./models";

export enum ActionTypeKey {
    UPDATE_PROJECT_DATA = "UPDATE_PROJECT_DATA",
    UPDATE_PROJECT_DATA_LOADING = "UPDATE_PROJECT_DATA_LOADING",
}

export type ActionType =
    | IUpdateProjectDataAction
    | IUpdateProjectDataLoadingAction;

export interface IUpdateProjectDataAction {
    type: ActionTypeKey.UPDATE_PROJECT_DATA;
    payload: {
        accountId: number;
        data: IProject[];
    };
}

export interface IUpdateProjectDataLoadingAction {
    type: ActionTypeKey.UPDATE_PROJECT_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateProjectData(accountId: number, data: IProject[]): IUpdateProjectDataAction {
    return {
        type: ActionTypeKey.UPDATE_PROJECT_DATA,
        payload: {
            accountId: accountId,
            data: data,
        },
    };
}

export function updateProjectDataLoading(
    isLoading: boolean
): IUpdateProjectDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_PROJECT_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
