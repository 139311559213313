import {
    ActionType,
    ActionTypeKey,
    IUpdateUserDataAction,
    IUpdateUserDataLoadingAction,
} from "./actions";
import { IUserData, NullUser } from "./models";

const INITIAL_STATE: IUserData = {
    data: NullUser,
    requireLoad: true,
    hasData: false,
    isLoading: false,
};

export default function userReducer(
    state: IUserData = INITIAL_STATE,
    action: ActionType
): IUserData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_USER_DATA:
            return updateUserDataAction(action);
        case ActionTypeKey.UPDATE_USER_DATA_LOADING:
            return updateUserDataLoadingAction(action, state);
        default:
            return state;
    }
}

function updateUserDataAction(action: IUpdateUserDataAction): IUserData {
    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data !== NullUser,
        isLoading: false,
    };
}

function updateUserDataLoadingAction(
    action: IUpdateUserDataLoadingAction,
    currentState: IUserData
): IUserData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}
