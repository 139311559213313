import { IAccount } from "data/user/models";

export enum ActionTypeKey {
    UPDATE_GLOBAL_FILTER_ACCOUNT = "UPDATE_GLOBAL_FILTER_ACCOUNT",
    UPDATE_GLOBAL_FILTER_SITE_ID = "UPDATE_GLOBAL_FILTER_SITE_ID",
    UPDATE_GLOBAL_FILTER_YEAR = "UPDATE_GLOBAL_FILTER_YEAR",
}

export type ActionType =
    | IUpdateGlobalFilterAccountAction
    // | test
    | IUpdateGlobalFilterSiteIdAction
    | IUpdateGlobalFilterYearAction;

export interface IUpdateGlobalFilterAccountAction {
    type: ActionTypeKey.UPDATE_GLOBAL_FILTER_ACCOUNT;
    payload: {
        account: IAccount;
    };
}

export interface IUpdateGlobalFilterSiteIdAction {
    type: ActionTypeKey.UPDATE_GLOBAL_FILTER_SITE_ID;
    payload: {
        siteId: number;
    };
}

export interface IUpdateGlobalFilterYearAction {
    type: ActionTypeKey.UPDATE_GLOBAL_FILTER_YEAR;
    payload: {
        year: number;
    };
}

export function updateGlobalFilterAccount(
    account: IAccount
): IUpdateGlobalFilterAccountAction {
    return {
        type: ActionTypeKey.UPDATE_GLOBAL_FILTER_ACCOUNT,
        payload: {
            account: account,
        },
    };
}

export function updateGlobalFilterSiteId(
    siteId: number
): IUpdateGlobalFilterSiteIdAction {
    return {
        type: ActionTypeKey.UPDATE_GLOBAL_FILTER_SITE_ID,
        payload: {
            siteId: siteId,
        },
    };
}

export function updateGlobalFilterYear(
    year: number
): IUpdateGlobalFilterYearAction {
    return {
        type: ActionTypeKey.UPDATE_GLOBAL_FILTER_YEAR,
        payload: {
            year: year,
        },
    };
}
