import { Box, Typography } from "@mui/material";
import "./TabPanel.css";

export function SetTabProp(id: string, index: number) {
    return {
        id: `${id}-tab-${index}`,
        "aria-controls": `${id}-tabpanel-${index}`,
    };
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box sx={{ paddingTop: "var(--max-length-medium1)" }}>
                {value === index && children}
            </Box>
        </Typography>
    );
}
