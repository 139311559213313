import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./DataUnits.css";

export default function DataUnits(label: string) {
    return (
        <div className="dataUnits">
            <InfoOutlinedIcon className="dataUnitsIcon" fontSize="small" />
            <div className="dataUnitsText">{label}</div>
        </div>
    );
}
