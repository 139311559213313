import { Chart as ChartJS, registerables } from "chart.js";
import { PolarArea } from "react-chartjs-2";

import EsgBase from "assets/image/esg_radar_base.svg";
import EsgGrid from "assets/image/esg_radar_gridlines.svg";
import EsgScore from "assets/image/esg_score_indicators.svg";
import EsgSecStd from "assets/image/esg_secstd_ring.svg";
import { Stack } from "@mui/material";

ChartJS.register(...registerables);

export interface IEsgChartProps {
    score: number;
    data: number[];
    data2: number[];
    isSecValid: boolean;
}

export const EsgChartComponent = (props: IEsgChartProps) => {
    var style = getComputedStyle(document.body);
    var colorPrimary = style.getPropertyValue("--max-color-primary");
    var colorAccent = style.getPropertyValue("--max-color-accent");

    // const labels = [
    //     "Circular Energy Economy",
    //     "Circular Energy Economy Comparison",
    //     "Community Leadership",
    //     "Community Leadership Comparison",
    //     "Safety & Sustainability",
    //     "Safety & Sustainability Comparison",
    //     "Policy Strategy & Targets",
    //     "Policy Strategy & Targets Comparison",
    //     "Organisation Capability",
    //     "Organisation Capability Comparison",
    //     "Tools & Management",
    //     "Tools & Management Comparison",
    //     "Baseline Energy Data",
    //     "Baseline Energy Data Comparison",
    //     "Energy Resilience",
    //     "Energy Resilience Comparison",
    //     "Net Zero Energy",
    //     "Net Zero Energy Comparison",
    // ];

    const labels = [
        "Circular Energy Economy",
        "Community Leadership",
        "Safety & Sustainability",
        "Policy Strategy & Targets",
        "Organisation Capability",
        "Tools & Management",
        "Baseline Energy Data",
        "Energy Resilience",
        "Net Zero Energy",
    ];

    const dataVisible = {
        labels: labels,
        datasets: [
            {
                label: "ESG",
                data: props.data2,
                // backgroundColor: ["rgba(0, 0, 0, 0.8)", "rgba(88, 88, 88, 0.5)"],
                // backgroundColor: ["rgba(255, 255, 255, 0.0)"],
                backgroundColor: ["rgba(0, 0, 0, 0.3)"],
                // backgroundColor: ["#545454", "#ABAEB0"],
                // backgroundColor: ["blue", "#669ECC"],
                borderColor: "black",
                borderWidth: 0,
            },
            // {
            //     label: "ESG",
            //     data: props.data,
            //     // backgroundColor: ["rgba(0, 0, 0, 0.8)", "rgba(88, 88, 88, 0.5)"],
            //     backgroundColor: ["rgba(0, 0, 0, 0.4)"],
            //     // backgroundColor: ["#545454", "#ABAEB0"],
            //     // backgroundColor: ["blue", "#669ECC"],
            //     borderColor: "red",
            //     borderWidth: 0,
            // },
        ],
    };

    const dataTooltip = {
        labels: labels,
        datasets: [
            {
                label: "ESG",
                data: props.data,
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        scales: {
            r: {
                startAngle: 0,
                min: 0,
                max: 5,
                grid: {
                    z: 1,
                    display: false,
                },
                ticks: {
                    z: 1,
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
            },
        },
    };

    const ChartVisible = () => {
        return (
            <PolarArea
                data={dataVisible}
                options={options}
                style={{
                    borderRadius: "50%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 12,
                }}
            />
        );
    };

    const ChartInner = () => {
        return (
            <div style={{ height: "79%", width: "79%" }}>
                <ChartScore />
                <ChartVisible />
            </div>
        );
    };
    const ChartTooltip = () => {
        return (
            <PolarArea
                data={dataTooltip}
                options={options}
                style={{
                    borderRadius: "50%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 16,
                }}
            />
        );
    };

    const ChartScore = () => {
        return (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "10%",
                    height: "10%",
                    zIndex: 25,
                    background: "white",
                    borderRadius: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: props.isSecValid ? colorPrimary : colorAccent,
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                    }}
                >
                    {props.score}
                </div>
            </div>
        );
    };

    const ChartGradient = () => {
        return (
            <div
                style={{
                    position: "absolute",
                    borderRadius: "50%",
                    width: "79%",
                    height: "79%",
                    top: "50%",
                    left: "50%",
                }}
            >
                <div
                    style={{
                        zIndex: 13,
                        position: "absolute",
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                        transform: "translate(-50%, -50%)",
                        backgroundImage:
                            "radial-gradient(rgba(255,255,255,0.7) 10%,rgba(255,255,255,0.0))",
                    }}
                />
            </div>
        );
    };

    const ChartGrid = () => {
        return (
            <>
                
                <img
                    alt=""
                    src={EsgScore}
                    height="100%"
                    width="100%"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 16,
                    }}
                />
                <img
                    alt=""
                    src={EsgSecStd}
                    height="100%"
                    width="100%"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 15,
                    }}
                />
                <img
                    alt=""
                    src={EsgGrid}
                    height="100%"
                    width="100%"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 14,
                    }}
                />
            </>
        );
    };

    const ChartBase = () => {
        return (
            <img
                alt=""
                src={EsgBase}
                height="100%"
                width="100%"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 11,
                }}
            />
        );
    };

    return (
        <Stack spacing={2}>
            <div
                style={{
                    width: "470px",
                    height: "470px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        width: "450px",
                        height: "450px",
                        position: "relative",
                    }}
                >
                    <ChartTooltip />
                    <ChartInner />
                    <ChartGrid />
                    {/* <ChartGradient /> */}
                    <ChartBase />
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        border: "15px white solid",
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        zIndex: 9,
                    }}
                />
            </div>

            {/* <div
                style={{
                    display: "flex",
                    fontSize: "var(--max-font-size-subtitle)",
                    color: "var(--max-color-text)",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",

                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            background: "#545454",
                            width: "40px",
                            height: "12px",
                            marginLeft: "var(--max-length-small3)",
                            marginRight: "var(--max-length-small3)",
                        }}
                    />
                </div>
                <div style={{ marginRight: "var(--max-length-small1)" }}>
                    Your Score
                </div>
                <div
                    style={{
                        display: "flex",

                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            background: "#ABAEB0",
                            width: "40px",
                            height: "12px",
                            marginLeft: "var(--max-length-small3)",
                            marginRight: "var(--max-length-small3)",
                        }}
                    />
                </div>
                <div>Average Score</div>
            </div> */}
        </Stack>
    );
};
