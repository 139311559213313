import { PointAggregation } from "../PointAggregation";
import { IEnergyEstimation } from "./models";

export enum ActionTypeKey {
    UPDATE_ENERGY_ESTIMATION_DATA = "UPDATE_ENERGY_ESTIMATION_DATA",
    UPDATE_ENERGY_ESTIMATION_DATA_LOADING = "UPDATE_ENERGY_ESTIMATION_DATA_LOADING",
}

export type ActionType =
    | IUpdateEnergyEstimationDataAction
    | IUpdateEnergyEstimationDataLoadingAction;

export interface IUpdateEnergyEstimationDataAction {
    type: ActionTypeKey.UPDATE_ENERGY_ESTIMATION_DATA;
    payload: {
        pointAggregation: PointAggregation;
        fromDate: string;
        toDate: string;
        data: IEnergyEstimation[];
    };
}

export interface IUpdateEnergyEstimationDataLoadingAction {
    type: ActionTypeKey.UPDATE_ENERGY_ESTIMATION_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateEnergyEstimationData(
    pointAggregation: PointAggregation,
    fromDate: string,
    toDate: string,
    data: IEnergyEstimation[]
): IUpdateEnergyEstimationDataAction {
    return {
        type: ActionTypeKey.UPDATE_ENERGY_ESTIMATION_DATA,
        payload: {
            pointAggregation: pointAggregation,
            fromDate: fromDate,
            toDate: toDate,
            data: data,
        },
    };
}

export function updateEnergyEstimationDataLoading(
    isLoading: boolean
): IUpdateEnergyEstimationDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_ENERGY_ESTIMATION_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
