import { Card, CardContent, Stack } from "@mui/material";
import IStoreState from "store/IStoreState";
import { connect } from "react-redux";
import { ISecScoreGroupData } from "data/sec/secScore/models";
import { getSecScore } from "data/sec/secScore/services";
import { Metric } from "components/shared/metrics/Metric";
import ProjectSummary from "./ProjectSummary";

export interface IEsgDetailsProps {
    secScoreGroupData: ISecScoreGroupData;
}

function EsgDetails(props: IEsgDetailsProps) {
    function createMetric(
        isLoading: boolean,
        metric: string,
        title: "Governance" | "Environment" | "Social",
        text: string
    ) {
        let source = getSecScore(
            props.secScoreGroupData.groupFilter,
            props.secScoreGroupData.data
        );

        let value = source.environment;

        let color: "primary" | "accent" | "grey" = "primary";

        if (metric === "g") {
            color = "grey";
            value = source.governance;
        }

        if (metric === "s") {
            color = "accent";
            value = source.social;
        }

        return (
            <Stack direction="column" spacing={2}>
                <Metric
                    isLoadingIcon={isLoading}
                    isLoadingContent={false}
                    color={color}
                    variant="default"
                    value={value}
                    title={title}
                    content={
                        <Stack direction="column" spacing={2}>
                            <div>{text}</div>
                            <ProjectSummary criteria={title} />
                        </Stack>
                    }
                />
            </Stack>
        );
    }

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent>
                <Stack direction="column" spacing={4}>
                    {createMetric(
                        props.secScoreGroupData.isLoading,
                        "e",
                        "Environment",
                        "Organisational drive to reduce carbon footprint and mitigate the effects of greenhouse gas emissions, through reduction of overall energy usage, increasing renewable energy mix and minimising energy related emissions."
                    )}
                    {createMetric(
                        props.secScoreGroupData.isLoading,
                        "s",
                        "Social",
                        "Organisational culture and decision making aligned with community net zero expectations, including sustainability in design and procurement and proactive community leadership in supporting the transition to a circular energy economy."
                    )}
                    {createMetric(
                        props.secScoreGroupData.isLoading,
                        "g",
                        "Governance",
                        "Organisational commitment to the sustainable energy transition including establishment of meaningful stretch targets, setting realistic plans and the disciplined execution and delivery of actions to meet stated obligations."
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    secScoreGroupData: state.secScoreGroupData,
});

export default connect(mapStateToProps)(EsgDetails);
