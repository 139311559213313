import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";
import moment from "moment";
import {
    IDiselInvoice,
    IDiselInvoiceData,
    IGasInvoice,
    IGasInvoiceData,
    IInvoiceData,
} from "data/finance/invoices/models";
import { IGlobalFilter } from "data/global-filter/models";
import IStoreState from "store/IStoreState";
import { connect } from "react-redux";
import {
    loadDiselInvoiceData,
    loadGasInvoiceData,
    loadInvoiceData,
} from "data/finance/invoices/services";

interface IInvoice {
    id: number;
    siteName: string;
    account: string;
    nmi: string;
    usage: number;
    costExGst: number;
    days: number;
    fromDate: string;
    toDate: string;
    dueDate: string;
    status: string;
    notes: string;
}

interface IBillingProps {
    globalFilter: IGlobalFilter;
    invoiceData: IInvoiceData;
    gasInvoiceData: IGasInvoiceData;
    diselInvoiceData: IDiselInvoiceData;
}

function Billing(props: IBillingProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);

        loadInvoiceData(true, props.globalFilter.account.id);
        loadGasInvoiceData(true, props.globalFilter.account.id);
        loadDiselInvoiceData(true, props.globalFilter.account.id);
    }, [dataGridKey]);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Id",
            type: "string",
            minWidth: 100,
            flex: 1,
            hide: true,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "siteName",
            headerName: "Site Name",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
        },
        {
            field: "account",
            headerName: "Account",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "nmi",
            headerName: "NMI",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "usage",
            headerName: "Usage kWh",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "costExGst",
            headerName: "Cost ex. GST",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "days",
            headerName: "Days",
            type: "number",
            minWidth: 75,
            flex: 0.75,
            renderHeader: renderColumnHeader,
        },
        {
            field: "fromDate",
            headerName: "Start Date",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "toDate",
            headerName: "End Date",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "dueDate",
            headerName: "Due Date",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "notes",
            headerName: "Notes",
            minWidth: 100,
            flex: 3,
            renderHeader: renderColumnHeader,
        },
    ];

    function getRows() {
        if (!props.invoiceData.hasData) {
            return [];
        }

        return props.invoiceData.data.map((x: IInvoice) => ({
            id: x.id,
            siteName: x.siteName,
            account: x.account,
            nmi: x.nmi,
            usage: x.usage,
            costExGst: x.costExGst,
            days: x.days,
            fromDate: moment(x.fromDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
            toDate: moment(x.toDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
            dueDate: moment(x.dueDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
            status: x.status,
            notes: x.notes,
        }));
    }

    const gasColumns: GridColDef[] = [
        {
            field: "id",
            headerName: "Id",
            type: "string",
            minWidth: 100,
            flex: 1,
            hide: true,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "type",
            headerName: "Type",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
        },
        {
            field: "usage",
            headerName: "Usage MJ",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "costExGst",
            headerName: "Cost ex. GST",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "days",
            headerName: "Days",
            type: "number",
            minWidth: 75,
            flex: 0.75,
            renderHeader: renderColumnHeader,
        },
        {
            field: "fromDate",
            headerName: "Start Date",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "toDate",
            headerName: "End Date",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
    ];

    function getGasRows() {
        if (!props.gasInvoiceData.hasData) {
            return [];
        }

        return props.gasInvoiceData.data.map((x: IGasInvoice) => ({
            id: x.id,
            type: x.type,
            usage: x.usage,
            costExGst: x.costExGst,
            days: x.days,
            fromDate: moment(x.fromDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
            toDate: moment(x.toDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
        }));
    }

    const diselColumns: GridColDef[] = [
        {
            field: "id",
            headerName: "Id",
            type: "string",
            minWidth: 100,
            flex: 1,
            hide: true,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "type",
            headerName: "Type",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
        },
        {
            field: "usage",
            headerName: "Usage MJ",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "costExGst",
            headerName: "Cost ex. GST",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "days",
            headerName: "Days",
            type: "number",
            minWidth: 75,
            flex: 0.75,
            renderHeader: renderColumnHeader,
        },
        {
            field: "fromDate",
            headerName: "Start Date",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "toDate",
            headerName: "End Date",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
    ];

    function getDiselRows() {
        if (!props.diselInvoiceData.hasData) {
            return [];
        }

        return props.diselInvoiceData.data.map((x: IDiselInvoice) => ({
            id: x.id,
            type: x.type,
            usage: x.usage,
            costExGst: x.costExGst,
            days: x.days,
            fromDate: moment(x.fromDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
            toDate: moment(x.toDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
        }));
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Electricity Invoices" />
                    <CardContent>
                        <Stack direction="column" spacing={2}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    color="info"
                                    variant="outlined"
                                    href="https://maximumenergy.sharepoint.com/:f:/s/maximum/Eoupiq41IJZOhBAUMPT8jSEB-eatT2mbk0qFD6Fw6XjTNw?e=rTYFEX"
                                    target="_blank"
                                >
                                    View Invoices
                                </Button>
                                <Button
                                    color="info"
                                    variant="outlined"
                                    href="https://thepeoplesgrid.com/collectives/bowhill-engineering-community-energy-group"
                                    target="_blank"
                                >
                                    The People's Grid
                                </Button>
                            </Stack>
                            <StyledDataGrid
                                key={dataGridKey}
                                rows={getRows()}
                                columns={columns}
                                disableColumnMenu={true}
                                hideFooter={true}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                                density="compact"
                                autoHeight={true}
                                loading={false}
                            />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            {props.gasInvoiceData.hasData ? (
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Gas Invoices" />
                        <CardContent>
                            <Stack direction="column" spacing={2}>
                                {/* <Stack direction="row" spacing={2}></Stack> */}
                                <StyledDataGrid
                                    key={dataGridKey}
                                    rows={getGasRows()}
                                    columns={gasColumns}
                                    disableColumnMenu={true}
                                    hideFooter={true}
                                    checkboxSelection={false}
                                    hideFooterSelectedRowCount={true}
                                    density="compact"
                                    autoHeight={true}
                                    loading={false}
                                />
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}

            {props.diselInvoiceData.hasData ? (
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Disel Invoices" />
                        <CardContent>
                            <Stack direction="column" spacing={2}>
                                {/* <Stack direction="row" spacing={2}></Stack> */}
                                <StyledDataGrid
                                    key={dataGridKey}
                                    rows={getDiselRows()}
                                    columns={diselColumns}
                                    disableColumnMenu={true}
                                    hideFooter={true}
                                    checkboxSelection={false}
                                    hideFooterSelectedRowCount={true}
                                    density="compact"
                                    autoHeight={true}
                                    loading={false}
                                />
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
    invoiceData: state.invoiceData,
    gasInvoiceData: state.gasInvoiceData,
    diselInvoiceData: state.diselInvoiceData,
});

export default connect(mapStateToProps)(Billing);
