import { Box, CircularProgress } from "@mui/material";

interface IMetricIconProps {
    isLoading: boolean;
    color: "primary" | "accent" | "grey";
    value: number;
}
export function MetricIcon(props: IMetricIconProps) {
    let color = "var(--max-color-primary)";

    if (props.color === "grey") {
        color = "var(--max-color-grey)";
    }

    if (props.color === "accent") {
        color = "var(--max-color-accent)";
    }

    return props.isLoading ? (
        <Box
            sx={{
                height: "9rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <CircularProgress color="primary" />
        </Box>
    ) : (
        <div
            style={{
                height: "var(--max-size-score-circle-medium)",
                aspectRatio: "1",
                position: "relative",
            }}
        >
            <div
                style={{
                    border: `0.5rem ${color} solid`,
                    borderRadius: "50%",
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: color,
                        fontSize: "var(--max-font-size-title)",
                        fontWeight: "bold",
                    }}
                >
                    {props.value}
                </div>
            </div>
        </div>
    );
}
