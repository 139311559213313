import { GridRenderCellParams } from "@mui/x-data-grid";

export function renderCellSplitAlignRight2(params: GridRenderCellParams<string>) {
    let parts = params.value === undefined ? [] : params.value.split("-", 2);
    return (
        <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "50%"}} />
            <div style={{ width: "100%", textAlign: "right" }}>{parts[0]}</div>
            <div style={{ width: "100%", textAlign: "right" }}>{parts[1]}</div>
        </div>
    );
}


export function renderCellSplit2(params: GridRenderCellParams<string>) {
    let parts = params.value === undefined ? [] : params.value.split("-", 2);
    return (
        <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "50%" }} />
            <div style={{ width: "100%", textAlign: "right" }}>{parts[0]}</div>
            {/* <div style={{ width: "50%" }} /> */}
            <div style={{ width: "100%", textAlign: "right" }}>{parts[1]}</div>
            <div style={{ width: "50%" }} />
        </div>
    );
}
