import {
    ActionType,
    ActionTypeKey,
    IUpdateFinancialMetricDataAction,
    IUpdateFinancialMetricDataLoadingAction,
} from "./actions";
import { IFinancialMetricData, NullFinancialMetric } from "./models";



const INITIAL_STATE: IFinancialMetricData = {
    data: NullFinancialMetric,
    requireLoad: true,
    hasData: false,
    isLoading: false,
    accountId: 0,
};

export default function financialMetricReducer(
    state: IFinancialMetricData = INITIAL_STATE,
    action: ActionType
): IFinancialMetricData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_FINANCIAL_METRIC_DATA:
            return updateFinancialMetricDataAction(action);
        case ActionTypeKey.UPDATE_FINANCIAL_METRIC_DATA_LOADING:
            return updateFinancialMetricDataLoadingAction(action, state);
        default:
            return state;
    }
}

function updateFinancialMetricDataAction(
    action: IUpdateFinancialMetricDataAction
): IFinancialMetricData {
    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data !== NullFinancialMetric,
        isLoading: false,
        accountId: action.payload.accountId,
    };
}

function updateFinancialMetricDataLoadingAction(
    action: IUpdateFinancialMetricDataLoadingAction,
    currentState: IFinancialMetricData
): IFinancialMetricData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}
