import { IData } from "data/common/IData";

export interface IFinancialMetricData extends IData {
    data: IFinancialMetric;
    accountId: number;
}

export interface IFinancialMetric {
    npv: number;
    irr: number;
    carbonAbatementProjects: number;
    capitalInvestment: number;
}

export const NullFinancialMetric : IFinancialMetric = {
    npv: 0,
    irr: 0,
    carbonAbatementProjects: 0,
    capitalInvestment: 0
}