import { Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../shared/tab/TabPanel";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { Link, useLocation } from "react-router-dom";
import Overview from "./overview";

function Dashboard() {
    const location = useLocation();
    const path = "/" + location.pathname.split("/", 2)[1];
    const tab = location.pathname.split("/", 3)[2];

    return (
        <>
            <Tabs value={tab} textColor="secondary" indicatorColor="secondary">
                <Tab
                    icon={<DashboardOutlinedIcon />}
                    iconPosition="start"
                    label="Overview"
                    value="overview"
                    to={path + "/overview"}
                    component={Link}
                />
            </Tabs>

            <TabPanel value={tab} index="overview">
                <Overview />
            </TabPanel>
        </>
    );
}

export default Dashboard;
