import { connect } from "react-redux";
import { IProject, IProjectData } from "data/projects/project/models";
import IStoreState from "store/IStoreState";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Grid,
} from "@mui/material";
import { useState, useEffect } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { renderCellWrapped } from "components/shared/data-grid/CellWrapped";
import { TrueOnlyButton } from "components/shared/icons/TrueFalseIcon";
import { NoHoverDataGrid } from "components/shared/data-grid/NoHoverDataGrid";
import { MetricIcon } from "components/shared/metrics/MetricIcon";
import { ISecScoreGroupData } from "data/sec/secScore/models";
import { ExpandMoreButton } from "components/shared/collapse/ExpandMoreButton";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface ICriteriaComponentProps {
    projectData: IProjectData;
    secScoreGroupData: ISecScoreGroupData;

    color: "primary" | "accent" | "grey";
    criteria: string;
    criteriaGroup: string;
    description: string;
    score: number;
    scoreComments: string;
    info: string[];
}

function CriteriaComponent(props: ICriteriaComponentProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const commentColumns: GridColDef[] = [
        {
            field: "id",
            headerName: "Id",
            hide: true,
            type: "string",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "comments",
            headerName: "Current Score Comments",
            minWidth: 100,
            flex: 6,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWrapped,
        },
    ];

    function getCommentRows() {
        return [
            {
                id: 1,
                comments: props.scoreComments,
            },
        ];
    }

    const infoColumns: GridColDef[] = [
        {
            field: "id",
            headerName: "Score",
            minWidth: 100,
            flex: 1,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
        },
        {
            field: "info",
            headerName: "Actions to Achive Score",
            minWidth: 100,
            flex: 100,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWrapped,
        },
    ];

    function getInfoRows() {
        return props.info.map((x: string, i: number) => ({
            id: i + 1,
            info: x,
        }));
    }

    const projectColumns: GridColDef[] = [
        {
            field: "stage",
            headerName: "Stage",
            minWidth: 75,
            flex: 0.75,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
        },
        {
            field: "project",
            headerName: "Project",
            minWidth: 100,
            flex: 3,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWrapped,
        },
        {
            field: "id",
            headerName: "Code",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "esgE",
            headerName: "E",
            minWidth: 50,
            flex: 0.5,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
        {
            field: "esgS",
            headerName: "S",
            minWidth: 50,
            flex: 0.5,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
        {
            field: "esgG",
            headerName: "G",
            minWidth: 50,
            flex: 0.5,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
        {
            field: "budget",
            headerName: "Budget",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "priority",
            headerName: "Priority",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "date",
            headerName: "Date",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
    ];

    function getProjectRows() {
        if (!props.projectData.hasData) {
            return [];
        }

        let filteredData = props.projectData.data.filter((x) =>
            x.categories.includes(props.criteria)
        );
        let rows = filteredData.map((x: IProject) => ({
            stage: x.stage,
            project: x.name,
            id: x.code,
            category: x.category,
            esgE: x.esgE,
            esgS: x.esgS,
            esgG: x.esgG,
            budget: x.budgetString,
            priority: x.priorityString,
            date: x.dateString,
            status: x.status,
        }));

        // rows.sort((a, b) => {
        //     return a.stage - b.stage;
        // });

        return rows;
    }

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    function getScoreCommentComponent() {
        return (
            <NoHoverDataGrid
                key={"comment" + dataGridKey}
                rows={getCommentRows()}
                columns={commentColumns}
                disableColumnMenu={true}
                hideFooter={true}
                checkboxSelection={false}
                hideFooterSelectedRowCount={true}
                density="compact"
                autoHeight={true}
                getRowHeight={() => "auto"}
                disableSelectionOnClick={true}
            />
        );
    }

    function getScoreInfoComponent() {
        return (
            <Box
                sx={{
                    width: "100%",
                    "& .score-theme--selected": {
                        backgroundColor:
                            "var(--max-color-table-highlight-primary)",
                    },
                }}
            >
                <StyledDataGrid
                    key={"info" + dataGridKey}
                    rows={getInfoRows()}
                    columns={infoColumns}
                    disableColumnMenu={true}
                    hideFooter={true}
                    checkboxSelection={false}
                    hideFooterSelectedRowCount={true}
                    density="compact"
                    autoHeight={true}
                    getRowHeight={() => "auto"}
                    getRowClassName={(params) =>
                        `score-theme--${
                            params.row.id === props.score
                                ? "selected"
                                : "unselected"
                        }`
                    }
                />
            </Box>
        );
    }

    function getProjectComponent() {
        return (
            <StyledDataGrid
                key={dataGridKey}
                rows={getProjectRows()}
                columns={projectColumns}
                disableColumnMenu={true}
                hideFooter={true}
                checkboxSelection={false}
                hideFooterSelectedRowCount={true}
                density="compact"
                autoHeight={true}
                loading={props.projectData.isLoading}
                getRowHeight={() => "auto"}
            />
        );
    }

    return (
        <Grid item xs={12} lg={expanded ? 12 : 6} xl={expanded ? 12 : 4}>
            <Card sx={{ height: "100%" }}>
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "var(--max-length-medium1)",
                        }}
                    >
                        <MetricIcon
                            isLoading={props.secScoreGroupData.isLoading}
                            color={props.color}
                            value={props.score}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <CardHeader
                            title={props.criteria}
                            subheader={props.criteriaGroup}
                            action={
                                <ExpandMoreButton
                                    expanded={expanded}
                                    handleExpandClick={handleExpandClick}
                                />
                            }
                        />
                    </div>
                </div>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} xl={6}>
                                <div>{props.description}</div>
                            </Grid>
                            <Grid item xs={12} xl={6}></Grid>

                            <Grid item xs={12} xl={6}>
                                {getScoreInfoComponent()}
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                {getScoreCommentComponent()}
                            </Grid>
                            <Grid item xs={12}>
                                {getProjectComponent()}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    projectData: state.projectData,
    secScoreGroupData: state.secScoreGroupData,
});

export default connect(mapStateToProps)(CriteriaComponent);
