import { PointAggregation } from "../PointAggregation";
import {
    ActionType,
    ActionTypeKey,
    IUpdateEnergyLoadPointDataAction,
    IUpdateEnergyLoadPointDataLoadingAction,
} from "./actions";
import { IEnergyLoadPointData } from "./models";

const INITIAL_STATE: IEnergyLoadPointData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    pointAggregation: PointAggregation.YEAR,
    fromDate: "2022-01-01",
    toDate: "2022-12-31",
    load1Total: 0,
    load2Total: 0,
    load3Total: 0,
    load4Total: 0,
    load5Total: 0,
    load6Total: 0,
    loadTotal: 0,
};

export default function energyLoadPointReducer(
    state: IEnergyLoadPointData = INITIAL_STATE,
    action: ActionType
): IEnergyLoadPointData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_ENERGY_LOAD_POINT_DATA:
            return updateEnergyLoadPointData(action);
        case ActionTypeKey.UPDATE_ENERGY_LOAD_POINT_DATA_LOADING:
            return updateEnergyLoadPointDataLoading(action, state);
        default:
            return state;
    }
}

function updateEnergyLoadPointData(
    action: IUpdateEnergyLoadPointDataAction
): IEnergyLoadPointData {
    let load1Total = 0;
    let load2Total = 0;
    let load3Total = 0;
    let load4Total = 0;
    let load5Total = 0;
    let load6Total = 0;

    if (action.payload.data.length > 0) {
        load1Total = action.payload.data
            .map((x) => x.load1)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        load2Total = action.payload.data
            .map((x) => x.load2)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        load3Total = action.payload.data
            .map((x) => x.load3)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        load4Total = action.payload.data
            .map((x) => x.load4)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        load5Total = action.payload.data
            .map((x) => x.load5)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        load6Total = action.payload.data
            .map((x) => x.load6)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
    }

    let loadTotal =
        load1Total +
        load2Total +
        load3Total +
        load4Total +
        load5Total +
        load6Total;

    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        pointAggregation: action.payload.pointAggregation,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
        load1Total: load1Total,
        load2Total: load2Total,
        load3Total: load3Total,
        load4Total: load4Total,
        load5Total: load5Total,
        load6Total: load6Total,
        loadTotal: loadTotal,
    };
}

function updateEnergyLoadPointDataLoading(
    action: IUpdateEnergyLoadPointDataLoadingAction,
    currentState: IEnergyLoadPointData
): IEnergyLoadPointData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}
