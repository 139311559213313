import { ISecScoreGroup, SecScoreType } from "./models";

export enum ActionTypeKey {
    UPDATE_SEC_SCORE_GROUP_DATA = "UPDATE_SEC_SCORE_GROUP_DATA",
    UPDATE_SEC_SCORE_GROUP_DATA_LOADING = "UPDATE_SEC_SCORE_GROUP_DATA_LOADING",
    UPDATE_SEC_SCORE_GROUP_DATA_FILTER = "UPDATE_SEC_SCORE_GROUP_DATA_FILTER",
}

export type ActionType =
    | IUpdateSecScoreGroupDataAction
    | IUpdateSecScoreGroupDataLoadingAction
    | IUpdateSecScoreGroupDataFilterAction;

export interface IUpdateSecScoreGroupDataAction {
    type: ActionTypeKey.UPDATE_SEC_SCORE_GROUP_DATA;
    payload: {
        accountId: number;
        data: ISecScoreGroup;
    };
}

export interface IUpdateSecScoreGroupDataLoadingAction {
    type: ActionTypeKey.UPDATE_SEC_SCORE_GROUP_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export interface IUpdateSecScoreGroupDataFilterAction {
    type: ActionTypeKey.UPDATE_SEC_SCORE_GROUP_DATA_FILTER;
    payload: {
        groupFilter: SecScoreType;
    };
}

export function updateSecScoreGroupData(
    accountId: number,
    data: ISecScoreGroup
): IUpdateSecScoreGroupDataAction {
    return {
        type: ActionTypeKey.UPDATE_SEC_SCORE_GROUP_DATA,
        payload: {
            accountId: accountId,
            data: data,
        },
    };
}

export function updateSecScoreGroupDataLoading(
    isLoading: boolean
): IUpdateSecScoreGroupDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_SEC_SCORE_GROUP_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}

export function updateSecScoreGroupDataFilter(
    groupFilter: SecScoreType
): IUpdateSecScoreGroupDataFilterAction {
    return {
        type: ActionTypeKey.UPDATE_SEC_SCORE_GROUP_DATA_FILTER,
        payload: {
            groupFilter: groupFilter,
        },
    };
}
