import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
    IUpdateGlobalFilterYearAction,
    updateGlobalFilterYear,
} from "data/global-filter/actions";
import IStoreState from "store/IStoreState";
import { IGlobalFilter } from "data/global-filter/models";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";

interface IYearFilterProps {
    globalFilter: IGlobalFilter;

    updateGlobalFilterYear: (year: number) => IUpdateGlobalFilterYearAction;
}

function YearFilter(props: IYearFilterProps) {
    function onChange(event: SelectChangeEvent<number>) {
        props.updateGlobalFilterYear(Number(event.target.value));
    }

    return (
        <FormControl>
            <InputLabel id="global-filter-year-label">Year</InputLabel>
            <Select
                labelId="global-filter-year-select"
                id="global-filter-year"
                value={props.globalFilter.year}
                label="Year"
                onChange={onChange}
            >
                <MenuItem value="2022">2022</MenuItem>
                {/* <MenuItem value="2021">2021</MenuItem> */}
            </Select>
        </FormControl>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateGlobalFilterYear: bindActionCreators(
        updateGlobalFilterYear,
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(YearFilter);
