import qs from "qs";
import ApiService from "services/ApiService";
import { IDiselInvoice, IGasInvoice, IInvoice } from "./models";
import { store } from "store/store";
import { updateDiselInvoiceData, updateDiselInvoiceDataLoading, updateGasInvoiceData, updateGasInvoiceDataLoading, updateInvoiceData, updateInvoiceDataLoading } from "./actions";

export function loadInvoiceData(useCache: boolean, accountId: number) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const invoiceData = store.getState().invoiceData;

    if (
        !invoiceData.isLoading &&
        (!useCache ||
            invoiceData.requireLoad ||
            invoiceData.accountId !== accountId)
    ) {
        store.dispatch(updateInvoiceDataLoading(true));

        ApiService.get(`/finance/GetInvoices`, {
            params: {
                accountId: accountId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = res.data.map(
                    (x: any) =>
                        ({
                            id: x.id,
                            siteName: x.siteName,
                            account: x.account,
                            nmi: x.nmi,
                            usage: x.usage,
                            costExGst: x.costExGst,
                            days: x.days,
                            fromDate: x.fromDate,
                            toDate: x.toDate,
                            dueDate: x.dueDate,
                            status: x.status,
                            notes: x.notes,
                        } as IInvoice)
                );

                store.dispatch(updateInvoiceData(accountId, data));
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(updateInvoiceDataLoading(false));
            });
    }
}


export function loadGasInvoiceData(useCache: boolean, accountId: number) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const gasInvoiceData = store.getState().gasInvoiceData;

    if (
        !gasInvoiceData.isLoading &&
        (!useCache ||
            gasInvoiceData.requireLoad ||
            gasInvoiceData.accountId !== accountId)
    ) {
        store.dispatch(updateGasInvoiceDataLoading(true));

        ApiService.get(`/finance/GetGasInvoices`, {
            params: {
                accountId: accountId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = res.data.map(
                    (x: any) =>
                        ({
                            id: x.id,
                            type: x.type,
                            usage: x.usage,
                            costExGst: x.costExGst,
                            days: x.days,
                            fromDate: x.fromDate,
                            toDate: x.toDate,
                        } as IGasInvoice)
                );

                store.dispatch(updateGasInvoiceData(accountId, data));
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(updateGasInvoiceDataLoading(false));
            });
    }
}

export function loadDiselInvoiceData(useCache: boolean, accountId: number) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const diselInvoiceData = store.getState().diselInvoiceData;

    if (
        !diselInvoiceData.isLoading &&
        (!useCache ||
            diselInvoiceData.requireLoad ||
            diselInvoiceData.accountId !== accountId)
    ) {
        store.dispatch(updateDiselInvoiceDataLoading(true));

        ApiService.get(`/finance/GetDiselInvoices`, {
            params: {
                accountId: accountId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = res.data.map(
                    (x: any) =>
                        ({
                            id: x.id,
                            type: x.type,
                            usage: x.usage,
                            costExGst: x.costExGst,
                            days: x.days,
                            fromDate: x.fromDate,
                            toDate: x.toDate,
                        } as IDiselInvoice)
                );

                store.dispatch(updateDiselInvoiceData(accountId, data));
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(updateDiselInvoiceDataLoading(false));
            });
    }
}
