import { connect } from "react-redux";
import {
    Card,
    CardContent,
    CardHeader,
    LinearProgress,
    Stack,
} from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import IStoreState from "store/IStoreState";
import {
    IEnergyPoint,
    IEnergyPointData,
} from "data/energy/energy-point/models";
import DataUnits from "components/shared/label/DataUnits";
import { useState, useEffect } from "react";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { renderCellChartBarPrimaryWithTextDp0 } from "components/shared/data-grid/CellChartBar";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";
import { IEnergyEstimationData } from "data/energy/energy-estimation/models";

interface IComparisonBreakdownProp {
    energyPointData: IEnergyPointData;
    energyEstimationData: IEnergyEstimationData;
}

function ComparisonBreakdown(props: IComparisonBreakdownProp) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    var style = getComputedStyle(document.body);
    var barColor1 = style.getPropertyValue("--max-color-chart-primary");
    var barColor2 = style.getPropertyValue("--max-color-chart-grey");
    var barColor3 = style.getPropertyValue("--max-color-chart-accent");

    ChartJS.register(...registerables);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top" as const,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    function createChart() {
        return (
            <>
                <div
                    style={{
                        height: "var(--max-size-chart-large)",
                        width: "99%",
                    }}
                >
                    <Chart type="bar" options={options} data={getChartData()} />
                </div>
                {props.energyPointData.isLoading ? <LinearProgress /> : <></>}
                {DataUnits("Chart Units: MWh")}
            </>
        );
    }

    function getChartData() {
        let labels = props.energyPointData.data.map(
            (x: { timestamp: any }) => x.timestamp
        );

        return {
            labels,
            datasets: [
                {
                    type: "line" as const,
                    label: "Estimated Generation",
                    data: props.energyEstimationData.data.map(
                        (x: { generation: number }) => x.generation / 1000
                    ),
                    backgroundColor: barColor3,
                },
                {
                    type: "bar" as const,
                    label: "Self Consumption",
                    data: props.energyPointData.data.map(
                        (x: { selfConsumption: number }) => x.selfConsumption / 1000
                    ),
                    backgroundColor: barColor1,
                },
                {
                    type: "bar" as const,
                    label: "Export to Grid",
                    data: props.energyPointData.data.map(
                        (x: { exportToGrid: number }) => x.exportToGrid / 1000
                    ),
                    backgroundColor: barColor2,
                },
                
            ],
        };
    }

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Period",
            type: "string",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "generation",
            headerName: "Generation",
            type: "number",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "consumption",
            headerName: "Consumption",
            type: "number",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "selfConsumption",
            headerName: "Self Consumption",
            type: "number",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "exportToGrid",
            headerName: "Export to Grid",
            type: "number",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "ratio",
            headerName: "Self Consumption Ratio",
            headerAlign: "center",
            type: "number",
            minWidth: 200,
            flex: 2,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellChartBarPrimaryWithTextDp0,
        },
    ];

    function getRows() {
        if (props.energyPointData.data.length < 1) {
            return [];
        }

        let rows = props.energyPointData.data.map((x: IEnergyPoint) => ({
            id: x.timestamp,
            generation: (x.generation / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            consumption: (x.consumption / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            selfConsumption: (x.selfConsumption / 1000).toLocaleString(
                undefined,
                {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }
            ),
            exportToGrid: (x.exportToGrid / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio:
                x.generation > 0 ? (x.selfConsumption / x.generation) * 100 : 0,
        }));

        rows.push({
            id: "Total",
            generation: (
                props.energyPointData.generationTotal / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            consumption: (
                props.energyPointData.consumptionTotal / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            selfConsumption: (
                props.energyPointData.selfConsumptionTotal / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            exportToGrid: (
                props.energyPointData.exportToGridTotal / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio:
                props.energyPointData.generationTotal > 0
                    ? (props.energyPointData.selfConsumptionTotal /
                          props.energyPointData.generationTotal) *
                      100
                    : 0,
        });
        return rows;
    }

    return (
        <Card>
            <CardHeader title="Monthly Comparison Breakdown" />
            <CardContent>
                <Stack spacing={2} direction="column">
                    {createChart()}
                    <StyledDataGrid
                        key={dataGridKey}
                        rows={getRows()}
                        columns={columns}
                        disableColumnMenu={true}
                        hideFooter={true}
                        checkboxSelection={false}
                        hideFooterSelectedRowCount={true}
                        density="compact"
                        autoHeight={true}
                        loading={props.energyPointData.isLoading}
                    />
                    {DataUnits("Table Units: MWh")}
                </Stack>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    energyPointData: state.energyPointData,
    energyEstimationData: state.energyEstimationData,
});

export default connect(mapStateToProps)(ComparisonBreakdown);
