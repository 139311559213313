import { Tab, Tabs } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import { TabPanel } from "../../shared/tab/TabPanel";
import Energy from "./usage";
import { Link, useLocation } from "react-router-dom";
import NetCarbon from "./net-carbon";
import ParkIcon from "@mui/icons-material/Park";

function EnergyBreakdown() {
    const location = useLocation();
    const path = "/" + location.pathname.split("/", 2)[1];
    const tab = location.pathname.split("/", 3)[2];

    return (
        <>
            <Tabs value={tab} textColor="secondary" indicatorColor="secondary">
                <Tab
                    icon={<BoltIcon />}
                    iconPosition="start"
                    label="Usage"
                    value="usage"
                    to={path + "/usage"}
                    component={Link}
                />
                <Tab
                    icon={<ParkIcon />}
                    iconPosition="start"
                    label="Net Carbon"
                    value="netcarbon"
                    to={path + "/netcarbon"}
                    component={Link}
                />
            </Tabs>

            <TabPanel value={tab} index="usage">
                <Energy />
            </TabPanel>
            <TabPanel value={tab} index="netcarbon">
                <NetCarbon />
            </TabPanel>
        </>
    );
}

export default EnergyBreakdown;
