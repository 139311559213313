import { Card, CardContent, CardHeader } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { ILightingAssetData } from "data/assets/lighting-asset/models";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { renderCellSplitAlignRight2 } from "components/shared/data-grid/CellValueSplit";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface IAssetByRoomProps {
    lightingAssetData: ILightingAssetData;

    pageSize: number;
    setPageSize: (size: number) => void;
}

function AssetByRoom(props: IAssetByRoomProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Room",
            type: "string",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "topAsset",
            headerName: "Top Tech",
            type: "string",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "assetQuantity",
            headerName: "Quantity in Room",
            type: "number",
            headerAlign: "right",
            minWidth: 200,
            flex: 2,
            renderHeader: renderColumnHeader,
            renderCell: renderCellSplitAlignRight2,
        },
    ];

    type KeyValue = {
        key: string;
        value: number;
    };

    function getRows() {
        if (props.lightingAssetData.data.length < 1) {
            return [];
        }

        let groups = Array.from(
            new Set(props.lightingAssetData.data.map((x) => x.room.trim()))
        );

        let rows = groups.map((group) => {
            let filteredData = props.lightingAssetData.data.filter(
                (x) => x.room.trim() === group
            );

            let quantity = filteredData
                .map((x) => x.noOfLamps)
                .reduce((prev: number, curr: number) => {
                    return prev + curr;
                });

            let assetGroups: KeyValue[] = [];
            filteredData.forEach((x) => {
                let assetGroup = assetGroups.find(
                    (g) => g.key === x.tech.trim()
                );
                if (assetGroup === undefined) {
                    assetGroup = { key: x.tech.trim(), value: 0 };
                    assetGroups.push(assetGroup);
                }

                assetGroup.value += x.noOfLamps;
            });

            assetGroups.sort((a, b) => {
                return b.value - a.value;
            });

            let assetQuantity =
                assetGroups[0].value +
                "-" +
                ((assetGroups[0].value / quantity) * 100).toFixed(0) +
                "%";

            return {
                id: group,
                quantity: quantity,
                topAsset: assetGroups[0].key,
                assetQuantity: assetQuantity,
            };
        });

        rows.sort((a, b) => {
            return b.quantity - a.quantity;
        });

        return rows;
    }

    return (
        <Card>
            <CardHeader title="Lighting By Room" />
            <CardContent>
                <StyledDataGrid
                    key={dataGridKey}
                    rows={getRows()}
                    columns={columns}
                    pageSize={props.pageSize}
                    onPageSizeChange={(newPageSize) =>
                        props.setPageSize(newPageSize)
                    }
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    checkboxSelection={false}
                    hideFooterSelectedRowCount={true}
                    density="compact"
                    autoHeight={true}
                    loading={props.lightingAssetData.isLoading}
                />
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    lightingAssetData: state.lightingAssetData,
});

export default connect(mapStateToProps)(AssetByRoom);
