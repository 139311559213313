import { PointAggregation } from "../PointAggregation";
import { IEnergyLoadPoint } from "./models";
import { store } from "store/store";
import ApiService from "services/ApiService";
import qs from "qs";
import moment from "moment";
import {
    updateEnergyLoadPointData,
    updateEnergyLoadPointDataLoading,
} from "./actions";

export function loadEnergyLoadPoints(
    useCache: boolean,
    pointAggregation: PointAggregation,
    siteId: number,
    fromDate: Date,
    toDate: Date
) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const energyLoadPointData = store.getState().energyLoadPointData;
    
    if (
        !energyLoadPointData.isLoading &&
        (!useCache ||
            energyLoadPointData.requireLoad ||
            pointAggregation !== energyLoadPointData.pointAggregation ||
            fromDate.toDateString() !== energyLoadPointData.fromDate ||
            toDate.toDateString() !== energyLoadPointData.toDate)
    ) {
        store.dispatch(updateEnergyLoadPointDataLoading(true));

        ApiService.get(`/energy/GetEnergyLoadPoints`, {
            params: {
                pointAggregation: pointAggregation,
                siteId: siteId,
                fromDate: fromDate,
                toDate: toDate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = res.data.map(
                    (x: any) =>
                        ({
                            timestamp: moment(x.timestamp, "YYYY-MM-DD").format(
                                "MMM YYYY"
                            ),
                            load1: x.load1,
                            load2: x.load2,
                            load3: x.load3,
                            load4: x.load4,
                            load5: x.load5,
                            load6: x.load6,
                        } as IEnergyLoadPoint)
                );

                store.dispatch(
                    updateEnergyLoadPointData(
                        pointAggregation,
                        fromDate.toDateString(),
                        toDate.toDateString(),
                        data
                    )
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
