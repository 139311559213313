import { PointAggregation } from "../PointAggregation";
import { IEnergyEstimation } from "./models";
import { store } from "store/store";
import ApiService from "services/ApiService";
import qs from "qs";
import moment from "moment";
import { updateEnergyEstimationData, updateEnergyEstimationDataLoading } from "./actions";

export function loadEnergyEstimations(
    useCache: boolean,
    pointAggregation: PointAggregation,
    siteId: number,
    fromDate: Date,
    toDate: Date
) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const energyEstimationData = store.getState().energyEstimationData;

    if (
        !energyEstimationData.isLoading &&
        (!useCache ||
            energyEstimationData.requireLoad ||
            pointAggregation !== energyEstimationData.pointAggregation ||
            fromDate.toDateString() !== energyEstimationData.fromDate ||
            toDate.toDateString() !== energyEstimationData.toDate)
    ) {
        store.dispatch(updateEnergyEstimationDataLoading(true));

        ApiService.get(`/energy/GetEnergyEstimations`, {
            params: {
                pointAggregation: pointAggregation,
                siteId: siteId,
                fromDate: fromDate,
                toDate: toDate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = res.data.map(
                    (x: any) =>
                        ({
                            timestamp: moment(x.timestamp, "YYYY-MM-DD").format(
                                "MMM YYYY"
                            ),
                            generation: x.generation,
                            consumption: x.consumption,
                        } as IEnergyEstimation)
                );

                store.dispatch(
                    updateEnergyEstimationData(
                        pointAggregation,
                        fromDate.toDateString(),
                        toDate.toDateString(),
                        data
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(updateEnergyEstimationDataLoading(false));
            });
    }
}
