import { NullAccount } from "data/user/models";
import {
    ActionType,
    ActionTypeKey,
    IUpdateGlobalFilterAccountAction,
    IUpdateGlobalFilterSiteIdAction,
    IUpdateGlobalFilterYearAction,
} from "./actions";
import { IGlobalFilter } from "./models";

const INITIAL_STATE: IGlobalFilter = {
    requireLoad: true,
    account: NullAccount,
    siteId: 0,
    year: 2022,
    yearStart: "2022-01-01",
    yearEnd: "2022-12-31",
};

export default function globalFilterReducer(
    state: IGlobalFilter = INITIAL_STATE,
    action: ActionType
): IGlobalFilter {
    switch (action.type) {
        case ActionTypeKey.UPDATE_GLOBAL_FILTER_ACCOUNT:
            return updateGlobalFilterAccountAction(action, state);
        case ActionTypeKey.UPDATE_GLOBAL_FILTER_SITE_ID:
            return updateGlobalFilterSiteIdAction(action, state);
        case ActionTypeKey.UPDATE_GLOBAL_FILTER_YEAR:
            return updateGlobalFilterYearAction(action, state);
        default:
            return state;
    }
}

function updateGlobalFilterAccountAction(
    action: IUpdateGlobalFilterAccountAction,
    currentState: IGlobalFilter
): IGlobalFilter {
    return {
        ...currentState,
        requireLoad: false,
        account: action.payload.account,
    };
}

function updateGlobalFilterSiteIdAction(
    action: IUpdateGlobalFilterSiteIdAction,
    currentState: IGlobalFilter
): IGlobalFilter {
    return {
        ...currentState,
        requireLoad: false,
        siteId: action.payload.siteId,
    };
}

function updateGlobalFilterYearAction(
    action: IUpdateGlobalFilterYearAction,
    currentState: IGlobalFilter
): IGlobalFilter {
    return {
        ...currentState,
        requireLoad: false,
        year: action.payload.year,
        yearStart: `${action.payload.year}-01-01`,
        yearEnd: `${action.payload.year}-12-31`
    };
}
