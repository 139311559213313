import { ISecScore } from "data/sec/secScore/models";

export function getTarget65Progress(secScore?: ISecScore) {
    return secScore === undefined ? 0 : (secScore.scorePercent / 65) * 100;
}

export function getTarget80Progress(secScore?: ISecScore) {
    if (secScore === undefined) {
        return 0;
    }

    // Target 2028
    // 50% - 90% SEC progress
    // 50% - min 4 score progress
    let partA = secScore.scorePercent / 80;
    let partB =
        ((secScore.policyStrategyTargets >= 4 ? 1 : 0) +
            (secScore.organisationalCapability >= 4 ? 1 : 0) +
            ((secScore.toolsMeasurement ?? 0) >= 4 ? 1 : 0) +
            (secScore.baselineEnergyData >= 4 ? 1 : 0) +
            ((secScore.energyResilience ?? 0) >= 4 ? 1 : 0) +
            ((secScore.netZeroEnergy ?? 0) >= 4 ? 1 : 0) +
            (secScore.circularEnergyEconomy >= 4 ? 1 : 0) +
            (secScore.communityLeadership >= 4 ? 1 : 0) +
            (secScore.safetySustainability >= 4 ? 1 : 0)) /
        9;

    return (partA * 0.5 + partB * 0.5) * 100;
}
