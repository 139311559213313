import { ISecRoadmap } from "./Models";
import { Chart as ChartJS, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";

interface IRoadmapComponentProps {
    secRoadmap: ISecRoadmap;
    isLoading: boolean;
}

export function RoadmapComponent(props: IRoadmapComponentProps) {
    var style = getComputedStyle(document.body);
    var chartColor1 = style.getPropertyValue("--max-color-chart-primary");
    var chartColor2 = style.getPropertyValue("--max-color-chart-grey");

    ChartJS.register(...registerables);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    function getChartData() {
        return {
            labels: ["Completed", "Remaining"],
            datasets: [
                {
                    label: "Progress",
                    data: [
                        props.secRoadmap.progress,
                        100 - props.secRoadmap.progress,
                    ],
                    backgroundColor: [chartColor1, chartColor2],
                },
            ],
        };
    }

    return (
        <div
            style={{
                display: "flex",
                height: "6.0rem",
                width: "99%",
                paddingLeft: "10%",
                paddingRight: "10%",
            }}
        >
            <Box
                style={{
                    height: "6.0rem",
                    width: "9.0rem",
                }}
            >
                {props.isLoading ? (
                    <Box
                        sx={{
                            height: "6rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress color="primary" />
                    </Box>
                ) : (
                    <Doughnut options={options} data={getChartData()} />
                )}
            </Box>
            <Box style={{ marginTop: "var(--max-length-medium1)" }}>
                <Stack>
                    <Typography
                        style={{
                            fontSize: "var(--max-font-size-header)",
                            // fontWeight: "bold",
                            color: "var(--max-color-title)"
                        }}
                    >
                        Target {props.secRoadmap.date}
                    </Typography>
                    <Typography
                        style={{
                            fontSize: "var(--max-font-size-text)",
                        }}
                    >
                        {props.secRoadmap.title}
                    </Typography>
                </Stack>
            </Box>
        </div>
    );
}
