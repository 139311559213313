export function dataGridFix(
    dataGridKey: number,
    setDataGridKey: (value: number) => void
) {
    // DataGrid has a bug where resizing causes end columns to get dropped
    // Workaround is to force component to rerender by updating key
    // Issue exists in Chrome, not in Edge
    let timeout: any;
    const handleResize = () => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setDataGridKey(dataGridKey + 1);
        }, 100);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
}
