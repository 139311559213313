import {
    ActionType,
    ActionTypeKey,
    IUpdateLightingAssetDataAction,
    IUpdateLightingAssetDataLoadingAction,
} from "./actions";
import { ILightingAssetData } from "./models";

const INITIAL_STATE: ILightingAssetData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    siteId: 0
};

export default function lightingAssetReducer(
    state: ILightingAssetData = INITIAL_STATE,
    action: ActionType
): ILightingAssetData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_LIGHTINGASSET_DATA:
            return updateLightingAssetDataAction(action);
        case ActionTypeKey.UPDATE_LIGHTINGASSET_DATA_LOADING:
            return updateLightingAssetDataLoadingAction(action, state);
        default:
            return state;
    }
}

function updateLightingAssetDataAction(
    action: IUpdateLightingAssetDataAction
): ILightingAssetData {
    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        siteId: action.payload.siteId
    };
}

function updateLightingAssetDataLoadingAction(
    action: IUpdateLightingAssetDataLoadingAction,
    currentState: ILightingAssetData
): ILightingAssetData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}
