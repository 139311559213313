import qs from "qs";
import { store } from "store/store";
import ApiService from "services/ApiService";
import { ILightingAsset } from "./models";
import {
    updateLightingAssetData,
    updateLightingAssetDataLoading,
} from "./actions";

export function getLightingAssets(useCache: boolean, siteId: number) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const lightingAssetData = store.getState().lightingAssetData;

    if (
        !lightingAssetData.isLoading &&
        (!useCache ||
            lightingAssetData.requireLoad ||
            lightingAssetData.siteId !== siteId)
    ) {
        store.dispatch(updateLightingAssetDataLoading(true));

        ApiService.get(`/assets/GetLightingAssets`, {
            params: {
                siteId: siteId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = res.data.map(
                    (x: any) =>
                        ({
                            siteId: x.siteId,
                            room: x.room,
                            tech: x.tech,
                            mount: x.mount,
                            noOfLamps: x.noOfLamps,
                        } as ILightingAsset)
                );

                store.dispatch(updateLightingAssetData(siteId, data));
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
