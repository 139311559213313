import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import {
    ILightingAssetData,
} from "data/assets/lighting-asset/models";
import IStoreState from "store/IStoreState";
import { getLightingAssets } from "data/assets/lighting-asset/services";
import AssetList from "./AssetList";
import AssetByRoom from "./AssetByRoom";
import AssetByTech from "./AssetByTech";
import SiteFilter from "components/shared/global-filter/SiteFilter";
import { IGlobalFilter } from "data/global-filter/models";

interface ILightingAssetProp {
    globalFilter: IGlobalFilter;
    lightingAssetData: ILightingAssetData;
}

function LightingAsset(props: ILightingAssetProp) {
    useEffect(() => {
        getLightingAssets(true, props.globalFilter.siteId);
    });

    const [pageSize, setPageSize] = useState(5);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SiteFilter />
            </Grid>
            <Grid item xs={12} xl={6}>
                <AssetByRoom
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </Grid>
            <Grid item xs={12} xl={6}>
                <AssetByTech
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </Grid>
            <Grid item xs={12} xl={6}>
                <AssetList />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
    lightingAssetData: state.lightingAssetData,
});

export default connect(mapStateToProps)(LightingAsset);
