import { connect } from "react-redux";
import {
    Card,
    CardContent,
    CardHeader,
    LinearProgress,
} from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import IStoreState from "store/IStoreState";
import {
    IEnergyPointData,
} from "data/energy/energy-point/models";
import DataUnits from "components/shared/label/DataUnits";
import { useState, useEffect } from "react";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface IComparisonProps {
    energyPointData: IEnergyPointData;
}

function Comparison(props: IComparisonProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    var style = getComputedStyle(document.body);
    var barColor1 = style.getPropertyValue("--max-color-chart-primary");
    var barColor2 = style.getPropertyValue("--max-color-chart-grey");

    ChartJS.register(...registerables);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top" as const,
            },
        },
    };

    function createChart() {
        return (
            <>
                <div
                    style={{
                        height: "var(--max-size-chart-large)",
                        width: "99%",
                    }}
                >
                    <Chart type="line" options={options} data={getChartData()} />
                </div>
                {props.energyPointData.isLoading ? <LinearProgress /> : <></>}
                {DataUnits("Chart Units: kg")}
            </>
        );
    }

    function getChartData() {
        let labels = props.energyPointData.data.map(
            (x: { timestamp: any }) => x.timestamp
        );

        return {
            labels,
            datasets: [
                {
                    type: "line" as const,
                    label: "C02 Emissions",
                    data: props.energyPointData.data.map(
                        (x: { importFromGrid: number }) => x.importFromGrid * 0.3
                    ),
                    borderColor: barColor2,
                    backgroundColor: barColor2 + 30,
                    fill: true
                },
                
                {
                    type: "line" as const,
                    label: "Baseline",
                    data: props.energyPointData.data.map(
                        (x: { selfConsumption: number }) => 6800
                    ),
                    borderColor: barColor1,
                    backgroundColor: barColor1 + 30,
                    fill: true
                },
            ],
        };
    }

    return (
        <Card>
            <CardHeader title="Electrical Emission Comparison" />
            <CardContent>{createChart()}</CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    energyPointData: state.energyPointData,
});

export default connect(mapStateToProps)(Comparison);
