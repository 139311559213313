import { PointAggregation } from "../PointAggregation";
import { IEnergyPoint } from "./models";

export enum ActionTypeKey {
    UPDATE_ENERGY_POINT_DATA = "UPDATE_ENERGY_POINT_DATA",
    UPDATE_ENERGY_POINT_DATA_LOADING = "UPDATE_ENERGY_POINT_DATA_LOADING",
}

export type ActionType =
    | IUpdateEnergyPointDataAction
    | IUpdateEnergyPointDataLoadingAction;

export interface IUpdateEnergyPointDataAction {
    type: ActionTypeKey.UPDATE_ENERGY_POINT_DATA;
    payload: {
        pointAggregation: PointAggregation;
        fromDate: string;
        toDate: string;
        data: IEnergyPoint[];
    };
}

export interface IUpdateEnergyPointDataLoadingAction {
    type: ActionTypeKey.UPDATE_ENERGY_POINT_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateEnergyPointData(
    pointAggregation: PointAggregation,
    fromDate: string,
    toDate: string,
    data: IEnergyPoint[]
): IUpdateEnergyPointDataAction {
    return {
        type: ActionTypeKey.UPDATE_ENERGY_POINT_DATA,
        payload: {
            pointAggregation: pointAggregation,
            fromDate: fromDate,
            toDate: toDate,
            data: data,
        },
    };
}

export function updateEnergyPointDataLoading(
    isLoading: boolean
): IUpdateEnergyPointDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_ENERGY_POINT_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
