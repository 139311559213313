import {
    Card,
    CardContent,
    CardMedia,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
} from "@mui/material";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { ISecScoreGroupData, SecScoreType } from "data/sec/secScore/models";
import { getSecScore } from "data/sec/secScore/services";

import { EsgChartComponent } from "components/feature/your-commitment/esg/EsgChartComponent";
import { Divider } from "@aws-amplify/ui-react";

import photo from "assets/image/profile_photo.png";
import logo from "assets/image/logo_bowhill.png";
import banner from "assets/image/profile_banner_bowhill.png";

export interface IProfileProps {
    secScoreGroupData: ISecScoreGroupData;
}

function Profile(props: IProfileProps) {
    const data = () => {
        let source = getSecScore(
            SecScoreType.CURRENT,
            props.secScoreGroupData.data
        );
        return [
            source.circularEnergyEconomy,
            source.communityLeadership,
            source.safetySustainability,

            source.policyStrategyTargets,
            source.organisationalCapability,
            source.toolsMeasurement,

            source.baselineEnergyData,
            source.energyResilience,
            source.netZeroEnergy,
        ];
    };

    const score = () => {
        return getSecScore(SecScoreType.CURRENT, props.secScoreGroupData.data)
            .scorePercent;
    };

    const isSecValid = () => {
        return getSecScore(SecScoreType.CURRENT, props.secScoreGroupData.data)
            .secCompliant;
    };

    return (
        <Card>
            <div style={{ position: "relative" }}>
                <img
                    src={logo}
                    style={{
                        height: "100px",
                        position: "absolute",
                        margin: "var(--max-length-medium1)",
                    }}
                />
                <img
                    src={banner}
                    style={{
                        width: "100%",
                        minWidth: "800px",
                        height: "240px",
                        objectFit: "cover",
                    }}
                />
            </div>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <CardContent style={{ border: "1px red" }}>
                            {/* <Stack direction="column" spacing={2}> */}
                            <div style={{ display: "flex" }}>
                                <div style={{ marginRight: "20px" }}>
                                    <img
                                        src={photo}
                                        style={{
                                            // width: "100%",
                                            height: "100px",
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        fontSize: "var(--max-font-size-title)",
                                    }}
                                >
                                    <div>
                                        <strong>Bowhill Engineering</strong>
                                    </div>
                                    <div>Jodie Hawkes - CFO</div>
                                    <div
                                    >
                                            "We are extremely excited to
                                            publicly launch this initiative and
                                            gain momentum"
                                    </div>
                                </div>
                            </div>
                            <div>
                                <br />
                                As Chair of the Regional Development Australia
                                Murraylands and Riverland, I see huge
                                scalability potential within our region. We have
                                some major food manufacturing facilities, whose
                                contributions to this scheme would be massive.
                                If we could get our region to be 100% renewables
                                by 2028 - now that's a BHAG I'd like to pursue!
                            </div>
                            {/* </Stack> */}
                        </CardContent>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{ height: "220px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    transform: "translate(0%, -50%)",
                                }}
                            >
                                <EsgChartComponent
                                    score={score()}
                                    data={data()}
                                    data2={data()}
                                    isSecValid={isSecValid()}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    secScoreGroupData: state.secScoreGroupData,
});

export default connect(mapStateToProps)(Profile);
