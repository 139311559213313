import { connect } from "react-redux";
import { useEffect } from "react";
import IStoreState from "store/IStoreState";
import { IGlobalFilter } from "data/global-filter/models";
import { loadUserData } from "data/user/services";

interface IFilterInitProps {
    globalFilter: IGlobalFilter;
}

function FilterInit(props: IFilterInitProps) {
    useEffect(() => {
        if (props.globalFilter.requireLoad) {
            loadUserData(false);
        }
    });

    return <></>;
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
});

export default connect(mapStateToProps)(FilterInit);
