import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { IEnergyPointData } from "data/energy/energy-point/models";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
} from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import DataUnits from "components/shared/label/DataUnits";
import SquareIcon from "@mui/icons-material/Square";
import { useState, useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { renderCellPercentageDp0 } from "components/shared/data-grid/CellPercentage";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface IElectricalCapacityProp {
    energyPointData: IEnergyPointData;
}

function ElectricalCapacity(props: IElectricalCapacityProp) {
    const siteCapacity = 1250;
    const maximumDemand = 291;

    var style = getComputedStyle(document.body);
    var chartColor1 = style.getPropertyValue("--max-color-chart-accent");
    var chartColor2 = style.getPropertyValue("--max-color-chart-grey");

    ChartJS.register(...registerables);

    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    function getChartData() {
        return {
            labels: ["Maximum Demand", "Remaining Capacity"],
            datasets: [
                {
                    label: "Electrical Capacity",
                    data: [
                        ((maximumDemand / siteCapacity) * 100).toFixed(0),
                        (
                            ((siteCapacity - maximumDemand) / siteCapacity) *
                            100
                        ).toFixed(0),
                    ],
                    backgroundColor: [chartColor1, chartColor2],
                },
            ],
        };
    }

    function createChart() {
        return props.energyPointData.isLoading ? (
            <Box
                sx={{
                    height: "9rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress color="primary" />
            </Box>
        ) : (
            <div
                style={{
                    height: "9.0rem",
                    width: "99%",
                }}
            >
                <Doughnut options={options} data={getChartData()} />
            </div>
        );
    }

    function getLegend(status: string) {
        if (status === "Maximum Demand") {
            return <SquareIcon sx={{ color: chartColor1 }} />;
        } else if (status === "Remaining Capacity") {
            return <SquareIcon sx={{ color: chartColor2 }} />;
        } else {
        }
    }

    const columns: GridColDef[] = [
        {
            field: "key",
            headerName: "Key",
            type: "string",
            maxWidth: 50,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "id",
            headerName: "Type",
            type: "string",
            minWidth: 100,
            flex: 2,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "total",
            headerName: "Total",
            type: "number",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "ratio",
            headerName: "Ratio",
            type: "number",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellPercentageDp0,
        },
    ];

    function getRows() {
        if (props.energyPointData.data.length < 1) {
            return [];
        }

        let rows = [];
        rows.push({
            key: getLegend("Maximum Demand"),
            id: "Maximum Demand",
            total: maximumDemand.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: (maximumDemand / siteCapacity) * 100,
        });
        rows.push({
            key: getLegend("Remaining Capacity"),
            id: "Remaining Capacity",
            total: (siteCapacity - maximumDemand).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: ((siteCapacity - maximumDemand) / siteCapacity) * 100,
        });
        rows.push({
            key: getLegend("Total"),
            id: "Total",
            total: siteCapacity.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: 100,
        });

        return rows;
    }

    return (
        <Card>
            <CardHeader title="Electrical Capacity" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <StyledDataGrid
                            key={dataGridKey}
                            rows={getRows()}
                            columns={columns}
                            disableColumnMenu={true}
                            hideFooter={true}
                            checkboxSelection={false}
                            hideFooterSelectedRowCount={true}
                            density="compact"
                            autoHeight={true}
                            loading={props.energyPointData.isLoading}
                        />
                        {DataUnits("Table Units: Ampere")}
                    </Grid>
                    <Grid item xs={3}>
                        {createChart()}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    energyPointData: state.energyPointData,
});

export default connect(mapStateToProps)(ElectricalCapacity);
