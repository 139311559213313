import { IElectricalAsset } from "./models";

export enum ActionTypeKey {
    UPDATE_ELECTRICALASSET_DATA = "UPDATE_ELECTRICALASSET_DATA",
    UPDATE_ELECTRICALASSET_DATA_LOADING = "UPDATE_ELECTRICALASSET_DATA_LOADING",
}

export type ActionType =
    | IUpdateElectricalAssetDataAction
    | IUpdateElectricalAssetDataLoadingAction;

export interface IUpdateElectricalAssetDataAction {
    type: ActionTypeKey.UPDATE_ELECTRICALASSET_DATA;
    payload: {
        siteId: number;
        data: IElectricalAsset[];
    };
}

export interface IUpdateElectricalAssetDataLoadingAction {
    type: ActionTypeKey.UPDATE_ELECTRICALASSET_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateElectricalAssetData(
    siteId: number,
    data: IElectricalAsset[]
): IUpdateElectricalAssetDataAction {
    return {
        type: ActionTypeKey.UPDATE_ELECTRICALASSET_DATA,
        payload: {
            siteId: siteId,
            data: data,
        },
    };
}

export function updateElectricalAssetDataLoading(
    isLoading: boolean
): IUpdateElectricalAssetDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_ELECTRICALASSET_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
