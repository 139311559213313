import qs from "qs";
import ApiService from "services/ApiService";
import { store } from "store/store";
import {
    updateElectricalAssetData,
    updateElectricalAssetDataLoading,
} from "./actions";
import { IElectricalAsset } from "./models";

export function getElectricalAssets(useCache: boolean, siteId: number) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const electricalAssetData = store.getState().electricalAssetData;

    if (
        !electricalAssetData.isLoading &&
        (!useCache ||
            electricalAssetData.requireLoad ||
            electricalAssetData.siteId !== siteId)
    ) {
        store.dispatch(updateElectricalAssetDataLoading(true));

        ApiService.get(`/assets/GetElectricalAssets`, {
            params: {
                siteId: siteId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = res.data.map(
                    (x: any) =>
                        ({
                            siteId: x.siteId,
                            room: x.room,
                            classification: x.classification,
                            type: x.type,
                            energyKwhPa: x.energyKwhPa,
                        } as IElectricalAsset)
                );

                store.dispatch(updateElectricalAssetData(siteId, data));
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
