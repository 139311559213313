import AppRoutes from "components/router/AppRoutes";
import "./Main.css";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { IGlobalFilter } from "data/global-filter/models";

import logo from "assets/image/logo_bowhill.png";

interface IMainProps {
    globalFilter: IGlobalFilter;
}

function Main(props: IMainProps) {
    return (
        <div className="main">
            {/* <div
                style={{
                    float: "right",
                    zIndex: "10",
                }}
            >
                {props.globalFilter.accountId === 5 ? (
                    <img src={logo} style={{ height: "6rem" }} />
                ) : (
                    <></>
                )}
            </div> */}
            <AppRoutes />
        </div>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
});

export default connect(mapStateToProps)(Main);
