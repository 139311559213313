import { useEffect } from "react";
import ComparisonBreakdown from "./ComparisonBreakdown";
import { Grid, Stack } from "@mui/material";
import SolarAllocation from "./SolarAllocation";
import { connect } from "react-redux";
import { IEnergyPointData } from "data/energy/energy-point/models";
import IStoreState from "store/IStoreState";
import SiteFilter from "components/shared/global-filter/SiteFilter";
import { loadEnergyPoints } from "data/energy/energy-point/services";
import { PointAggregation } from "data/energy/PointAggregation";
import YearFilter from "components/shared/global-filter/YearFilter";
import { IGlobalFilter } from "data/global-filter/models";
import { loadEnergyEstimations } from "data/energy/energy-estimation/services";

interface ISolarProps {
    globalFilter: IGlobalFilter;
    energyPointData: IEnergyPointData;
}

function Solar(props: ISolarProps) {
    useEffect(() => {
        loadEnergyPoints(
            true,
            PointAggregation.MONTH,
            props.globalFilter.siteId,
            new Date(props.globalFilter.yearStart),
            new Date(props.globalFilter.yearEnd)
        );

        loadEnergyEstimations(
            true,
            PointAggregation.MONTH,
            props.globalFilter.siteId,
            new Date(props.globalFilter.yearStart),
            new Date(props.globalFilter.yearEnd)
        );
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                    <SiteFilter />
                    <YearFilter />
                </Stack>
            </Grid>
            <Grid item xs={12} xl={6}>
                <SolarAllocation />
            </Grid>
            <Grid item xs={12}>
                <ComparisonBreakdown />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
    energyPointData: state.energyPointData,
});

export default connect(mapStateToProps)(Solar);
