import {
    Authenticator,
    Theme,
    ThemeProvider,
    useTheme,
    View,
} from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import "./Authentication.css";
import InternalApp from "InternalApp";
import "themes/GlobalStyle.css";
import { Box } from "@mui/material";

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
        mandatorySignIn: true,
    },
});

const components = {
    Header() {
        const { tokens } = useTheme();

        return <View textAlign="center" padding={tokens.space.large}></View>;
    },
    Footer() {
        const { tokens } = useTheme();

        return (
            <View
                textAlign="center"
                padding={tokens.space.large}
                style={{ color: "var(--max-color-text)" }}
            >
                &copy; All Rights Reserved
            </View>
        );
    },
    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Box style={{ width: "100%" }}>
                        <img
                            alt="Maximum Energy"
                            src={require("../../../assets/image/logo.png")}
                            style={{ width: "inherit" }}
                        />
                    </Box>
                </View>
            );
        },
    },
};

const formFields = {
    signIn: {
        username: {
            placeholder: "Email",
        },
    },
    resetPassword: {
        username: {
            placeholder: "Email",
        },
    },
};

export default function Authentication() {
    const { tokens } = useTheme();
    const theme: Theme = {
        name: "Auth Example Theme",
        tokens: {
            fonts: {
                default: {
                    static: {value: "Raleway, sans-serif"},
                    variable: {value: "Raleway, sans-serif"}
                }
            },
            // colors: {
            //     background: {
            //         primary: {
            //             value: tokens.colors.neutral["90"].value,
            //         },
            //         secondary: {
            //             value: tokens.colors.neutral["100"].value,
            //         },
            //     },
            //     font: {
            //         interactive: {
            //             value: tokens.colors.white.value,
            //         },
            //     },
            //     brand: {
            //         primary: {
            //             "10": tokens.colors.teal["100"],
            //             "80": tokens.colors.teal["40"],
            //             "90": tokens.colors.teal["20"],
            //             "100": tokens.colors.teal["10"],
            //         },
            //     },
            // },
            // components: {
            //     tabs: {
            //         item: {
            //             _focus: {
            //                 color: {
            //                     value: tokens.colors.white.value,
            //                 },
            //             },
            //             _hover: {
            //                 color: {
            //                     value: tokens.colors.yellow["80"].value,
            //                 },
            //             },
            //             _active: {
            //                 color: {
            //                     value: tokens.colors.white.value,
            //                 },
            //             },
            //         },
            //     },
            // },
        },
    };

    return (
        // <ThemeProvider theme={theme}>
            <Authenticator
                hideSignUp={true}
                components={components}
                formFields={formFields}
            >
                <InternalApp />
            </Authenticator>
        // </ThemeProvider>
    );
}
