import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { IEnergyPointData } from "data/energy/energy-point/models";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import {
    renderCellChartBarAccentWithTextDp0,
    renderCellChartBarPrimaryWithTextDp0,
} from "components/shared/data-grid/CellChartBar";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface ISummaryProps {
    energyPointData: IEnergyPointData;
}

function Summary(props: ISummaryProps) {
    const gasTotal = 86590;
    const dieselTotal = 59180;

    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Type",
            type: "string",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "totalKwh",
            headerName: "Total MWh",
            type: "number",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "totalMj",
            headerName: "Total GJ",
            type: "number",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "ratio",
            headerName: "Ratio",
            headerAlign: "center",
            type: "number",
            minWidth: 200,
            flex: 2,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellChartBarAccentWithTextDp0,
        },
        {
            field: "renewable",
            headerName: "Renewable",
            headerAlign: "center",
            type: "number",
            minWidth: 200,
            flex: 2,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellChartBarPrimaryWithTextDp0,
        },
    ];

    function getRows() {
        if (props.energyPointData.data.length < 1) {
            return [];
        }

        let totalMj =
            gasTotal +
            dieselTotal +
            props.energyPointData.consumptionTotal * 3.6;

        let totalKwh = totalMj / 3.6;

        let totalRenewableElec = props.energyPointData.consumptionTotal - 11508;
        if (totalRenewableElec < 0) totalRenewableElec = 0;
        let totalRenewable = totalRenewableElec;

        let rows = [];
        rows.push({
            id: "Electricity",
            totalKwh: (props.energyPointData.consumptionTotal / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            totalMj: ((props.energyPointData.consumptionTotal * 3.6) / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: (props.energyPointData.consumptionTotal / totalKwh) * 100,
            renewable:
                (totalRenewableElec /
                    props.energyPointData.consumptionTotal) *
                100,
        });
        rows.push({
            id: "Gas",
            totalKwh: (gasTotal / 3.6 / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            totalMj: (gasTotal / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: (gasTotal / totalMj) * 100,
            renewable: 0,
        });
        rows.push({
            id: "Diesel",
            totalKwh: (dieselTotal / 3.6 / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            totalMj: (dieselTotal / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: (dieselTotal / totalMj) * 100,
            renewable: 0,
        });
        rows.push({
            id: "Total",
            totalKwh: (totalKwh / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            totalMj: (totalMj / 1000).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: 100,
            renewable: (totalRenewable / totalKwh) * 100,
        });

        return rows;
    }

    return (
        <Card>
            <CardHeader title="Energy Summary" />
            <CardContent>
                <StyledDataGrid
                    key={dataGridKey}
                    rows={getRows()}
                    columns={columns}
                    disableColumnMenu={true}
                    hideFooter={true}
                    checkboxSelection={false}
                    hideFooterSelectedRowCount={true}
                    density="compact"
                    autoHeight={true}
                    loading={props.energyPointData.isLoading}
                />
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    energyPointData: state.energyPointData,
});

export default connect(mapStateToProps)(Summary);
