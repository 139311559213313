import { Card, CardContent, Grid } from "@mui/material";
import { ISecRoadmap } from "./Models";
import { RoadmapComponent } from "./RoadmapComponent";
import IStoreState from "store/IStoreState";
import { connect } from "react-redux";
import { ISecScoreGroupData } from "data/sec/secScore/models";
import { getTarget65Progress, getTarget80Progress } from "./Targets";

export interface IRoadmapProps {
    secScoreGroupData: ISecScoreGroupData;
}

function Roadmap(props: IRoadmapProps) {
    function getSecRoadmaps(): ISecRoadmap[] {
        let roadmaps: ISecRoadmap[] = [];

        let target2025Progress = 0;
        let target2028Progress = 0;

        if (props.secScoreGroupData.hasData) {
            target2025Progress = getTarget65Progress(
                props.secScoreGroupData.data?.current
            );

            target2028Progress = getTarget80Progress(
                props.secScoreGroupData.data?.current
            );
        }

        roadmaps.push({
            date: "2025",
            title: "SEC Target > 65%",
            progress: target2025Progress,
        });

        roadmaps.push({
            date: "2028",
            title: "SEC Target > 80% with minimum score of 4 for all criteria",
            progress: target2028Progress,
        });

        return roadmaps;
    }

    return (
        <Card>
            <CardContent>
                <Grid container spacing={4}>
                    {getSecRoadmaps().map(
                        (secRoadmap: ISecRoadmap, index: number) => (
                            <Grid item xs={12} lg={6} key={index}>
                                <RoadmapComponent
                                    secRoadmap={secRoadmap}
                                    isLoading={
                                        props.secScoreGroupData.isLoading
                                    }
                                />
                            </Grid>
                        )
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    secScoreGroupData: state.secScoreGroupData,
});

export default connect(mapStateToProps)(Roadmap);
