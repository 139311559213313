import {
    Autocomplete,
    AutocompleteChangeDetails,
    Card,
    CardContent,
    CardHeader,
    Stack,
    TextField,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { IElectricalAssetData } from "data/assets/electrical-asset/models";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { renderCellChartBarAccentWithTextDp0 } from "components/shared/data-grid/CellChartBar";
import { renderCellSplitAlignRight2 } from "components/shared/data-grid/CellValueSplit";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface IAssetListProps {
    electricalAssetData: IElectricalAssetData;
}

function AssetList(props: IAssetListProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Type",
            type: "string",
            minWidth: 100,
            flex: 3,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            headerAlign: "right",
            type: "number",
            minWidth: 200,
            flex: 2,
            renderHeader: renderColumnHeader,
            renderCell: renderCellSplitAlignRight2,
        },
        {
            field: "usage",
            headerName: "Usage",
            headerAlign: "center",
            type: "number",
            minWidth: 200,
            flex: 2,
            description: `Asset usage of total site consumption`,
            renderHeader: renderColumnHeader,
            renderCell: renderCellChartBarAccentWithTextDp0,
        },
    ];

    const [pageSize, setPageSize] = useState(10);
    const [roomFilter, setRoomFilter] = useState("");
    const [classFilter, setClassFilter] = useState("");

    function getRows() {
        let filteredData = props.electricalAssetData.data
            .filter((x) => x.room.trim() === roomFilter || roomFilter === "")
            .filter(
                (x) =>
                    x.classification.trim() === classFilter ||
                    classFilter === ""
            );

        if (filteredData.length < 1) {
            return [];
        }

        let usageTotal = props.electricalAssetData.data
            .map((x) => x.energyKwhPa)
            .reduce((prev: number, curr: number) => {
                return prev + curr;
            });

        let types = Array.from(new Set(filteredData.map((x) => x.type)));
        let data = types.map((type) => {
            let quantity = filteredData.filter((x) => x.type === type).length;

            let quantityString =
                quantity +
                "-" +
                ((quantity / filteredData.length) * 100).toFixed(0) +
                "%";

            let usage = filteredData
                .filter((x) => x.type === type)
                .map((x) => x.energyKwhPa)
                .reduce((prev: number, curr: number) => {
                    return prev + curr;
                });

            return {
                id: type,
                quantity: quantityString,
                usage: (usage / usageTotal) * 100,
            };
        });

        data.sort((a, b) => {
            return b.usage - a.usage;
        });

        return data;
    }

    function onRoomFilterChange(
        event: React.SyntheticEvent,
        value: string | null,
        reason: string,
        details?: AutocompleteChangeDetails<string> | undefined
    ) {
        if (value === null) {
            setRoomFilter("");
        } else {
            setRoomFilter(value);
        }
    }

    function onClassFilterChange(
        event: React.SyntheticEvent,
        value: string | null,
        reason: string,
        details?: AutocompleteChangeDetails<string> | undefined
    ) {
        if (value === null) {
            setClassFilter("");
        } else {
            setClassFilter(value);
        }
    }

    return (
        <Card>
            <CardHeader title="Assets List" />
            <CardContent>
                <Stack
                    spacing={2}
                    direction="row"
                    sx={{ marginBottom: "1rem" }}
                >
                    <Autocomplete
                        disablePortal
                        id="assetRegisterAssetListRoomFilter"
                        options={Array.from(
                            new Set(
                                props.electricalAssetData.data.map((x) =>
                                    x.room.trim()
                                )
                            )
                        ).sort()}
                        sx={{ width: 300 }}
                        size="small"
                        renderInput={(params) => (
                            <TextField {...params} label="Room" />
                        )}
                        onChange={onRoomFilterChange}
                    />
                    <Autocomplete
                        disablePortal
                        id="assetRegisterAssetListClassFilter"
                        options={Array.from(
                            new Set(
                                props.electricalAssetData.data.map((x) =>
                                    x.classification.trim()
                                )
                            )
                        ).sort()}
                        sx={{ width: 300 }}
                        size="small"
                        renderInput={(params) => (
                            <TextField {...params} label="Classification" />
                        )}
                        onChange={onClassFilterChange}
                    />
                </Stack>
                <StyledDataGrid
                    key={dataGridKey}
                    rows={getRows()}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    checkboxSelection={false}
                    hideFooterSelectedRowCount={true}
                    density="compact"
                    autoHeight={true}
                    loading={props.electricalAssetData.isLoading}
                />
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    electricalAssetData: state.electricalAssetData,
});

export default connect(mapStateToProps)(AssetList);
