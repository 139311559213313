import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Stack,
    Tab,
    Tabs,
} from "@mui/material";
import { TabPanel } from "components/shared/tab/TabPanel";
import CriteriaComponent from "./CriteriaComponent";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { ISecScoreGroupData } from "data/sec/secScore/models";
import { getSecScore } from "data/sec/secScore/services";
import { Metric } from "components/shared/metrics/Metric";
import { Link, useLocation } from "react-router-dom";
import ProjectSummary from "./ProjectSummary";

export interface ICriteriaDetailsProps {
    secScoreGroupData: ISecScoreGroupData;
}

function CriteriaDetails(props: ICriteriaDetailsProps) {
    function GovernanceTab() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Governance" />
                        <CardContent>{governanceSummary}</CardContent>
                    </Card>
                </Grid>

                {gPolicy}
                {gStrategy}
                {gTargets}
                {gRolesResponsibilities}
                {gTrainingCompetency}
                {gCollaboration}
                {gMetering}
                {gMaintenance}
                {gMonitoring}
            </Grid>
        );
    }

    function EnvironmentTab() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Environment" />
                        <CardContent>{environmentSummary}</CardContent>
                    </Card>
                </Grid>

                {eAssetRegister}
                {eBaselineIntervalData}
                {eEmissionScopes}
                {eEnergyEfficiency}
                {eDemandManagement}
                {eEnergyElectrification}
                {eNetZero}
                {eFinancialAccounting}
            </Grid>
        );
    }

    function SocialTab() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Social" />
                        <CardContent>{socialSummary}</CardContent>
                    </Card>
                </Grid>

                {sLocalEnergyUse}
                {sEnergyMarketParticipation}
                {sSourcing}
                {sIndustryEngagement}
                {sEmployeeEngagement}
                {sSafety}
                {sDesign}
                {sReporting}
            </Grid>
        );
    }

    const governanceSummary = (
        <Stack spacing={4}>
            <Metric
                isLoadingIcon={props.secScoreGroupData.isLoading}
                isLoadingContent={false}
                color="grey"
                variant="default"
                value={
                    getSecScore(
                        props.secScoreGroupData.groupFilter,
                        props.secScoreGroupData.data
                    ).policyStrategyTargets
                }
                title="Policies, Strategies and Targets"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            Policies, Strategies and Targets are key to driving
                            sustainable development and a reduction in energy
                            use and emissions. They facilitate company
                            sustainability, energy and emissions goals through
                            the provision of a clear methodology and defined
                            pathway.
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <ProjectSummary criteria="Policies, Strategies and Targets" />
                        </Grid>
                    </Grid>
                }
            />

            <Metric
                isLoadingIcon={props.secScoreGroupData.isLoading}
                isLoadingContent={false}
                color="grey"
                variant="default"
                value={
                    getSecScore(
                        props.secScoreGroupData.groupFilter,
                        props.secScoreGroupData.data
                    ).organisationalCapability
                }
                title="Organisational Capability"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            In order to drive sustainability throughout the
                            business, it is important that targets and
                            strategies are effectively communicated to all
                            employees. Defining organisational roles,
                            responsibilities and competencies for energy and
                            emissions reduction and ensuring employees are
                            trained and competent in energy management
                            practices. Strong collaboration, teamwork and
                            accountability will help drive the transition
                            towards a sustainable energy future.
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <ProjectSummary criteria="Organisational Capability" />
                        </Grid>
                    </Grid>
                }
            />

            <Metric
                isLoadingIcon={props.secScoreGroupData.isLoading}
                isLoadingContent={false}
                color="grey"
                variant="default"
                value={
                    getSecScore(
                        props.secScoreGroupData.groupFilter,
                        props.secScoreGroupData.data
                    ).toolsMeasurement
                }
                title="Tools and Measurement"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            Tools and Measurement systems provide a means of
                            monitoring energy usage, identifying trends and
                            ensuring efficient energy consumption. To drive the
                            shift to renewable energy, it is important to
                            understand trends for all major energy streams and
                            monitor real time demand. To ensure accuracy and
                            reliability of data, regular quality assurance and
                            maintenance practices need to be in place to provide
                            effective and reliable tools and measurement
                            systems.
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <ProjectSummary criteria="Tools and Measurement" />
                        </Grid>
                    </Grid>
                }
            />
        </Stack>
    );

    const gPolicy = (
        <CriteriaComponent
            color="grey"
            criteria="Policy"
            criteriaGroup="Score weighted 20% of Policies, Strategies and Targets"
            description="A clear policy is in place to drive a sustainable development culture and positive action on energy and emissions reduction"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).policy
            }
            scoreComments="Integration of a sustainable energy policy and organisational involvement in the company's sustainability objectives will improve this score."
            info={[
                "A Sustainability Policy is not in existance.",
                "A Sustainability Policy is in place but does not adequately address sustainable energy requirements.",
                "A Sustainable Energy Policy is in place but there are some gaps in meeting the requirements of the SEC.",
                "A Sustainable Energy policy is in place that includes a purpose and commitment to energy and emissions reductions. The policy includes awareness on the need to transition to decentralisation, market participation and community leadership. The policy addresses the basic requirements of the SEC.",
                "A Sustainable Energy Policy is in place that includes a purpose and commitment to energy and emissions reduction. The policy includes a focus on transition to decentralisation, market participation and community leadership. The policy addresses all the requirements of the SEC including the need to accelerate the market transition. The Policy is regularly updated and approved by the Managing Director.",
            ]}
        />
    );

    const gStrategy = (
        <CriteriaComponent
            color="grey"
            criteria="Strategy"
            criteriaGroup="Score weighted 40% of Policies, Strategies and Targets"
            description="Energy and emissions management strategies are clearly articulated, communicated through the organisation and have well-defined objectives that are consistent with the requirements of the SEC. There is effective governance and stakeholder communication to assure delivery of these strategies."
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).strategies
            }
            scoreComments="Some strategies are already in place, but further energy and emissions strategies for organisational communication, risk management and public commitment will improve this score. "
            info={[
                "A strategy is non-existent.",
                "A strategy exists but doesn't focus on energy.",
                "Strategy is in place that focuses on energy but doesn't meet all of the minimum SEC requirements",
                "A roadmap of actions has been developed that meet the minimum requirements of the SEC. A SEC committee has been established for governance.",
                "A roadmap of actions has been developed that exceeds the minimum requirement of the SEC. A SEC deployment risk analysis has been conducted.  A communication strategy is in place. SEC committee has been established for governance. Effective SEC governance processes are in place.",
            ]}
        />
    );

    const gTargets = (
        <CriteriaComponent
            color="grey"
            criteria="Targets"
            criteriaGroup="Score weighted 40% of Policies, Strategies and Targets"
            description="Short Term (2025) and Long Term (2030) energy and emissions reduction targets have been established that are aligned with SEC requirements. A formal public commitment to meeting these targets has been declared. "
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).targets
            }
            scoreComments="Some strategies are already in place, but further energy and emissions strategies for organisational communication, risk management and public commitment will improve this score. "
            info={[
                "No targets have been established.",
                "Targets are in development.",
                "Targets are in place but do not meet the SEC requirements. A public commitment to the targets has not been made.",
                "Short term (2025) targets and long term (2030) targets are set. Targets meet the minimum SEC criteria. There is a signed public commitment and media release. Requires an approved extension up to 10 years to meet targets.",
                "Short term (2025) targets and long term (2030) targets are set. Targets meet or exceed the SEC requirements. A signed public commitment and media release has been undertaken.",
            ]}
        />
    );

    const gRolesResponsibilities = (
        <CriteriaComponent
            color="grey"
            criteria="Roles and Responsibilities"
            criteriaGroup="Score weighted 20% of Organisational Capability"
            description="Roles, responsibilities and competencies for energy and emissions management are clearly defined and documented in position descriptions. Targets are clear and employee expectations are well understood and included in annual performance reviews."
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).rolesResponsibilities
            }
            scoreComments="To improve this score, employee energy and emissions roles need to be clearly defined and outlined in job descriptions, governed through regular performance reviews. A dedicated SEC team needs to be established"
            info={[
                "Non-existent",
                "Roles and responsbilities are in development.",
                "Job descriptions have been updated to include SEC responsbilities and associated competencies.",
                "Job descriptions have been updated to include SEC responsibilities and associated competencies. KPIs established for staff. SEC committee established.",
                "Job descriptions have been updated to include SEC responsbilities and associated competencies. SEC specific KPIs are established for staff and staff performance reviews are in place. SEC committee established and fully functional.",
            ]}
        />
    );

    const gTrainingCompetency = (
        <CriteriaComponent
            color="grey"
            criteria="Training and Competency"
            criteriaGroup="Score weighted 40% of Organisational Capability"
            description="SEC accredited energy efficiency and usage training programs are in place and all personnel are trained and competent in line with the competency requirements of the role as defined by their position descriptions"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).trainingCompetency
            }
            scoreComments="To improve this score, employee energy and emissions roles need to be clearly defined and outlined in job descriptions, governed through regular performance reviews. A dedicated SEC team needs to be established"
            info={[
                "Non-existent.",
                "Base training covered in induction /onboarding. ",
                "Training program is in place but does not fully address SEC requirements. SEC accredited training is in development ",
                "Annual SEC business training conducted for all staff. SEC accredited training modules are available and available for staff training. There is a plan to achieve staff SEC training completion of 50% within 2 years.",
                "Annual SEC business training conducted for all staff. SEC accredited training modules are being actively used by more than 50%. There is a plan to increase staff SEC training completion to 90% within 2 years. SEC competency metrics are recorded, available and audited annually.",
            ]}
        />
    );

    const gCollaboration = (
        <CriteriaComponent
            color="grey"
            criteria="Collaboration"
            criteriaGroup="Score weighted 40% of Organisational Capability"
            description="There is ownership and accountability for energy usage and efficiency throughout the organisation and the use of technology and innovation is encouraged to improve energy management processes and systems. "
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).collaboration
            }
            scoreComments="To improve this score, quarterly meetings need to take place to discuss progress and plan the next stages"
            info={[
                "Non-existent",
                "Live data is not available however some monitoring occurs in a retrospective manner.",
                "Live monitoring portal in development. Knowledge sharing reliant on individuals. Limited governance occurring ",
                "Quarterly SEC committee meetings are established with recorded minutes and actions. A live performance dashboard is in place. Knowledge sharing across the company is encouraged and occurs in an ad hoc manner. ",
                "Quarterly SEC committee meetings with recorded minutes and actions that allow proactive governance, generating insights and sharing learnings. A sustainable energy management platform is in place to demonstrate performance. There is an active program to share knowledge within the company. ",
            ]}
        />
    );

    const gMetering = (
        <CriteriaComponent
            color="grey"
            criteria="Metering"
            criteriaGroup="Score weighted 40% of Tools and Measurement"
            description="Smart metering systems are in place for all major energy streams that monitor and measure real time demand and power quality for the site, with live energy data available and visible across the organisation to inform and drive energy use decision making "
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).metering
            }
            scoreComments="Smart metering systems such as a live monitoring portal need to be implemented for all major energy sources in order to improve this score. "
            info={[
                "No smart metering in place",
                "Applied to local DNSP/retailer to get smart meter.",
                "A smart meter is in place at the main switchboard.",
                "Smart metering is in place for all major energy streams. Demand and power quality is measured ",
                "Smart metering is in place for all major energy streams including individual assets. Demand and power quality is measured in real time.",
            ]}
        />
    );

    const gMaintenance = (
        <CriteriaComponent
            color="grey"
            criteria="Maintenance"
            criteriaGroup="Score weighted 30% of Tools and Measurement"
            description="Maintenance and quality assurance practices are in place to maintain the reliability, availability and accuracy of energy metering systems, associated equipment and energy infrastructure"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).maintenance
            }
            scoreComments="To improve this score, a maintenance plan or contract needs to be established for all energy systems, equipment and infrastructure"
            info={[
                "Non existent and maintenance strategy is on a run to failure approach. Nearly all maintenance work is reactive and breakdown work.",
                "Maintenance procedures and service contracts are being developed. PMs are being developed. Some limited Preventative Maintenance (PM) work being executed (PMs < 30% ). Work planning and execution is primarily driven by breakdown and reactive maintenance work (Corrective Maintenance (CM)s >75%).",
                "Maintenance procedures and service contracts are in place. There are some PM strategies and routines identified and executed (PMs 30-60%). Work planning and execution is mainly driven by reactive/breakdown maintenance work. (CMs 50% )",
                "PM strategies are fully defined and PM routines are in place but not fully executed. PM routines are regularly executed (PMs 60-80%). There is still some breakdown/reactive maintenance work. (CMs <25%)",
                "Maintenance procedures and  service contracts for PMs are fully in place and executed at least annually or frequency recommended by the OEM to ensure reliability, availability and accuracy of all meters, energy generating assets and major energy consuming equipment. PM routines are executed on time (PMs >80%). There  is minor breakdown/reactive maintenance work (CMs <10%).",
            ]}
        />
    );

    const gMonitoring = (
        <CriteriaComponent
            color="grey"
            criteria="Monitoring"
            criteriaGroup="Score weighted 30% of Tools and Measurement"
            description="Changes in energy usage are monitored, deviations are investigated, and corrective actions applied in a timely manner to minimize energy usage and losses."
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).monitoring
            }
            scoreComments="Monitoring plans need to be implemented in the form of a live dashboard and an alert system to improve this score"
            info={[
                "No monitoring program or energy use data available.",
                "Routine monitoring program in place but deviations are not identified. Energy usage data is available. ",
                "Routine monitoring program in place. Deviations from normal operational performance are identified but not adequately investigated. Corrective actions implemented are not fully effective. Energy usage data is available but not used to drive decisions.",
                "Routine monitoring program in place. Deviations from normal operational performance are identified and investigated without structured, formal investigation techniques. Corrective actions are put in place to address the deviations. Energy usage data is available but used in an ad hoc manner to drive decisions.",
                "Routine monitoring program in place. Any deviations from normal operational performance are identified and investigated using RCA techniques. Corrective actions are put in place to address the deviations in a timely manner. Energy usage data is used to make decisions on maintenance improvements and capital investment upgrade opportunities.",
            ]}
        />
    );

    const environmentSummary = (
        <Stack spacing={4}>
            <Metric
                isLoadingIcon={props.secScoreGroupData.isLoading}
                isLoadingContent={false}
                color="primary"
                variant="default"
                value={
                    getSecScore(
                        props.secScoreGroupData.groupFilter,
                        props.secScoreGroupData.data
                    ).baselineEnergyData
                }
                title="Baseline Energy Data"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            The development of baseline energy data is the
                            foundation for a successful SEC. It provides a
                            starting point and highlights how the company can
                            improve their energy consumption and emissions
                            through implementation of an energy asset register,
                            access to detailed energy usage and interval data,
                            and good power quality data. Additionally, emissions
                            data for scopes 1 and 2 need to be understood.
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <ProjectSummary criteria="Baseline Energy Data" />
                        </Grid>
                    </Grid>
                }
            />
            <Metric
                isLoadingIcon={props.secScoreGroupData.isLoading}
                isLoadingContent={false}
                color="primary"
                variant="default"
                value={
                    getSecScore(
                        props.secScoreGroupData.groupFilter,
                        props.secScoreGroupData.data
                    ).energyResilience
                }
                title="Energy Resilience"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            Energy management resilience requires innovation and
                            problem solving to better manage energy use and
                            reduce consumption. It requires power demand
                            reduction, optimisation and management of energy
                            usage and operational capability to use energy in a
                            flexible manner and shift energy load as required.
                            Additionally, power quality should be of a high
                            standard that supports the grid and network system
                            strength.
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <ProjectSummary criteria="Energy Resilience" />
                        </Grid>
                    </Grid>
                }
            />
            <Metric
                isLoadingIcon={props.secScoreGroupData.isLoading}
                isLoadingContent={false}
                color="primary"
                variant="default"
                value={
                    getSecScore(
                        props.secScoreGroupData.groupFilter,
                        props.secScoreGroupData.data
                    ).netZeroEnergy
                }
                title="Net Zero Energy"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            Net zero energy initially requires the transition to
                            net zero electricity, by optimising self-owned
                            energy generation assets and matching demand with
                            offsite renewable energy sources. Following this,
                            the transition to net zero energy involves creating
                            a system where other energy sources such as diesel,
                            petrol or gas can achieve a net renewable status,
                            through either elimination or offset with excess
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <ProjectSummary criteria="Net Zero Energy" />
                        </Grid>
                    </Grid>
                }
            />
        </Stack>
    );

    const eAssetRegister = (
        <CriteriaComponent
            color="primary"
            criteria="Asset Register"
            criteriaGroup="Score weighted 40% of Baseline and Interval Data"
            description="A detailed energy asset register is in place that specifies individual asset type, location, model and estimated energy use. A defined process is in place to ensure the accuracy of the register is maintained"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).assetRegister
            }
            scoreComments="A site audit needs to be undertaken and an asset register needs to be developed in order to improve this score. The asset register should be updated regularly."
            info={[
                "No asset register is in place ",
                "Asset register in development 	",
                "Asset register is in place but doesn't cover all equipment and doesn't specify location, model and estimated energy use.	",
                "Detailed energy asset register is in place. Individual asset types, location, estimated energy use are documented. A process is in place to ensure the register is regularly maintained, updated and accurate. 	",
                "Detailed energy asset register is in place. Individual asset types, location and estimated energy use are documented. A process is in place to ensure the register is regularly maintained, updated and accurate. All new or modified equipment is included in the asset register in a timely manner. 	",
            ]}
        />
    );

    const eBaselineIntervalData = (
        <CriteriaComponent
            color="primary"
            criteria="Baseline and Interval Data"
            criteriaGroup="Score weighted 40% of Baseline Energy Data"
            description="Energy usage to a sub meter level is available to ensure that energy flows are understood behind the main meter. The energy usage baseline is defined with detailed interval data, peak demand data and power quality data. Historical energy trends are available and used to support decisions on future energy efficiency actions and improvement opportunities"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).baselineIntervalData
            }
            scoreComments="Baseline energy data needs to be established to allow sufficient monitoring of emissions data and an understanding of power quality data, in order to improve this score"
            info={[
                "No digital energy use data is available 	",
                "Energy use data at a site level is available from metering at the main switchboard. Interval data and historical energy use information is available at a site level but not used. Energy flows behind main meter are not known. ",
                "Energy use data at a site level is available from metering at the main switchboard. Interval data and historical energy use information is available at a site level and is used for budget purposes. Strategy is in development to build the energy use baseline for major energy streams to a sub meter level.",
                "Energy usage to a sub meter level is available for major energy streams only. The energy usage baseline and interval data for major energy streams is available. Historical energy use trands are available for at least 12 months to support decisions on energy efficiency actions and improvement opportunities. ",
                "Energy usage to a sub meter level is available to ensure that all energy flows are understood behind the main meter. The energy usage baseline is defined with detailed interval data, peak demand data and power quality data. Historical energy trends are available for at least 24 months and used to support decisions on future energy efficiency actions and improvement opportunities.	",
            ]}
        />
    );

    const eEmissionScopes = (
        <CriteriaComponent
            color="primary"
            criteria="Emission Scopes"
            criteriaGroup="Score weighted 20% of Baseline and Interval Data"
            description="Emissions data from a portfolio to an asset level is available, segmented by greenhouse gas emissions scopes (1 and 2) and fuel source to identify and drive emissions reduction initiatives"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).emissionScopes
            }
            scoreComments="In order to improve this score, an emissions register needs to be developed for all scope 1 and 2 emissions"
            info={[
                "Emissions data is not calculated or available.",
                "Emissions data is limited (<40%) and is not segmented by GHG emissions scope or by fuel source.",
                "Emissions data at an asset level is mostly available (70%). Emissions data is segmented by fuel source but not by GHG emissions scope. Emissions data is not regularly updated.",
                "Emissions data at an asset level is available, and segmented by GHG emissions scope (scope 1 and 2) and fuel source. Emissions data is  updated bi-annually and reviewed to identify and drive emissions reduction initiatives. ",
                "Emissions data from a portfolio to an asset level is available, segmented by GHG emissions scope (scope 1 and 2) and fuel source. Emissions data is updated when asset changes are made. Emissions data is reviewed annually to identify and drive emissions reduction initiatives. ",
            ]}
        />
    );

    const eEnergyEfficiency = (
        <CriteriaComponent
            color="primary"
            criteria="Energy Efficiency"
            criteriaGroup="Score weighted 30% of Energy Resilience"
            description="There is a program in place to drive energy efficiency, minimize power demand requirements and energy use has been reduced so far as is reasonably practical through asset optimization, smart technology and automation through maintenance and capital improvements"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).energyEfficiency
            }
            scoreComments="Improvements to flexibility and efficiency of energy usage will be established through the SEC, as well as improving site power quality."
            info={[
                "Less than 25% of all potential energy efficiency projects with less than a 3 year ROI have been implemented to minimise power demand requirements and energy use. This includes asset optimisation, smart technology and automation through maintenance and capital improvement projects. ",
                "25%-50% of all potential energy efficiency projects with less that a 3 year ROI have been implemented to minimise power demand requirements and energy use. This includes asset optimisation, smart technology and automation through maintenance and capital improvement projects. ",
                "At least 50% of all potential Energy efficiency projects with less that a 3 year ROI have been implemented to minimise power demand requirements and energy use. This includes asset optimisation, smart technology and automation through maintenance and capital improvement projects. ",
                "All Energy efficiency projects with less that a 3 year ROI have been implemented to minimise power demand requirements and energy use. This includes asset optimisation, smart technology and automation through maintenance and capital improvement projects. ",
                "All Energy efficiency projects with less that a 5 year ROI have been implemented  to minimise power demand requirements and energy use. This includes asset optimisation, smart technology and automation through maintenance and capital improvement projects. ",
            ]}
        />
    );

    const eDemandManagement = (
        <CriteriaComponent
            color="primary"
            criteria="Demand Management"
            criteriaGroup="Score weighted 40% of Energy Resilience"
            description="Effective power quality and demand management programs are in place to allow active participation in the energy market and capability to reduce or increase energy load to match self-renewable generation capability and optimize the use of external renewable energy sources"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).demandManagement
            }
            scoreComments="In order to improve this score, smart systems need to be implemented to shift energy load when necessary"
            info={[
                "Less than 25% of all potential power quality and demand management projects with less than a 3 year ROI have been implemented to allow active participation in the energy market and capability to reduce or increase energy load to match self-renewable generation capability and optimise the use of external renewable energy sources. 	",
                "less than 25-50% of all potential power quality and demand management projects with less than a 3 year ROI have been implemented to allow active participation in the energy market and capability to reduce or increase energy load to match self-renewable generation capability and optimise the use of external renewable energy sources. 	",
                "Less than 50% of all potential power quality and demand management projects with less than a 3 year ROI have been implemented to allow active participation in the energy market and capability to reduce or increase energy load to match self-renewable generation capability and optimise the use of external renewable energy sources. 	",
                "All power quality and demand management projects with less than a 3 year ROI have been implemented to allow active participation in the energy market and capability to reduce or increase energy load to match self-renewable generation capability and optimise the use of external renewable energy sources. 	",
                "All power quality and demand management projects with less than a 5 year ROI have been implemented to allow active participation in the energy market and capability to reduce or increase energy load to match self-renewable generation capability and optimise the use of external renewable energy sources. 	",
            ]}
        />
    );

    const eEnergyElectrification = (
        <CriteriaComponent
            color="primary"
            criteria="Energy Electrification"
            criteriaGroup="Score weighted 30% of Energy Resilience"
            description="Realistic plans are in place for delivering the interim and long-term net zero targets, that include electrifying of fuel sources where possible"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).energyElectrification
            }
            scoreComments="All energy sources need to be electrified in order to deliver net zero targets and achieve the requirements of the SEC"
            info={[
                "Less than 25% of baseline fuel sources have been electrified	",
                "25-50% of baseline fuel sources have been electrified	",
                "At least 50% of baseline fuel sources have been electrified	",
                "All fleet and/or all hotwater services have been electrified	",
                "All major fuel users have been electrified	",
            ]}
        />
    );

    const eNetZero = (
        <CriteriaComponent
            color="primary"
            criteria="Net Zero"
            criteriaGroup="Score weighted 60% of Net Zero Energy"
            description="Actual matched net zero renewable energy, measured quantitatively"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).netZero
            }
            scoreComments="To achieve the requirements of the SEC, at least 100% of energy must come from net zero sources."
            info={[
                "less than 25% net renewably sourced energy	",
                "25%-49% net renewably sourced energy	",
                "50%-74% net renewably sourced energy	",
                "75%-99% net renewably sourced energy	",
                "More than 100% net renewably sourced energy	",
            ]}
        />
    );

    const eFinancialAccounting = (
        <CriteriaComponent
            color="primary"
            criteria="Financial Accounting"
            criteriaGroup="Score weighted 40% of Net Zero Energy"
            description="Financial accounting systems are in place for tracking renewable energy inputs, including source identification and matching of internal and external energy sources at a 15-minute interval level"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).financialAccounting
            }
            scoreComments="In order to improve this score, a live energy and emissions accounting need to be tracked and made available"
            info={[
                "There are energy and associated emission accounting systems in place that can report on a monthly basis but isn't live.",
                "There are energy and associated emission accounting systems in place that can report on a daily basis but isn't live",
                "There are energy and associated emission accounting systems in place that can report on a daily basis but isn't live",
                "There are live energy and associated emission accounting systems in place that can measure matched renewables to energy load to 15 min increments",
                "There are live energy and associated emission accounting systems in place that can measure matched renewables to energy load to 5 min increments",
            ]}
        />
    );

    const socialSummary = (
        <Stack spacing={4}>
            <Metric
                isLoadingIcon={props.secScoreGroupData.isLoading}
                isLoadingContent={false}
                color="accent"
                variant="default"
                value={
                    getSecScore(
                        props.secScoreGroupData.groupFilter,
                        props.secScoreGroupData.data
                    ).circularEnergyEconomy
                }
                title="Circular Energy Economy"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            The circular energy economy aims to facilitate the
                            shift from a linear to a circular economy, fostering
                            the continual use of resources through the
                            implementation of renewable infrastructure. It aims
                            to decentralise the energy network and create a
                            system for sharing and utilising renewable energy in
                            a circular way. Strategies to support the circular
                            economy should include sourcing renewable energy
                            from sources that support a circular energy economy,
                            becoming an energy market participant, and through
                            investments that utilise sustainable design
                            methodologies
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <ProjectSummary criteria="Circular Energy Economy" />
                        </Grid>
                    </Grid>
                }
            />
            <Metric
                isLoadingIcon={props.secScoreGroupData.isLoading}
                isLoadingContent={false}
                color="accent"
                variant="default"
                value={
                    getSecScore(
                        props.secScoreGroupData.groupFilter,
                        props.secScoreGroupData.data
                    ).communityLeadership
                }
                title="Community Leadership"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            Community leadership facilitates commitment to a
                            sustainable energy future by the wider community,
                            leading the transition to the new, decentralised
                            energy market. Community energy networks can only
                            exist once businesses and communities introduce
                            smart energy infrastructure, establish energy
                            management resilience, and encourage others to
                            follow. In addition, establishing a sustainable
                            sourcing policy that identifies community-based
                            suppliers, commitment to sustainable energy
                            programs, active engagement with industry bodies,
                            the community and employees to raise awareness of
                            sustainable energy matters is required.
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <ProjectSummary criteria="Community Leadership" />
                        </Grid>
                    </Grid>
                }
            />
            <Metric
                isLoadingIcon={props.secScoreGroupData.isLoading}
                isLoadingContent={false}
                color="accent"
                variant="default"
                value={
                    getSecScore(
                        props.secScoreGroupData.groupFilter,
                        props.secScoreGroupData.data
                    ).safetySustainability
                }
                title="Safety and Sustainability"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={7}>
                            To ensure operations are safe and sustainable, it is
                            important that clear strategies are in place to
                            reduce impacts on people and the environment.
                            Effective safety systems for energy projects should
                            be in place and a sustainable development culture
                            needs to be embedded across the organisation. In
                            addition, strategies to support the United Nations
                            Energy Compacts and an annual sustainability report
                            needs to be created that includes energy and
                            emissions management strategies, policies and
                            statistics.
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <ProjectSummary criteria="Community Leadership" />
                        </Grid>
                    </Grid>
                }
            />
        </Stack>
    );

    const sLocalEnergyUse = (
        <CriteriaComponent
            color="accent"
            criteria="Local Energy Use"
            criteriaGroup="Score weighted 40% of Circular Energy Economy"
            description="Matched renewable energy use through circular energy economy strategies, measured quantitatively"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).localEnergyUse
            }
            scoreComments="In order to improve this score, energy sharing and procurement initiatives should be implemented with suppliers, employees, customers and local community energy companies."
            info={[
                "Non-existent				",
                "More than 10% of energy use or sharing is matched within the DNSP				",
                "More than 10% of energy use or sharing is matched within the 500km				",
                "More than 10% of energy use or sharing is matched within the 250km				",
                "More than 10% of energy use or sharing is matched within the 100km				",
            ]}
        />
    );

    const sEnergyMarketParticipation = (
        <CriteriaComponent
            color="accent"
            criteria="Energy Market Participation"
            criteriaGroup="Score weighted 40% of Circular Energy Economy"
            description="A circular energy economy approach is used for energy related investment decisions for energy utilisation, generation and procurement to allow active energy market participation to procure or share energy at a local decentralised level. Ability to be flexible in energy demand requirements and managing power quality to support grid and network system strength to minimize exposure to grid energy supply instabilities and market fluctuations"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).energyMarketParticipation
            }
            scoreComments="This score will be improved once the company is actively participating in the new energy market through self-owned renewable energy generation and VPPs."
            info={[
                "Non-existent	",
                "A circular energy economy strategy is in place that has more than 0-10% of required energy use or sharing utilised with scope 3 suppliers, employees, customers or other creative areas	",
                "A circular energy economy strategy is in place that has more than 10-20% of required energy use or sharing utilised with scope 3 suppliers, employees, customers or other creative areas	",
                "A circular energy economy strategy is in place that has more than 20-30% of required energy use or sharing utilised with scope 3 suppliers, employees, customers or other creative areas	",
                "A circular energy economy strategy is in place that has more than 30% of required energy use or sharing utilised with scope 3 suppliers, employees, customers or other creative areas	",
            ]}
        />
    );

    const sSourcing = (
        <CriteriaComponent
            color="accent"
            criteria="Sourcing"
            criteriaGroup="Score weighted 20% of Circular Energy Economy"
            description="Sourcing programs are in place that consider social, economic and environmental factors alongside price and quality considerations in the procurement of energy retail services, energy related equipment and materials. Approved supplier registers are in place with an emphasis on suppliers that meet the minimum SEC requirements"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).sourcing
            }
            scoreComments="A clear and comprehensive methodology to evaluate suppliers and determine whether they meet defined  requirements is required to increase score. "
            info={[
                "Non-existent	",
                "Sourcing programs for procurement of energy retail services and energy related equipment only consider price and quality. A sustainable sourcing strategy is being developed to consider ESG factors in procurement of energy related services. ",
                "Sourcing programs for procurement of energy retail services and energy related equipment and materials consider social, economic and environmental factors alongside price and quality considerations. For energy related procurement decisions there is less than 10% weighting on ESG factors. Approved supplier registers are in place but do not include community based suppliers meeting the minimum SEC requirements.",
                "Sourcing programs for procurement of energy retail services and energy related equipment and materials consider social, economic and environmental factors alongside price and quality considerations. For energy related procurement decisions there is at least a 25% weighting on ESG factors. Approved supplier registers are in place that include community based suppliers that meet the minimum SEC requirements.",
                "Sourcing programs for procurement of energy retail services and energy related equipment and materials consider social, economic and environmental factors alongside price and quality considerations. For energy related procurement decisions there is at least a 40% weighting on ESG factors. Approved supplier registers are in place that include community based suppliers that meet the minimum SEC requirements.",
            ]}
        />
    );

    const sIndustryEngagement = (
        <CriteriaComponent
            color="accent"
            criteria="Industry Engagement"
            criteriaGroup="Score weighted 50% of Community Leadership"
            description="The business is recognised by peers and the community for its leadership and proactive business engagement program with industry bodies and the community to raise awareness of sustainable energy matters. An active program is in place to share sustainable energy commitment successes, opportunities and innovation with industry peers and the community."
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).industryEngagement
            }
            scoreComments="Some industry engagement has already taken place, further endeavors to communicate on energy and sustainability related topics will increase this score."
            info={[
                "There is no community and industry engagment program for sustainable energy.	",
                "A strategy for community and industry engagement on sustainable energy is in development.	",
                "There is ad-hoc engagement with local business and community groups. The business promotes sustainable energy matters through informal communications and limited sponsorships. There is limited sharing of sustainable energy project successes with industry peers and the community. A structured program for community and industry engagement on sustainable energy is being implemented. ",
                "There is an engagement program incorporating local business, industry and community groups. The business raises awareness of sustainable energy matters through ad-hoc community initiatives, industry programs and community sponsorship. There is some sharing of sustainable energy project successes with industry peers and community. ",
                "There is a proactive engagement program incorporating local business, industry and community groups. The business is recognised by peers and the community for its leadership in addressing sustainable energy issues. The business raises awareness of sustainable energy matters through committment to community and insustry programs, and sponsorship. There is formal program in place to share sustainable energy program successes, opportunities and innovation with industry peers and community. ",
            ]}
        />
    );

    const sEmployeeEngagement = (
        <CriteriaComponent
            color="accent"
            criteria="Employee Engagement"
            criteriaGroup="Score weighted 50% of Community Leadership"
            description="An employee engagement program is in place that provides access to sustainable energy initiatives and support greater alignment and physical interaction between the business and employees"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).employeeEngagement
            }
            scoreComments="The development of programs to engage employees in energy initiatives will improve this score"
            info={[
                "There is no employee engagment program for sustainable energy.	",
                "A strategy for employee engagement on sustainable energy is in development.	",
                "An employee engagement strategy is being implemented. Company led sustainable energy initiative awareness sessions are held with employees. Company sponsored renewable energy procurement programs are  available to selected employees in the business.",
                "There is a structured employee engagement program in place that facilitates employee access to the renewable energy market. Company led net zero energy initiatives are communicated  and promoted to all employees. At least 50% of all employees are actively engaged in company sponsored energy initiative programs including renewable energy procurement.",
                "There is a structured employee engagement program in place that facilitates employee access to the renewable energy market. Company led net zero energy initiatives are communicated and promoted to all employees. At least 90% of all employees are actively engaged in company sponsored energy initiative programs including renewable energy procurement. Employees are actively engaged and interacting with a company sponsored renewable energy grid program.",
            ]}
        />
    );

    const sSafety = (
        <CriteriaComponent
            color="accent"
            criteria="Safety"
            criteriaGroup="Score weighted 25% of Safety and Sustainability"
            description="Effective safety management systems are in place for work on site. Pre-screening is conducted for contractors engaged for energy projects and maintenance services to validate safety performance and their safety management system effectiveness."
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).safety
            }
            scoreComments="The company already has some safety management systems in place, however integration of an SMS would further improve this score."
            info={[
                "There is no Safety Management System in place.",
                "A Safety Management System is in development. Safety is managed through basic control of work processes or reliant on basic contractor safety processes.",
                "A Safety Management System is in place. Contractors are required to have control of work processes that are fit for purpose and meet the requirements of the SMS.",
                "A Safety Management System (SMS) is in place. Contractors are only engaged for site work if they have an equivalent form of SMS. Pre Screening of at least 70% of contractors is conducted to provide assurance that safety management systems are fit for purpose and comply with SMS requirements.  ",
                "A Safety Management System (SMS) is in place. Contractors are only engaged for site work if they have an equivalent form of SMS. Pre Screening of all contractors is conducted to provide assurance that safety management systems are fit for purpose and comply with SMS requirements. Contractors are audited regularly to assure compliance with safety requirements.",
            ]}
        />
    );

    const sDesign = (
        <CriteriaComponent
            color="accent"
            criteria="Design"
            criteriaGroup="Score weighted 25% of Safety and Sustainability"
            description="Design methodology considers energy efficiency, increased technology life cycle, recyclability and end-of-life material recovery strategies to minimize emissions and waste as part of total life cycle cost for investment decisions"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).design
            }
            scoreComments="In order to improve this score, sustainable design and end-of-life plans are required for all energy-related assets, to be documented in a register."
            info={[
                "Energy project designs are based solely on lowest total installed cost (TIC). 	",
                "Energy project designs include consideration of quality and reliability  in addition to cost.	",
                "Energy project designs include consideration of quality, reliability and energy efficiency in addition to cost. A strategy is being developed to include design factor considerations such as  increased technology lifecycle, recyclability and end of life material recovery costs.",
                "Design methodology considers energy efficiency, increased technology life cycle in addition to cost and quality. Recyclability and end-of-life material recovery costs are included in total project life cycle cost. Investment decisions are usually made on the basis of total project life cycle cost economics. A register of engineering design providers that meet SEC requirements is being developed.",
                "Design methodology considers energy efficiency, increased technology life cycle in addition to cost and quality. Recyclability and end-of-life material recovery costs are included in total project life cycle cost. All investment decisions are made on the basis of total project life cycle cost economics. There is an approved register of engineering design providers that meet SEC requirements.",
            ]}
        />
    );

    const sReporting = (
        <CriteriaComponent
            color="accent"
            criteria="Reporting"
            criteriaGroup="Score weighted 50% of Safety and Sustainability"
            description="A comprehensive, audited annual sustainability report is publicly available that includes energy and emissions management strategies, policies and statistics"
            score={
                getSecScore(
                    props.secScoreGroupData.groupFilter,
                    props.secScoreGroupData.data
                ).reporting
            }
            scoreComments="A sustainability report for energy needs to be developed, that is reviewed regularly, annually audited and made publicly available."
            info={[
                "No sustainability report exists.	",
                "There is a defined plan to develop a sustainability report.	",
                "An annual sustainability report is developed. The sustainability report has limited information on energy and emissions management strategies, policies and metrics. The sutainability report is not available publicly.",
                "An annual sustainability report is published. The sustainability report contains the most relevent information on energy and emissions management strategies, policies and metrics. The sutainability report is independently audited and made publicly available.",
                "An annual sustainability report is published. The sustainability report contains all relevent  information on energy and emissions management strategies, policies and metrics. The sustainability report provides strategies and future targets to accelerate delivery of net zero energy objectives. The sutainability report is independently audited and made publicly available.",
            ]}
        />
    );

    const location = useLocation();
    const path = location.pathname.split("/", 2)[1];
    const tab = location.pathname.split("/", 3)[2];

    const basepath = "/" + path + "/" + tab;
    const tab2 = location.pathname.split("/", 4)[3];

    const basepath2 = "/" + path + "/" + tab + "/" + tab2;
    const tab3 = location.pathname.split("/", 5)[4];

    return (
        <>
            <Tabs value={tab2} textColor="secondary" indicatorColor="secondary">
                <Tab
                    label="Environment"
                    value="environment"
                    to={basepath + "/environment"}
                    component={Link}
                />
                <Tab
                    label="Social"
                    value="social"
                    to={basepath + "/social"}
                    component={Link}
                />
                <Tab
                    label="Governance"
                    value="governance"
                    to={basepath + "/governance"}
                    component={Link}
                />
            </Tabs>
            
            <TabPanel value={tab2} index="environment">
                <EnvironmentTab />
            </TabPanel>
            <TabPanel value={tab2} index="social">
                <SocialTab />
            </TabPanel>
            <TabPanel value={tab2} index="governance">
                <GovernanceTab />
            </TabPanel>
        </>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    secScoreGroupData: state.secScoreGroupData,
});

export default connect(mapStateToProps)(CriteriaDetails);
