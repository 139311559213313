import { connect } from "react-redux";
import { IProject, IProjectData } from "data/projects/project/models";
import IStoreState from "store/IStoreState";
import { useState, useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { renderCellWrapped } from "components/shared/data-grid/CellWrapped";
import { NoHoverDataGrid } from "components/shared/data-grid/NoHoverDataGrid";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface IProjectSummaryProps {
    projectData: IProjectData;

    criteria:
        | "Governance"
        | "Environment"
        | "Social"
        | "Policies, Strategies and Targets"
        | "Organisational Capability"
        | "Tools and Measurement"
        | "Baseline Energy Data"
        | "Energy Resilience"
        | "Net Zero Energy"
        | "Circular Energy Economy"
        | "Community Leadership"
        | "Safety and Sustainability";
}

function ProjectSummary(props: IProjectSummaryProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    const gPoliciesStrategiesTargetsCriteria = [
        "Policy",
        "Strategy",
        "Targets",
    ];

    const gOrganisationalCapabilityCriteria = [
        "Roles and Responsibilities",
        "Training and Competency",
        "Collaboration",
    ];

    const gToolsMeasurementCriteria = ["Metering", "Maintenance", "Monitoring"];

    const eBaselineEnergyDataCriteria = [
        "Asset Register",
        "Baseline and Interval Data",
        "Emission Scopes",
    ];

    const eEnergyResilienceCriteria = [
        "Energy Efficiency",
        "Demand Management",
        "Energy Electrification",
    ];

    const eNetZeroEnergyCriteria = ["Net Zero", "Financial Accounting"];

    const sCircularEnergyEconomyCriteria = [
        "Local Energy Use",
        "Energy Market Participation",
        "Sourcing",
    ];

    const sCommunityLeadershipCriteria = [
        "Industry Engagement",
        "Employee Engagement",
    ];

    const sSafetySustainabilityCriteria = ["Safety", "Design", "Reporting"];

    function getFilterList(criteria: string) {
        switch (criteria) {
            case "Governance":
                return [
                    ...gPoliciesStrategiesTargetsCriteria,
                    ...gOrganisationalCapabilityCriteria,
                    ...gToolsMeasurementCriteria,
                ];
            case "Environment":
                return [
                    ...eBaselineEnergyDataCriteria,
                    ...eEnergyResilienceCriteria,
                    ...eNetZeroEnergyCriteria,
                ];
            case "Social":
                return [
                    ...sCircularEnergyEconomyCriteria,
                    ...sCommunityLeadershipCriteria,
                    ...sSafetySustainabilityCriteria,
                ];
            case "Policies, Strategies and Targets":
                return gPoliciesStrategiesTargetsCriteria;
            case "Organisational Capability":
                return gOrganisationalCapabilityCriteria;
            case "Tools and Measurement":
                return gToolsMeasurementCriteria;
            case "Baseline Energy Data":
                return eBaselineEnergyDataCriteria;
            case "Energy Resilience":
                return eEnergyResilienceCriteria;
            case "Net Zero Energy":
                return eNetZeroEnergyCriteria;
            case "Circular Energy Economy":
                return sCircularEnergyEconomyCriteria;
            case "Community Leadership":
                return sCommunityLeadershipCriteria;
            case "Safety and Sustainability":
                return sSafetySustainabilityCriteria;

            default:
                return [];
        }
    }

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Id",
            hide: true,
            type: "string",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "planned",
            headerName: "Planned",
            minWidth: 100,
            flex: 1,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
            renderCell: renderCellWrapped,
        },
        {
            field: "inprogress",
            headerName: "In Progress",
            minWidth: 100,
            flex: 1,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
            renderCell: renderCellWrapped,
        },
        {
            field: "completed",
            headerName: "Completed",
            minWidth: 100,
            flex: 1,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
            renderCell: renderCellWrapped,
        },
    ];

    function getRows() {
        let plannedCount = 0;
        let inprogressCount = 0;
        let completedCount = 0;

        if (props.projectData.hasData) {
            let plannedProjects: string[] = [];
            let inprogressProjects: string[] = [];
            let completedProjects: string[] = [];

            let filters = getFilterList(props.criteria);

            filters.map((filter: string) => {
                props.projectData.data
                    .filter(
                        (x) =>
                            x.categories.includes(filter) &&
                            x.status === "Planned"
                    )
                    .map((project: IProject) => {
                        plannedProjects.push(project.code);
                    });

                props.projectData.data
                    .filter(
                        (x) =>
                            x.categories.includes(filter) &&
                            x.status === "In Progress"
                    )
                    .map((project: IProject) => {
                        inprogressProjects.push(project.code);
                    });

                props.projectData.data
                    .filter(
                        (x) =>
                            x.categories.includes(filter) &&
                            x.status === "Completed"
                    )
                    .map((project: IProject) => {
                        completedProjects.push(project.code);
                    });
            });

            plannedCount = new Set(plannedProjects).size;
            inprogressCount = new Set(inprogressProjects).size;
            completedCount = new Set(completedProjects).size;
        }

        return [
            {
                id: 1,
                planned: plannedCount,
                inprogress: inprogressCount,
                completed: completedCount,
            },
        ];
    }

    return (
        <NoHoverDataGrid
            key={"projects" + dataGridKey}
            rows={getRows()}
            columns={columns}
            disableColumnMenu={true}
            hideFooter={true}
            checkboxSelection={false}
            hideFooterSelectedRowCount={true}
            density="compact"
            autoHeight={true}
            getRowHeight={() => "auto"}
            disableSelectionOnClick={true}
        />
    );
}

const mapStateToProps = (state: IStoreState) => ({
    projectData: state.projectData,
});

export default connect(mapStateToProps)(ProjectSummary);
