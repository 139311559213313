import {
    ActionType,
    ActionTypeKey,
    IUpdateFinancialModelDataAction,
    IUpdateFinancialModelDataLoadingAction,
} from "./actions";
import { IFinancialModelData } from "./models";

const INITIAL_STATE: IFinancialModelData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    accountId: 0,
    growthPercent: 0,
    greenPowerPricing: 1,
    offsetPricing: 1
};

export default function financialModelReducer(
    state: IFinancialModelData = INITIAL_STATE,
    action: ActionType
): IFinancialModelData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_FINANCIAL_MODEL_DATA:
            return updateFinancialModelDataAction(action);
        case ActionTypeKey.UPDATE_FINANCIAL_MODEL_DATA_LOADING:
            return updateFinancialModelDataLoadingAction(action, state);
        default:
            return state;
    }
}

function updateFinancialModelDataAction(
    action: IUpdateFinancialModelDataAction
): IFinancialModelData {
    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        accountId: action.payload.accountId,
        growthPercent: action.payload.growthPercent,
        greenPowerPricing: action.payload.greenPowerPricing,
        offsetPricing: action.payload.offsetPricing
    };
}

function updateFinancialModelDataLoadingAction(
    action: IUpdateFinancialModelDataLoadingAction,
    currentState: IFinancialModelData
): IFinancialModelData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}
