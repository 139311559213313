import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    TextField,
    ToggleButtonGroup,
} from "@mui/material";
import { EsgChartComponent } from "../your-commitment/esg/EsgChartComponent";
import { useEffect, useState } from "react";
import React from "react";
import { ConsoleLogger } from "@aws-amplify/core";

function EsgTool() {
    const [value, setValue] = React.useState("Controlled");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const [secCompliant, setSecCompliant] = useState(false);
    const [scorePercent, setScorePercent] = useState(1.0);
    const [policyStrategyTargets, setPolicyStrategyTargets] = useState(1.0);
    const [organisationalCapability, setOrganisationalCapability] =
        useState(1.0);
    const [toolsMeasurement, setToolsMeasurement] = useState(1.0);
    const [baselineEnergyData, setBaselineEnergyData] = useState(1.0);
    const [energyResilience, setEnergyResilience] = useState(1.0);
    const [netZeroEnergy, setNetZeroEnergy] = useState(1.0);
    const [circularEnergyEconomy, setCircularEnergyEconomy] = useState(1.0);
    const [communityLeadership, setCommunityLeadership] = useState(1.0);
    const [safetySustainability, setSafetySustainability] = useState(1.0);

    const data = () => {
        return [
            circularEnergyEconomy,
            communityLeadership,
            safetySustainability,

            policyStrategyTargets,
            organisationalCapability,
            toolsMeasurement,

            baselineEnergyData,
            energyResilience,
            netZeroEnergy,
        ];
    };

    const score = () => {
        return scorePercent;
    };

    const isSecValid = () => {
        return secCompliant;
    };

    function tryInt(s: string) {
        // let i = Number.isInteger(Number.parseFloat(s)) ? Number.parseFloat(s) : 0;
        let i = parseFloat(s);

        return i > 5 ? 5 : i;
    }

    function tryScore(s: string) {
        // let i = Number.isInteger(Number.parseFloat(s)) ? Number.parseFloat(s) : 0;
        let i = parseFloat(s);

        return i > 100 ? 100 : i;
    }

    function createChart() {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <EsgChartComponent
                    score={score()}
                    data={data()}
                    data2={data()}
                    isSecValid={isSecValid()}
                />
            </div>
        );
    }

    return (
        <Card style={{ height: "800px" }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {createChart()}
                    </Grid>

                    <Grid item xs={3}>
                        <Stack direction="column" spacing={4}>
                            <TextField
                                id="26"
                                type="number"
                                color="info"
                                focused
                                label="Policy Strategy Targets"
                                value={policyStrategyTargets}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setPolicyStrategyTargets(
                                        tryInt(event.target.value)
                                    );
                                }}
                            />
                            <TextField
                                id="27"
                                type="number"
                                color="info"
                                focused
                                label="Organisational Capability"
                                value={organisationalCapability}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setOrganisationalCapability(
                                        tryInt(event.target.value)
                                    );
                                }}
                            />
                            <TextField
                                id="28"
                                type="number"
                                color="info"
                                focused
                                label="Tools Measurement"
                                value={toolsMeasurement}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setToolsMeasurement(
                                        tryInt(event.target.value)
                                    );
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack direction="column" spacing={4}>
                            <TextField
                                id="29"
                                type="number"
                                color="primary"
                                focused
                                label="Baseline Energy Data"
                                value={baselineEnergyData}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setBaselineEnergyData(
                                        tryInt(event.target.value)
                                    );
                                }}
                            />
                            <TextField
                                id="30"
                                type="number"
                                color="primary"
                                focused
                                label="Energy esilience"
                                value={energyResilience}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setEnergyResilience(
                                        tryInt(event.target.value)
                                    );
                                }}
                            />
                            <TextField
                                id="31"
                                type="number"
                                color="primary"
                                focused
                                label="Net Zero Energy"
                                value={netZeroEnergy}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setNetZeroEnergy(
                                        tryInt(event.target.value)
                                    );
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack direction="column" spacing={4}>
                            <TextField
                                id="34"
                                type="number"
                                color="secondary"
                                focused
                                label="Circular Energy Economy"
                                value={circularEnergyEconomy}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setCircularEnergyEconomy(
                                        tryInt(event.target.value)
                                    );
                                }}
                            />
                            <TextField
                                id="35"
                                type="number"
                                color="secondary"
                                focused
                                label="Community Leadership"
                                value={communityLeadership}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setCommunityLeadership(
                                        tryInt(event.target.value)
                                    );
                                }}
                            />
                            <TextField
                                id="36"
                                type="number"
                                color="secondary"
                                focused
                                label="Safety Sustainability"
                                value={safetySustainability}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setSafetySustainability(
                                        tryInt(event.target.value)
                                    );
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack direction="column" spacing={4}>
                            <TextField
                                id="33"
                                type="number"
                                color="info"
                                focused
                                label="Score"
                                value={scorePercent}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setScorePercent(
                                        tryScore(event.target.value)
                                    );
                                }}
                            />
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={secCompliant}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setSecCompliant(!secCompliant);
                                            }}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                        />
                                    }
                                    label="SEC Compliant"
                                />
                            </FormGroup>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default EsgTool;
