import { Card, CardContent, CardHeader } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { renderCellChartBarAccentWithTextDp0 } from "components/shared/data-grid/CellChartBar";
import { IEnergyLoadPointData } from "data/energy/energy-load-point/models";
import DataUnits from "components/shared/label/DataUnits";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface ISubMeterProps {
    energyLoadPointData: IEnergyLoadPointData;
}

function SubMeter(props: ISubMeterProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Load",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "location",
            headerName: "Location",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "consumption",
            headerName: "Consumption",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "consumptionPercent",
            headerName: "Site Consumption",
            headerAlign: "center",
            type: "number",
            minWidth: 200,
            flex: 2,
            description: "Load consumption of total site consumption",
            renderHeader: renderColumnHeader,
            renderCell: renderCellChartBarAccentWithTextDp0,
        },
    ];

    const [pageSize, setPageSize] = useState(10);

    function getRows() {
        if (props.energyLoadPointData.data.length < 1) {
            return [];
        }

        let rows = [];
        rows.push({
            id: "Load 1",
            location: "River Pump",
            consumption: (
                props.energyLoadPointData.load1Total / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            consumptionPercent: (
                (props.energyLoadPointData.load1Total /
                    props.energyLoadPointData.loadTotal) *
                100
            ).toFixed(0),
        });
        rows.push({
            id: "Load 2",
            location: "Unknown",
            consumption: (
                props.energyLoadPointData.load2Total / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            consumptionPercent: (
                (props.energyLoadPointData.load2Total /
                    props.energyLoadPointData.loadTotal) *
                100
            ).toFixed(0),
        });
        rows.push({
            id: "Load 3",
            location: "B2 AW",
            consumption: (
                props.energyLoadPointData.load3Total / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            consumptionPercent: (
                (props.energyLoadPointData.load3Total /
                    props.energyLoadPointData.loadTotal) *
                100
            ).toFixed(0),
        });
        rows.push({
            id: "Load 4",
            location: "B1 MW",
            consumption: (
                props.energyLoadPointData.load4Total / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            consumptionPercent: (
                (props.energyLoadPointData.load4Total /
                    props.energyLoadPointData.loadTotal) *
                100
            ).toFixed(0),
        });
        rows.push({
            id: "Load 5",
            location: "B3 STF",
            consumption: (
                props.energyLoadPointData.load5Total / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            consumptionPercent: (
                (props.energyLoadPointData.load5Total /
                    props.energyLoadPointData.loadTotal) *
                100
            ).toFixed(0),
        });
        rows.push({
            id: "Load 6",
            location: "B4 EMB",
            consumption: (
                props.energyLoadPointData.load6Total / 1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            consumptionPercent: (
                (props.energyLoadPointData.load6Total /
                    props.energyLoadPointData.loadTotal) *
                100
            ).toFixed(0),
        });

        return rows;
    }

    return (
        <Card>
            <CardHeader title="Sub Metered Consumption" />
            <CardContent>
                <StyledDataGrid
                    key={dataGridKey}
                    rows={getRows()}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    checkboxSelection={false}
                    hideFooterSelectedRowCount={true}
                    density="compact"
                    autoHeight={true}
                    loading={props.energyLoadPointData.isLoading}
                />
                {DataUnits("Table Units: MWh")}
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    energyLoadPointData: state.energyLoadPointData,
});

export default connect(mapStateToProps)(SubMeter);
