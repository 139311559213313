import {
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Grid,
    Tab,
    Tabs,
} from "@mui/material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { TabPanel } from "components/shared/tab/TabPanel";
import { Link, useLocation } from "react-router-dom";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import Suppliers from "./suppliers";
import { ExpandMoreButton } from "components/shared/collapse/ExpandMoreButton";
import { useEffect, useState } from "react";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { TrueOnlyButton } from "components/shared/icons/TrueFalseIcon";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

function MarketPlace() {
    const location = useLocation();
    const path = "/" + location.pathname.split("/", 2)[1];
    const tab = location.pathname.split("/", 3)[2];

    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const [expanded1, setExpanded1] = useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const [expanded4, setExpanded4] = useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const columns1: GridColDef[] = [
        {
            field: "id",
            headerName: "Id",
            type: "string",
            minWidth: 100,
            flex: 1,
            hide: true,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "company",
            headerName: "Organisation",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
        },
        {
            field: "region",
            headerName: "Region",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "solar",
            headerName: "Solar",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
        {
            field: "battery",
            headerName: "Battery",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
        {
            field: "vpp",
            headerName: "VPP",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
    ];

    function getRows1() {
        let rows = [];
        rows.push({
            id: 1,
            company: "Max Power",
            region: "AUS",
            solar: true,
            battery: true,
            vpp: true,
        });
        rows.push({
            id: 2,
            company: "Big Solar Corp",
            region: "SA",
            solar: true,
            battery: true,
            vpp: false,
        });
        rows.push({
            id: 3,
            company: "Greentech",
            region: "NSW",
            solar: true,
            battery: true,
            vpp: false,
        });
        rows.push({
            id: 4,
            company: "Besla Inc",
            region: "AUS",
            solar: false,
            battery: true,
            vpp: false,
        });

        return rows;
    }

    const columns2: GridColDef[] = [
        {
            field: "id",
            headerName: "Id",
            type: "string",
            minWidth: 100,
            flex: 1,
            hide: true,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "company",
            headerName: "Project",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
        },
        {
            field: "type",
            headerName: "Tender Type",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "budget",
            headerName: "Project Budget",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "weight",
            headerName: "Green Weighting",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "sec",
            headerName: "SEC Approved",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
    ];

    function getRows2() {
        let rows = [];
        rows.push({
            id: 1,
            company: "Battery Install",
            type: "Battery",
            budget: "1M",
            weight: "22%",
            sec: true,
        });
        rows.push({
            id: 2,
            company: "Commercial Solar Install",
            type: "Solar",
            budget: "100K",
            weight: "25%",
            sec: true,
        });
        rows.push({
            id: 3,
            company: "Solar Farm",
            type: "Solar",
            budget: "5M",
            weight: "32%",
            sec: true,
        });
        rows.push({
            id: 4,
            company: "EV Fleet Rollout",
            type: "EV",
            budget: "5M",
            weight: "27%",
            sec: true,
        });

        return rows;
    }

    const columns3: GridColDef[] = [
        {
            field: "id",
            headerName: "Id",
            type: "string",
            minWidth: 100,
            flex: 1,
            hide: true,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "company",
            headerName: "Organisation",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
        },
        {
            field: "rate",
            headerName: "Asset Finance Rate",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "min",
            headerName: "Min. Loan Size",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "max",
            headerName: "Max. Loan Size",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "discount",
            headerName: "Green Discount Rate",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
    ];

    function getRows3() {
        let rows = [];
        rows.push({
            id: 1,
            company: "Renewable Capital",
            rate: "10.0%",
            min: "5K",
            max: "10M",
            discount: "8.1%",
        });
        rows.push({
            id: 2,
            company: "Sustainable Lending",
            rate: "8.6%",
            min: "50K",
            max: "5M",
            discount: "5.6%",
        });
        rows.push({
            id: 3,
            company: "Big Bank",
            rate: "12.0%",
            min: "100K",
            max: "100M",
            discount: "10.7%",
        });
        rows.push({
            id: 4,
            company: "Green Money Finance",
            rate: "9.2%",
            min: "1M",
            max: "100M",
            discount: "8.2%",
        });

        return rows;
    }

    return (
        // <>
        //     <Tabs value={tab} textColor="secondary" indicatorColor="secondary">
        //         <Tab
        //             icon={<Inventory2OutlinedIcon />}
        //             iconPosition="start"
        //             label="Suppliers"
        //             value="suppliers"
        //             to={path + "/suppliers"}
        //             component={Link}
        //         />
        //         <Tab
        //             icon={<RecentActorsOutlinedIcon />}
        //             iconPosition="start"
        //             label="Procurement"
        //             value="procurement"
        //             to={path + "/procurement"}
        //             component={Link}
        //         />
        //         <Tab
        //             icon={<AttachMoneyIcon />}
        //             iconPosition="start"
        //             label="Financial"
        //             value="financial"
        //             to={path + "/financial"}
        //             component={Link}
        //         />
        //     </Tabs>

        //     <TabPanel value={tab} index="suppliers">
        //         <Suppliers />
        //     </TabPanel>
        // </>

        <Grid container spacing={2}>
            <Grid item xs={12} lg={expanded1 ? 12 : 6} xl={expanded1 ? 12 : 4}>
                <Card sx={{ height: "100%" }}>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "var(--max-length-medium1)",
                            }}
                        >
                            <Inventory2OutlinedIcon />
                        </div>
                        <div style={{ width: "100%" }}>
                            <CardHeader
                                title="Approved Suppliers"
                                // subheader={props.criteriaGroup}
                                action={
                                    <ExpandMoreButton
                                        expanded={expanded1}
                                        handleExpandClick={handleExpandClick1}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <Collapse in={expanded1} timeout="auto" unmountOnExit>
                        <CardContent>
                            <StyledDataGrid
                                key={dataGridKey}
                                rows={getRows1()}
                                columns={columns1}
                                disableColumnMenu={true}
                                hideFooter={true}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                                density="compact"
                                autoHeight={true}
                                loading={false}
                            />
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>
            <Grid item xs={12} lg={expanded2 ? 12 : 6} xl={expanded2 ? 12 : 4}>
                <Card sx={{ height: "100%" }}>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "var(--max-length-medium1)",
                            }}
                        >
                            <RecentActorsOutlinedIcon />
                        </div>
                        <div style={{ width: "100%" }}>
                            <CardHeader
                                title="Procurement"
                                // subheader={props.criteriaGroup}
                                action={
                                    <ExpandMoreButton
                                        expanded={expanded2}
                                        handleExpandClick={handleExpandClick2}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <Collapse in={expanded2} timeout="auto" unmountOnExit>
                        <CardContent>
                            <StyledDataGrid
                                key={dataGridKey}
                                rows={getRows2()}
                                columns={columns2}
                                disableColumnMenu={true}
                                hideFooter={true}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                                density="compact"
                                autoHeight={true}
                                loading={false}
                            />
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>

            <Grid item xs={12} lg={expanded3 ? 12 : 6} xl={expanded3 ? 12 : 4}>
                <Card sx={{ height: "100%" }}>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "var(--max-length-medium1)",
                            }}
                        >
                            <AttachMoneyIcon />
                        </div>
                        <div style={{ width: "100%" }}>
                            <CardHeader
                                title="Finance"
                                // subheader={props.criteriaGroup}
                                action={
                                    <ExpandMoreButton
                                        expanded={expanded3}
                                        handleExpandClick={handleExpandClick3}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <Collapse in={expanded3} timeout="auto" unmountOnExit>
                        <CardContent>
                            <StyledDataGrid
                                key={dataGridKey}
                                rows={getRows3()}
                                columns={columns3}
                                disableColumnMenu={true}
                                hideFooter={true}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                                density="compact"
                                autoHeight={true}
                                loading={false}
                            />
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>

            <Grid item xs={12} lg={expanded4 ? 12 : 6} xl={expanded4 ? 12 : 4}>
                <Card sx={{ height: "100%" }}>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "var(--max-length-medium1)",
                            }}
                        >
                            <DescriptionOutlinedIcon />
                        </div>
                        <div style={{ width: "100%" }}>
                            <CardHeader
                                title="Insurance"
                                // subheader={props.criteriaGroup}
                                action={
                                    <ExpandMoreButton
                                        expanded={expanded4}
                                        handleExpandClick={handleExpandClick4}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <Collapse in={expanded4} timeout="auto" unmountOnExit>
                        <CardContent></CardContent>
                    </Collapse>
                </Card>
            </Grid>
        </Grid>
    );
}

export default MarketPlace;
