import { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { connect } from "react-redux";
import {
    IElectricalAssetData,
} from "data/assets/electrical-asset/models";
import IStoreState from "store/IStoreState";
import { getElectricalAssets } from "data/assets/electrical-asset/services";
import AssetList from "./AssetList";
import AssetByGroup from "./AssetByGroup";
import SiteFilter from "components/shared/global-filter/SiteFilter";
import { IGlobalFilter } from "data/global-filter/models";

interface IElectricalAssetProp {
    globalFilter: IGlobalFilter;
    electricalAssetData: IElectricalAssetData;
}

function ElectricalAsset(props: IElectricalAssetProp) {
    useEffect(() => {
        getElectricalAssets(true, props.globalFilter.siteId);
    });

    function getAssetsByRoom(room: string) {
        return props.electricalAssetData.data.filter(
            (x) => x.room.trim() === room
        );
    }

    function getAssetsByClassification(classification: string) {
        return props.electricalAssetData.data.filter(
            (x) => x.classification.trim() === classification
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                    <SiteFilter />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <AssetByGroup
                    cardHeader="Assets By Room"
                    type="Room"
                    groups={Array.from(
                        new Set(
                            props.electricalAssetData.data.map((x) =>
                                x.room.trim()
                            )
                        )
                    )}
                    getFilteredData={getAssetsByRoom}
                />
            </Grid>
            <Grid item xs={12}>
                <AssetByGroup
                    cardHeader="Assets By Classification"
                    type="Classification"
                    groups={Array.from(
                        new Set(
                            props.electricalAssetData.data.map((x) =>
                                x.classification.trim()
                            )
                        )
                    )}
                    getFilteredData={getAssetsByClassification}
                />
            </Grid>
            <Grid item xs={12}>
                <AssetList />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
    electricalAssetData: state.electricalAssetData,
});

export default connect(mapStateToProps)(ElectricalAsset);
