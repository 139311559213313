export enum ActionTypeKey {
    UPDATE_PAGE_TITLE = "UPDATE_PAGE_TITLE",
}

export type ActionType = IUpdatePageTitleAction;

export interface IUpdatePageTitleAction {
    readonly type: ActionTypeKey.UPDATE_PAGE_TITLE;
    readonly payload: {
        readonly pageTitle: string;
    };
}

export function updatePageTitle(newPageTitle: string): IUpdatePageTitleAction {
    return {
        type: ActionTypeKey.UPDATE_PAGE_TITLE,
        payload: {
            pageTitle: newPageTitle,
        },
    };
}
