import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export interface IInfoLabelProps {
    label: string;
}

export function InfoLabel(props: IInfoLabelProps) {
    return (
        <div
            style={{
                color: "var(--max-color-label)",
                display: "flex",
                fontSize: "var(--max-font-size-subtitle)",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "var(--max-length-small3)",
                    marginRight: "var(--max-length-small3)"
                }}
            >
                <InfoOutlinedIcon />
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {props.label}
            </div>
        </div>
    );
}
