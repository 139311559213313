import { IFinancialMetric } from "./models";

export enum ActionTypeKey {
    UPDATE_FINANCIAL_METRIC_DATA = "UPDATE_FINANCIAL_METRIC_DATA",
    UPDATE_FINANCIAL_METRIC_DATA_LOADING = "UPDATE_FINANCIAL_METRIC_DATA_LOADING",
}

export type ActionType =
    | IUpdateFinancialMetricDataAction
    | IUpdateFinancialMetricDataLoadingAction;

export interface IUpdateFinancialMetricDataAction {
    type: ActionTypeKey.UPDATE_FINANCIAL_METRIC_DATA;
    payload: {
        accountId: number;
        data: IFinancialMetric;
    };
}

export interface IUpdateFinancialMetricDataLoadingAction {
    type: ActionTypeKey.UPDATE_FINANCIAL_METRIC_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateFinancialMetricData(
    accountId: number,
    data: IFinancialMetric
): IUpdateFinancialMetricDataAction {
    return {
        type: ActionTypeKey.UPDATE_FINANCIAL_METRIC_DATA,
        payload: {
            accountId: accountId,
            data: data,
        },
    };
}

export function updateFinancialMetricDataLoading(
    isLoading: boolean
): IUpdateFinancialMetricDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_FINANCIAL_METRIC_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
