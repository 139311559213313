export const routeNames: { [key: string]: string } = {
    dashboard: "Dashboard",
    "dashboard/overview": "Overview",

    yourcommitment: "Your Commitment",
    "yourcommitment/secplan": "SEC Plan",
    "yourcommitment/secplan/governance": "Governance",
    "yourcommitment/secplan/environment": "Environment",
    "yourcommitment/secplan/social": "Social",
    "yourcommitment/benchmarks": "Benchmarks",

    projectmanagement: "Project Management",
    "projectmanagement/projects": "Projects",

    energybreakdown: "Energy Breakdown",
    "energybreakdown/usage": "Usage",
    "energybreakdown/netcarbon": "Net Carbon",

    assetregister: "Asset Register",
    "assetregister/electrical": "Electrical",
    "assetregister/lighting": "Lighting",

    assetperformance: "Asset Performance",
    "assetperformance/solar": "Solar",

    financial: "Financial",
    "financial/models": "Models",
    "financial/billing": "Billing",

    marketplace: "Market Place",
};
