import { Grid, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import EsgDetails from "./EsgDetails";
import EsgChart from "./EsgChart";
import CriteriaDetails from "./CriteriaDetails";
import { loadSecScoreGroupData } from "data/sec/secScore/services";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
    IUpdateSecScoreGroupDataFilterAction,
    updateSecScoreGroupDataFilter,
} from "data/sec/secScore/actions";
import { SecScoreType } from "data/sec/secScore/models";
import { StyledToggleButton } from "components/shared/buttons/StyledToggleButton";
import Roadmap from "./Roadmap";
import IStoreState from "store/IStoreState";
import { IGlobalFilter } from "data/global-filter/models";

interface ISecPlanProps {
    globalFilter: IGlobalFilter;

    tab: string;

    updateSecScoreGroupDataFilter: (
        groupFilter: SecScoreType
    ) => IUpdateSecScoreGroupDataFilterAction;
}

function SecPlan(props: ISecPlanProps) {
    useEffect(() => {
        loadSecScoreGroupData(true, props.globalFilter.account.id);
    });

    const esgOptions = ["Baseline", "Current", "Commitment"];
    const [filterValue, setFilterValue] = useState(1);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: number
    ) => {
        setFilterValue(newValue);

        switch (newValue) {
            case 0:
                props.updateSecScoreGroupDataFilter(SecScoreType.BASELINE);
                break;
            case 2:
                props.updateSecScoreGroupDataFilter(SecScoreType.COMMITMENT);
                break;
            default:
                props.updateSecScoreGroupDataFilter(SecScoreType.CURRENT);
                break;
        }
    };

    function createFilter() {
        return (
            <div
                style={{
                    position: "absolute",
                    bottom: "var(--max-length-medium1)",
                    right: "var(--max-length-medium1)",
                    marginRight: "var(--max-length-medium1)",
                    background: "white",
                    borderRadius: "var(--max-length-button-radius)",
                    border: "4px solid var(--max-color-primary)",
                    zIndex: "100"
                }}
            >
                <Tooltip title="Toggle all scores on this page">
                    <ToggleButtonGroup
                        orientation="horizontal"
                        value={filterValue}
                        exclusive
                        onChange={handleChange}
                        size="small"
                    >
                        {esgOptions.map((x: string, i: number) => {
                            return (
                                <StyledToggleButton
                                    key={`esgScoreFilter-${i}`}
                                    value={i}
                                    aria-label="list"
                                    color="primary"
                                >
                                    <div
                                        style={{
                                            textAlign: "left",
                                            textTransform: "capitalize",
                                            marginRight: "auto",
                                        }}
                                    >
                                        {x}
                                    </div>
                                </StyledToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>
                </Tooltip>
            </div>
        );
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} order={{ xs: 1 }}>
                    <Roadmap />
                </Grid>
                <Grid item xs={12} xl={7} order={{ xs: 3, xl: 2 }}>
                    <EsgDetails />
                </Grid>
                <Grid item xs={12} xl={5} order={{ xs: 2, xl: 3 }}>
                    <EsgChart />
                </Grid>
                <Grid item xs={12} order={{ xs: 4 }}>
                    <CriteriaDetails />
                </Grid>
            </Grid>

            <div style={{ height: "var(--max-length-large3)" }} />
            {createFilter()}
        </>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateSecScoreGroupDataFilter: bindActionCreators(
        updateSecScoreGroupDataFilter,
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecPlan);
