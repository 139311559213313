import { connect } from "react-redux";
import { IProject, IProjectData } from "data/projects/project/models";
import IStoreState from "store/IStoreState";
import {
    Card,
    CardContent,
    LinearProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";
import {
    Chart as ChartJS,
    registerables,
    TimeUnit,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import moment from "moment";
import "chartjs-adapter-moment";

interface IGanttProps {
    projectData: IProjectData;
}

function Gantt(props: IGanttProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    var style = getComputedStyle(document.body);
    var chartColor1 = style.getPropertyValue("--max-color-chart-primary");
    var chartColor2 = style.getPropertyValue("--max-color-chart-accent");
    var chartColor3 = style.getPropertyValue("--max-color-chart-grey");

    ChartJS.register(...registerables);

    const options = {
        indexAxis: "y" as const,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top" as const,
            },
            tooltip: {
                displayColors: false,
                xAlign: "left" as const,
                callbacks: {
                    label: function (context: any) {
                        let value = context.dataset.data[context.dataIndex];
                        let from = moment(value[0]).format("DD/MM/YYYY");
                        let to = moment(value[1]).format("DD/MM/YYYY");
                        return ["Start: " + from, "End: " + to];
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                type: "time" as const,
                time: {
                    unit: "month" as TimeUnit,
                },
                min: Number(moment("2022-06-01")),
                max: Number(moment("2023-06-01")),
            },
            y: {
                stacked: true,
            },
        },
    };

    function createChart() {
        return (
            <>
                <div
                    style={{
                        height: "var(--max-size-chart-large)",
                        width: "99%",
                    }}
                >
                    <Chart type="bar" options={options} data={getChartData()} />
                </div>
                {props.projectData.isLoading ? <LinearProgress /> : <></>}
            </>
        );
    }

    function getChartData() {
        let filteredData = props.projectData.data.filter(
            (x) => x.startDate !== null
        );
        filteredData = filteredData.sort((a: IProject, b: IProject) => {
            if (a.status < b.status) {
                return -1;
            }
            if (a.status > b.status) {
                return 1;
            }

            if (a.status === "Completed" || a.status === "In Progress") {
                if (a.endDate < b.endDate) {
                    return -1;
                }
                if (a.endDate > b.endDate) {
                    return 1;
                }
                return 0;
            }

            // Planned
            if (a.startDate < b.startDate) {
                return -1;
            }
            if (a.startDate > b.startDate) {
                return 1;
            }
            return 0;
        });

        let labels = filteredData.map((x: { name: any }) => x.name);

        return {
            labels,
            datasets: [
                {
                    type: "bar" as const,
                    label: "Completed",
                    data: filteredData.map((x) => [
                        x.status === "Completed"
                            ? moment(x.startDate, "YYYY-MM-DD")
                            : 0,
                        x.status === "Completed"
                            ? moment(x.endDate, "YYYY-MM-DD")
                            : 0,
                    ]),
                    backgroundColor: chartColor1,
                },
                {
                    type: "bar" as const,
                    label: "In Progress",
                    data: filteredData.map((x) => [
                        x.status === "In Progress"
                            ? moment(x.startDate, "YYYY-MM-DD")
                            : 0,
                        x.status === "In Progress"
                            ? moment(x.endDate, "YYYY-MM-DD")
                            : 0,
                    ]),
                    backgroundColor: chartColor2,
                },
                {
                    type: "bar" as const,
                    label: "Planned",
                    data: filteredData.map((x) => [
                        x.status === "Planned"
                            ? moment(x.startDate, "YYYY-MM-DD")
                            : 0,
                        x.status === "Planned"
                            ? moment(x.endDate, "YYYY-MM-DD")
                            : 0,
                    ]),
                    backgroundColor: chartColor3,
                },
            ],
        };
    }

    return (
        <Card>
            {/* <CardHeader title="Projects" /> */}
            <CardContent>{createChart()}</CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    projectData: state.projectData,
});

export default connect(mapStateToProps)(Gantt);
