import { createTheme, Theme } from "@mui/material";
import "./MuiTheme.css";


export default function getDefaultMuiTheme(): Theme {
    const theme = createTheme({
        typography: {
            fontFamily: "Roboto",
        },
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: "#bdce56",
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                main: "#f1a341",
                // dark: will be calculated from palette.secondary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            info: {
                main: "#87939c",
                // dark: will be calculated from palette.secondary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: "0.875rem"
                    }
                }
            }
        }
    });

    return theme;
}
