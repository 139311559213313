import { IAccount, ISite, IUser } from "./models";

export function getSitesByUser(user: IUser): ISite[] {
    let sites: ISite[] = [];

    user.accounts.forEach((acc: IAccount) => {
        acc.sites.forEach((site: ISite) => {
            sites.push(site);
        });
    });

    return sites;
}

export function getFirstAccountByUser(user: IUser): IAccount {
    return user.accounts[0];
}

export function getFirstSiteByUser(user: IUser): ISite {
    return user.accounts[0].sites[0];
}
