import { ActionType, ActionTypeKey, IUpdateProjectDataAction, IUpdateProjectDataLoadingAction } from "./actions";
import { IProjectData } from "./models";

const INITIAL_STATE: IProjectData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    accountId: 0
};

export default function projectReducer(
    state: IProjectData = INITIAL_STATE,
    action: ActionType
): IProjectData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_PROJECT_DATA:
            return updateProjectDataAction(action);
        case ActionTypeKey.UPDATE_PROJECT_DATA_LOADING:
            return updateProjectDataLoadingAction(action, state);
        default:
            return state;
    }
}

function updateProjectDataAction(
    action: IUpdateProjectDataAction
): IProjectData {
    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        accountId: action.payload.accountId
    };
}

function updateProjectDataLoadingAction(
    action: IUpdateProjectDataLoadingAction,
    currentState: IProjectData
): IProjectData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading
    };
}
