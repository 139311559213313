import { Grid } from "@mui/material";
import { useEffect } from "react";
import { loadSecScoreGroupData } from "data/sec/secScore/services";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
    IUpdateSecScoreGroupDataFilterAction,
    updateSecScoreGroupDataFilter,
} from "data/sec/secScore/actions";
import { SecScoreType } from "data/sec/secScore/models";
import Metrics from "./Metrics";
import EsgChart from "./EsgChart";
import Notification from "./Notification";
import { loadProjectData } from "data/projects/project/services";
import IStoreState from "store/IStoreState";
import { IGlobalFilter } from "data/global-filter/models";
import Profile from "./Profile";

interface IOverviewProps {
    globalFilter: IGlobalFilter;

    updateSecScoreGroupDataFilter: (
        groupFilter: SecScoreType
    ) => IUpdateSecScoreGroupDataFilterAction;
}

function Overview(props: IOverviewProps) {
    useEffect(() => {
        loadSecScoreGroupData(true, props.globalFilter.account.id);
        loadProjectData(true, props.globalFilter.account.id);
    });

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Profile />
                </Grid>
                <Grid item xs={12}>
                    <Notification />
                </Grid>
                <Grid item xs={12}>
                    <Metrics />
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateSecScoreGroupDataFilter: bindActionCreators(
        updateSecScoreGroupDataFilter,
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
