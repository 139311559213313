import { Box, CircularProgress } from "@mui/material";
import { MetricIcon } from "./MetricIcon";

interface IMetricProps {
    isLoadingIcon: boolean;
    isLoadingContent: boolean;
    variant: "default" | "stacked";
    color: "primary" | "accent" | "grey";
    value: number;
    title: string;
    content?: JSX.Element;
}

function getIcon(
    isLoadingIcon: boolean,
    color: "primary" | "accent" | "grey",
    value: number
) {
    return <MetricIcon isLoading={isLoadingIcon} color={color} value={value} />;
}

function getContent(
    isLoadingContent: boolean,
    title: string,
    content?: JSX.Element
) {
    return (
        <div style={{ width: "100%" }}>
            {isLoadingContent ? (
                <Box
                    sx={{
                        height: "9rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            ) : content !== undefined ? (
                <>
                    <div
                        style={{
                            fontSize: "var(--max-font-size-title)",
                            // fontWeight: "bold",
                            color: "var(--max-color-title)",
                        }}
                    >
                        {title}
                    </div>
                    {content}
                </>
            ) : (
                <div
                    style={{
                        fontSize: "var(--max-font-size-title)",
                        // fontWeight: "bold",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        color: "var(--max-color-title)",
                    }}
                >
                    {title}
                </div>
            )}
        </div>
    );
}

export function Metric(props: IMetricProps) {
    if (props.variant === "stacked") {
        return (
            <div
                style={{
                    width: "100%",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {getIcon(props.isLoadingIcon, props.color, props.value)}
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <div>
                    {getContent(
                        props.isLoadingContent,
                        props.title,
                        props.content
                    )}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                }}
            >
                <div style={{ marginRight: "var(--max-length-medium3)" }}>
                    {getIcon(props.isLoadingIcon, props.color, props.value)}
                </div>
                {getContent(props.isLoadingContent, props.title, props.content)}
            </div>
        );
    }
}
