import { ActionType, ActionTypeKey, IUpdatePageTitleAction } from "./actions";

const INITIAL_STATE = "Welcome";

export default function headerReducer(
    state = INITIAL_STATE,
    action: ActionType
): string {
    switch (action.type) {
        case ActionTypeKey.UPDATE_PAGE_TITLE:
            return updatePageTitle(action);
        default:
            return state;
    }
}

function updatePageTitle(action: IUpdatePageTitleAction) {
    return action.payload.pageTitle;
}
