import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Stack,
    TextField,
    ToggleButtonGroup,
} from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import IStoreState from "store/IStoreState";
import { useState, useEffect } from "react";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";
import { IFinancialModelData } from "data/finance/financial-model/models";
import { loadFinancialModelData } from "data/finance/financial-model/services";
import DataUnits from "components/shared/label/DataUnits";
import { IGlobalFilter } from "data/global-filter/models";
import {
    IUpdateSecScoreGroupDataFilterAction,
    updateSecScoreGroupDataFilter,
} from "data/sec/secScore/actions";
import { ISecScoreGroupData, SecScoreType } from "data/sec/secScore/models";
import {
    getSecScore,
    getSecScoreComparison,
    loadSecScoreGroupData,
} from "data/sec/secScore/services";
import { StyledToggleButton } from "components/shared/buttons/StyledToggleButton";

import photo from "assets/image/profile_photo.png";
import logo_industry from "assets/image/logo_industry.png";
import banner_industry from "assets/image/profile_banner_industry.png";
import logo_bowhill from "assets/image/logo_bowhill.png";
import logo_circular from "assets/image/logo_circulargroup.png";
import logo_saputo from "assets/image/logo_saputo.png";
import logo_foodland from "assets/image/logo_foodland.png";
import banner_bowhill from "assets/image/profile_banner_bowhill.png";
import logo_demo1 from "assets/image/logo_demo1.jpg";
import logo_demo2 from "assets/image/logo_demo2.png";
import logo_demo3 from "assets/image/logo_demo3.png";
import logo_demo4 from "assets/image/logo_demo4.png";
import logo_mangoes from "assets/image/logo_mangoes.png";
import banner_mangoes from "assets/image/profile_banner_mangoes.png";

import EsgBase from "assets/image/esg_score_ring.svg";
import { EsgChartComponent } from "../esg/EsgChartComponent";

interface IBenchmarksProp {
    globalFilter: IGlobalFilter;

    updateSecScoreGroupDataFilter: (
        groupFilter: SecScoreType
    ) => IUpdateSecScoreGroupDataFilterAction;

    secScoreGroupData: ISecScoreGroupData;
}

function Benchmarks(props: IBenchmarksProp) {
    useEffect(() => {
        loadSecScoreGroupData(true, props.globalFilter.account.id);
    });

    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    var style = getComputedStyle(document.body);
    var colorPrimary = style.getPropertyValue("--max-color-primary");
    var colorAccent = style.getPropertyValue("--max-color-accent");
    var barColor1 = style.getPropertyValue("--max-color-chart-primary");
    var barColor2 = style.getPropertyValue("--max-color-chart-grey");
    var barColor3 = style.getPropertyValue("--max-color-chart-accent");

    ChartJS.register(...registerables);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top" as const,
            },
        },
    };

    function createChart() {
        return (
            <>
                <div
                    style={{
                        height: "var(--max-size-chart-large)",
                        width: "99%",
                    }}
                >
                    <Chart type="bar" options={options} data={getChartData()} />
                </div>
                {props.secScoreGroupData.isLoading ? <LinearProgress /> : <></>}
            </>
        );
    }

    function getChartData() {
        let labels = [
            "Baseline Energy Data",
            "Energy Resilience",
            "Net Zero Energy",
            "Circular Energy Economy",
            "Community Leadership",
            "Safety Sustainability",
            "Policy, Strategy, and Targets",
            "Organisational Capability",
            "Tools and Measurement",
        ];

        let source = getSecScore(
            props.secScoreGroupData.groupFilter,
            props.secScoreGroupData.data
        );
        let comparison = getSecScoreComparison(
            props.secScoreGroupData.groupFilter,
            props.secScoreGroupData.data
        );

        return {
            labels,
            datasets: [
                {
                    type: "bar" as const,
                    label: "Your Score",
                    data: [
                        source.baselineEnergyData,
                        source.energyResilience,
                        source.netZeroEnergy,

                        source.circularEnergyEconomy,
                        source.communityLeadership,
                        source.safetySustainability,

                        source.policyStrategyTargets,
                        source.organisationalCapability,
                        source.toolsMeasurement,
                    ],
                    backgroundColor: barColor1,
                },
                // {
                //     type: "bar" as const,
                //     label: "Industry Highest Score",
                //     data: [

                //         comparison.policyStrategyTargets,
                //         comparison.organisationalCapability,
                //         comparison.toolsMeasurement,

                //         comparison.baselineEnergyData,
                //         comparison.energyResilience,
                //         comparison.netZeroEnergy,

                //         comparison.circularEnergyEconomy,
                //         comparison.communityLeadership,
                //         comparison.safetySustainability,
                //     ],
                //     backgroundColor: barColor3,
                // },
                {
                    type: "bar" as const,
                    label: "Industry Average Score",
                    data: [
                        comparison.baselineEnergyData,
                        comparison.energyResilience,
                        comparison.netZeroEnergy,

                        comparison.circularEnergyEconomy,
                        comparison.communityLeadership,
                        comparison.safetySustainability,

                        comparison.policyStrategyTargets,
                        comparison.organisationalCapability,
                        comparison.toolsMeasurement,
                    ],
                    backgroundColor: barColor2,
                },
            ],
        };
    }

    const esgOptions = ["Baseline", "Current", "Commitment"];
    const [filterValue, setFilterValue] = useState(1);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: number
    ) => {
        setFilterValue(newValue);

        switch (newValue) {
            case 0:
                props.updateSecScoreGroupDataFilter(SecScoreType.BASELINE);
                break;
            case 2:
                props.updateSecScoreGroupDataFilter(SecScoreType.COMMITMENT);
                break;
            default:
                props.updateSecScoreGroupDataFilter(SecScoreType.CURRENT);
                break;
        }
    };

    function secScore(score: number, secCompliant: boolean) {
        // let source = getSecScore(
        //     SecScoreType.CURRENT,
        //     props.secScoreGroupData.data
        // );

        return (
            <div
                style={{
                    position: "absolute",
                    float: "right",
                    top: "var(--max-length-medium1)",
                    right: "var(--max-length-medium1)",
                    // transform: "translate(0%, -100%)",
                }}
            >
                <div
                    style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            height: "100px",
                            width: "100px",
                            position: "relative",
                        }}
                    >
                        <img
                            alt=""
                            src={EsgBase}
                            height="100%"
                            width="100%"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                zIndex: 11,
                            }}
                        />
                        <div
                            style={{
                                position: "absolute",
                                borderRadius: "50%",
                                width: "77%",
                                height: "77%",
                                top: "50%",
                                left: "50%",
                            }}
                        >
                            <div
                                style={{
                                    zIndex: 13,
                                    position: "absolute",
                                    borderRadius: "50%",
                                    width: "100%",
                                    height: "100%",
                                    transform: "translate(-50%, -50%)",
                                    background: "white",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        color: secCompliant
                                            ? colorPrimary
                                            : colorAccent,
                                        fontSize: "1.5rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {score}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            border: "15px white solid",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            zIndex: 9,
                        }}
                    />
                </div>
            </div>
        );
    }

    function scoreCard(company: string, score: number, secCompliant: boolean) {
        var logo = logo_bowhill;
        if (company === "demo1") {
            logo = logo_demo1;
        } else if (company === "demo2") {
            logo = logo_demo2;
        } else if (company === "demo3") {
            logo = logo_demo3;
        } else if (company === "demo4") {
            logo = logo_demo4;
        }

        return (
            <Card>
                <div
                    style={{
                        position: "relative",
                        height: "150px",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={logo}
                            style={{
                                maxWidth: "200px",
                                maxHeight: "150px",
                                position: "absolute",
                                margin: "var(--max-length-medium1)",
                            }}
                        />
                    </div>
                    {secScore(score, secCompliant)}
                    {/* <img
                        src={banner_bowhill}
                        style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                        }}
                    /> */}
                </div>
                {/* <CardContent></CardContent> */}
            </Card>
        );
    }

    const data = () => {
        let source = getSecScoreComparison(
            SecScoreType.CURRENT,
            props.secScoreGroupData.data
        );
        return [
            source.circularEnergyEconomy,
            source.communityLeadership,
            source.safetySustainability,

            source.policyStrategyTargets,
            source.organisationalCapability,
            source.toolsMeasurement,

            source.baselineEnergyData,
            source.energyResilience,
            source.netZeroEnergy,
        ];
    };

    const score = () => {
        return getSecScoreComparison(
            SecScoreType.CURRENT,
            props.secScoreGroupData.data
        ).scorePercent;
    };

    const isSecValid = () => {
        return getSecScoreComparison(
            SecScoreType.CURRENT,
            props.secScoreGroupData.data
        ).secCompliant;
    };

    const banner = (
        <Card>
            <div style={{ position: "relative" }}>
                <img
                    src={logo_industry}
                    style={{
                        height: "100px",
                        position: "absolute",
                        margin: "var(--max-length-medium1)",
                        background: "white",
                    }}
                />
                <img
                    src={banner_industry}
                    style={{
                        width: "100%",
                        minWidth: "800px",
                        height: "240px",
                        objectFit: "cover",
                    }}
                />
            </div>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <CardContent style={{ border: "1px red" }}>
                            <div>
                                <strong>
                                    At Regional Development Australia
                                    Murraylands and Riverland, our vision for
                                    the next decade is that the Murraylands and
                                    Riverland is recognised internationally as a
                                    vibrant, world leading circular economy with
                                    high-performing businesses, resilient
                                    communities and individuals with a deep
                                    sense of wellbeing and connection with the
                                    world around us.
                                </strong>
                                <br />
                                <br />
                                We are part of a national network of 52
                                organisations aiming to grow and strengthen
                                Australia's regions, through advocacy,
                                facilitation and funded service delivery. We
                                raise organisational capabilities, enable more
                                targeted and productive collaboration, encourage
                                priority infrastructure investment to be brought
                                forward through government support, advocate to
                                address regional constraints, and help to close
                                the gap on workforce planning needs.
                            </div>
                            {/* <div>
                                <strong>
                                    The Australian Mango Industry Association
                                    (AMIA) is the peak industry body for the
                                    Australian mango industry. We were
                                    established in 2000 after mango growers and
                                    allied businesses identified the need for a
                                    strong and united industry.
                                </strong>
                                <br />
                                <br />
                                Our aim is to represent the interests of our
                                members and promote the growth and development
                                of the industry to ensure its long term
                                sustainability. We work closely with Hort
                                Innovation and a range of other organisations,
                                including Government and commercial businesses,
                                to achieve this aim. Our members include mango
                                growers, wholesalers, processors and associated
                                businesses. Our activities are predominantly
                                funded through membership fees and provision of
                                services to industry. We are a 'not-for-profit'
                                company limited by guarantee and comply with the
                                rules of the Australian Securities Investment
                                Commission.
                            </div> */}
                        </CardContent>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{ height: "220px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    transform: "translate(0%, -50%)",
                                }}
                            >
                                <EsgChartComponent
                                    score={score()}
                                    data={data()}
                                    data2={data()}
                                    isSecValid={isSecValid()}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {banner}
            </Grid>
            <Grid item xs={3}>
                {scoreCard("bowhill", 49, false)}
            </Grid>
            <Grid item xs={3}>
                {scoreCard("demo1", 82, true)}
            </Grid>
            <Grid item xs={3}>
                {scoreCard("demo2", 68, false)}
            </Grid>
            <Grid item xs={3}>
                {scoreCard("demo3", 35, false)}
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title="SEC Score Comparison" />
                    <CardContent>
                        <Stack spacing={2} direction="column">
                            {createChart()}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <ToggleButtonGroup
                                    orientation="horizontal"
                                    value={filterValue}
                                    exclusive
                                    onChange={handleChange}
                                    size="small"
                                >
                                    {esgOptions.map((x: string, i: number) => {
                                        return (
                                            <StyledToggleButton
                                                key={`esgScoreFilter-${i}`}
                                                value={i}
                                                aria-label="list"
                                                color="primary"
                                            >
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                        textTransform:
                                                            "capitalize",
                                                        marginRight: "auto",
                                                    }}
                                                >
                                                    {x}
                                                </div>
                                            </StyledToggleButton>
                                        );
                                    })}
                                </ToggleButtonGroup>
                            </div>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
    secScoreGroupData: state.secScoreGroupData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateSecScoreGroupDataFilter: bindActionCreators(
        updateSecScoreGroupDataFilter,
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Benchmarks);
