import { Navigate, Route, Routes } from "react-router-dom";
import App from "../../App";
import AssetPerformance from "components/feature/asset-performance";
import EnergyBreakdown from "components/feature/energy-breakdown";
import AssetRegister from "components/feature/asset-register";
import Dashboard from "components/feature/dashboard";
import YourCommitment from "components/feature/your-commitment";
import AppBreadcrumbs from "./AppBreadcrumbs";
import React from "react";
import ProjectManagement from "components/feature/project-management";
import FinancialModels from "components/feature/financial/financial-models";
import Financial from "components/feature/financial";
import MarketPlace from "components/feature/market-place";
import EsgTool from "components/feature/esg-tool/EsgTool";

type Redirect = {
    from: string;
    to: string;
};

function RouteGroup(paths: string[]) {
    let routes: string[] = [];
    let redirects: Redirect[] = [];

    const Component: React.FC = GetRouteComponent(paths[0].split("/", 2)[1]);

    paths.forEach((path) => {
        let parts = path.split("/");

        parts.map((part: string, index: number) => {
            let last = index + 1 === parts.length;
            let targetPath = `${parts.slice(0, index + 1).join("/")}`;

            if (last) {
                routes.push(targetPath);
            } else {
                if (!redirects.some((x) => x.from === targetPath)) {
                    redirects.push({ from: targetPath, to: path });
                }
            }
        });
    });

    return (
        <>
            {routes.map((x: string, index: number) => (
                <Route key={index} path={x} element={<Component />} />
            ))}
            {redirects.map((x: Redirect, index: number) => (
                <Route
                    key={index}
                    path={x.from}
                    element={<Navigate replace to={x.to} />}
                />
            ))}
        </>
    );
}

function GetRouteComponent(path: string): React.FC {
    switch (path) {
        case "dashboard":
            return () => <Dashboard />;

        case "yourcommitment":
            return () => <YourCommitment />;

        case "projectmanagement":
            return () => <ProjectManagement />;

        case "energybreakdown":
            return () => <EnergyBreakdown />;

        case "assetregister":
            return () => <AssetRegister />;

        case "assetperformance":
            return () => <AssetPerformance />;

        case "financial":
            return () => <Financial />;

        case "marketplace":
            return () => <MarketPlace />;

        case "esgtool":
            return () => <EsgTool />;

        default:
            return () => <Dashboard />;
    }
}

function AppRoutes() {
    return (
        <>
            <AppBreadcrumbs />
            <Routes>
                <Route path="/" element={<Navigate replace to="dashboard" />} />
                <Route path="app" element={<App />} />

                {RouteGroup(["/dashboard/overview"])}

                {RouteGroup([
                    "/yourcommitment/secplan/environment",
                    "/yourcommitment/secplan/social",
                    "/yourcommitment/secplan/governance",
                    "/yourcommitment/benchmarks",
                ])}

                {RouteGroup(["/projectmanagement/projects"])}

                {RouteGroup(["/energybreakdown/usage"])}
                {RouteGroup(["/energybreakdown/netcarbon"])}

                {RouteGroup([
                    "/assetregister/electrical",
                    "/assetregister/lighting",
                ])}

                {RouteGroup(["/assetperformance/solar"])}

                {RouteGroup(["/financial/models"])}
                {RouteGroup(["/financial/billing"])}

                {RouteGroup(["/marketplace"])}

                {RouteGroup(["/esgtool"])}
            </Routes>
        </>
    );
}

export default AppRoutes;
