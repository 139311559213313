import { IUser } from "./models";

export enum ActionTypeKey {
    UPDATE_USER_DATA = "UPDATE_USER_DATA",
    UPDATE_USER_DATA_LOADING = "UPDATE_USER_DATA_LOADING",
}

export type ActionType = IUpdateUserDataAction | IUpdateUserDataLoadingAction;

export interface IUpdateUserDataAction {
    type: ActionTypeKey.UPDATE_USER_DATA;
    payload: {
        data: IUser;
    };
}

export interface IUpdateUserDataLoadingAction {
    type: ActionTypeKey.UPDATE_USER_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateUserData(data: IUser): IUpdateUserDataAction {
    return {
        type: ActionTypeKey.UPDATE_USER_DATA,
        payload: {
            data: data,
        },
    };
}

export function updateUserDataLoading(
    isLoading: boolean
): IUpdateUserDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_USER_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
