import { IInvoice, IGasInvoice, IDiselInvoice } from "./models";

export enum ActionTypeKey {
    UPDATE_INVOICE_DATA = "UPDATE_INVOICE_DATA",
    UPDATE_INVOICE_DATA_LOADING = "UPDATE_INVOICE_DATA_LOADING",
    UPDATE_GAS_INVOICE_DATA = "UPDATE_GAS_INVOICE_DATA",
    UPDATE_GAS_INVOICE_DATA_LOADING = "UPDATE_GAS_INVOICE_DATA_LOADING",
    UPDATE_DISEL_INVOICE_DATA = "UPDATE_DISEL_INVOICE_DATA",
    UPDATE_DISEL_INVOICE_DATA_LOADING = "UPDATE_DISEL_INVOICE_DATA_LOADING",
}

export type ActionType =
    | IUpdateInvoiceDataAction
    | IUpdateInvoiceDataLoadingAction
    | IUpdateGasInvoiceDataAction
    | IUpdateGasInvoiceDataLoadingAction
    | IUpdateDiselInvoiceDataAction
    | IUpdateDiselInvoiceDataLoadingAction;

export interface IUpdateInvoiceDataAction {
    type: ActionTypeKey.UPDATE_INVOICE_DATA;
    payload: {
        accountId: number;
        data: IInvoice[];
    };
}

export interface IUpdateInvoiceDataLoadingAction {
    type: ActionTypeKey.UPDATE_INVOICE_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateInvoiceData(
    accountId: number,
    data: IInvoice[]
): IUpdateInvoiceDataAction {
    return {
        type: ActionTypeKey.UPDATE_INVOICE_DATA,
        payload: {
            accountId: accountId,
            data: data,
        },
    };
}

export function updateInvoiceDataLoading(
    isLoading: boolean
): IUpdateInvoiceDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_INVOICE_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}

export interface IUpdateGasInvoiceDataAction {
    type: ActionTypeKey.UPDATE_GAS_INVOICE_DATA;
    payload: {
        accountId: number;
        data: IGasInvoice[];
    };
}

export interface IUpdateGasInvoiceDataLoadingAction {
    type: ActionTypeKey.UPDATE_GAS_INVOICE_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateGasInvoiceData(
    accountId: number,
    data: IGasInvoice[]
): IUpdateGasInvoiceDataAction {
    return {
        type: ActionTypeKey.UPDATE_GAS_INVOICE_DATA,
        payload: {
            accountId: accountId,
            data: data,
        },
    };
}

export function updateGasInvoiceDataLoading(
    isLoading: boolean
): IUpdateGasInvoiceDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_GAS_INVOICE_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}


export interface IUpdateDiselInvoiceDataAction {
    type: ActionTypeKey.UPDATE_DISEL_INVOICE_DATA;
    payload: {
        accountId: number;
        data: IDiselInvoice[];
    };
}

export interface IUpdateDiselInvoiceDataLoadingAction {
    type: ActionTypeKey.UPDATE_DISEL_INVOICE_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateDiselInvoiceData(
    accountId: number,
    data: IDiselInvoice[]
): IUpdateDiselInvoiceDataAction {
    return {
        type: ActionTypeKey.UPDATE_DISEL_INVOICE_DATA,
        payload: {
            accountId: accountId,
            data: data,
        },
    };
}

export function updateDiselInvoiceDataLoading(
    isLoading: boolean
): IUpdateDiselInvoiceDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_DISEL_INVOICE_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}