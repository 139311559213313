import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from "redux";
import logo from "./logo.svg";
import "./App.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ApiService from "./services/ApiService";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import qs from "qs";
import { IUpdatePageTitleAction, updatePageTitle } from 'components/layout/header/actions';

interface IAppProp {
    updatePageTitle: (newPageTitle: string) => IUpdatePageTitleAction;
}

function App(props: IAppProp) {
    const { user, signOut } = useAuthenticator((context) => [context.user]);

    const token = async () => {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        const idToken = session.getIdToken().getJwtToken();
        const refreshToken = session.getRefreshToken().getToken();
        console.log("ID");
        console.log(idToken);
        console.log("ACCESS");
        console.log(accessToken);
        console.log("REFRESH");
        console.log(refreshToken);
        console.log("SESSION");
        console.log(session);
    };

    const data = async () => {
        await ApiService.get(`/energy`, {
            params: {
                serials: ["60983", "60976"],
                fromDate: "2022-1-1",
                toDate: "2022-2-1",
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                console.log("api result");
                console.log(res);
                console.log(res.data);
            })
            .catch((error) => {
                console.log("api error");
                console.log(error);
            });

        console.log("done");
    };

    useEffect(() => {
        // props.setTitle("Welcome");
        props.updatePageTitle("App");

        token();

        // data();
    });

    return (
        <div className="App">
            <header className="App-header">
                <h2>Welcome, {user.username}!</h2>
                <button onClick={signOut}>Sign Out</button>

                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.tsx</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updatePageTitle: bindActionCreators(updatePageTitle, dispatch),
});

export default connect(null, mapDispatchToProps)(App);
