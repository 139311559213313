import qs from "qs";
import ApiService from "services/ApiService";
import { ISecScore, ISecScoreGroup, SecScoreType } from "./models";
import { store } from "store/store";
import {
    updateSecScoreGroupData,
    updateSecScoreGroupDataLoading,
} from "./actions";

export function loadSecScoreGroupData(useCache: boolean, accountId: number) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const secScoreGroupData = store.getState().secScoreGroupData;

    if (
        !secScoreGroupData.isLoading &&
        (!useCache ||
            secScoreGroupData.requireLoad ||
            secScoreGroupData.accountId !== accountId)
    ) {
        store.dispatch(updateSecScoreGroupDataLoading(true));

        ApiService.get(`/sec/GetSecScoreGroups`, {
            params: {
                accountId: accountId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = {
                    baseline: parseSecScore(res.data.baseline),
                    current: parseSecScore(res.data.current),
                    commitment: parseSecScore(res.data.commitment),
                    baselineComparison: parseSecScore(
                        res.data.baselineComparison
                    ),
                    currentComparison: parseSecScore(
                        res.data.currentComparison
                    ),
                    commitmentComparison: parseSecScore(
                        res.data.commitmentComparison
                    ),
                } as ISecScoreGroup;

                store.dispatch(updateSecScoreGroupData(accountId, data));
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(updateSecScoreGroupDataLoading(false));
            });
    }
}

function parseSecScore(data: any) {
    return {
        secCompliant: data.secCompliant,
        scorePercent: data.scorePercent,
        governance: data.governance,
        environment: data.environment,
        social: data.social,
        policyStrategyTargets: data.policyStrategyTargets,
        organisationalCapability: data.organisationalCapability,
        toolsMeasurement: data.toolsMeasurement,
        baselineEnergyData: data.baselineEnergyData,
        energyResilience: data.energyResilience,
        netZeroEnergy: data.netZeroEnergy,
        circularEnergyEconomy: data.circularEnergyEconomy,
        communityLeadership: data.communityLeadership,
        safetySustainability: data.safetySustainability,
        policy: data.policy,
        strategies: data.strategies,
        targets: data.targets,
        rolesResponsibilities: data.rolesResponsibilities,
        trainingCompetency: data.trainingCompetency,
        collaboration: data.collaboration,
        metering: data.metering,
        maintenance: data.maintenance,
        monitoring: data.monitoring,
        assetRegister: data.assetRegister,
        baselineIntervalData: data.baselineIntervalData,
        emissionScopes: data.emissionScopes,
        energyEfficiency: data.energyEfficiency,
        demandManagement: data.demandManagement,
        energyElectrification: data.energyElectrification,
        netZero: data.netZero,
        financialAccounting: data.financialAccounting,
        localEnergyUse: data.localEnergyUse,
        energyMarketParticipation: data.energyMarketParticipation,
        sourcing: data.sourcing,
        industryEngagement: data.industryEngagement,
        employeeEngagement: data.employeeEngagement,
        safety: data.safety,
        design: data.design,
        reporting: data.reporting,
    } as ISecScore;
}

export function getSecScore(type: SecScoreType, data?: ISecScoreGroup) {
    if (data === undefined) {
        return {
            secCompliant: false,
            scorePercent: 0,
            governance: 0,
            environment: 0,
            social: 0,
            policyStrategyTargets: 0,
            organisationalCapability: 0,
            toolsMeasurement: 0,
            baselineEnergyData: 0,
            energyResilience: 0,
            netZeroEnergy: 0,
            circularEnergyEconomy: 0,
            communityLeadership: 0,
            safetySustainability: 0,
            policy: 0,
            strategies: 0,
            targets: 0,
            rolesResponsibilities: 0,
            trainingCompetency: 0,
            collaboration: 0,
            metering: 0,
            maintenance: 0,
            monitoring: 0,
            assetRegister: 0,
            baselineIntervalData: 0,
            emissionScopes: 0,
            energyEfficiency: 0,
            demandManagement: 0,
            energyElectrification: 0,
            netZero: 0,
            financialAccounting: 0,
            localEnergyUse: 0,
            energyMarketParticipation: 0,
            sourcing: 0,
            industryEngagement: 0,
            employeeEngagement: 0,
            safety: 0,
            design: 0,
            reporting: 0,
        };
    }

    if (type === SecScoreType.BASELINE) {
        return data.baseline;
    }
    if (type === SecScoreType.CURRENT) {
        return data.current;
    }
    return data.commitment;
}

export function getSecScoreComparison(type: SecScoreType, data?: ISecScoreGroup) {
    if (data === undefined) {
        return {
            secCompliant: false,
            scorePercent: 0,
            governance: 0,
            environment: 0,
            social: 0,
            policyStrategyTargets: 0,
            organisationalCapability: 0,
            toolsMeasurement: 0,
            baselineEnergyData: 0,
            energyResilience: 0,
            netZeroEnergy: 0,
            circularEnergyEconomy: 0,
            communityLeadership: 0,
            safetySustainability: 0,
            policy: 0,
            strategies: 0,
            targets: 0,
            rolesResponsibilities: 0,
            trainingCompetency: 0,
            collaboration: 0,
            metering: 0,
            maintenance: 0,
            monitoring: 0,
            assetRegister: 0,
            baselineIntervalData: 0,
            emissionScopes: 0,
            energyEfficiency: 0,
            demandManagement: 0,
            energyElectrification: 0,
            netZero: 0,
            financialAccounting: 0,
            localEnergyUse: 0,
            energyMarketParticipation: 0,
            sourcing: 0,
            industryEngagement: 0,
            employeeEngagement: 0,
            safety: 0,
            design: 0,
            reporting: 0,
        };
    }

    if (type === SecScoreType.BASELINE) {
        return data.baselineComparison;
    }
    if (type === SecScoreType.CURRENT) {
        return data.currentComparison;
    }
    return data.commitmentComparison;
}
