import {
    ActionType,
    ActionTypeKey,
    IUpdateElectricalAssetDataAction,
    IUpdateElectricalAssetDataLoadingAction,
} from "./actions";
import { IElectricalAssetData } from "./models";

const INITIAL_STATE: IElectricalAssetData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    siteId: 0
};

export default function electricalAssetReducer(
    state: IElectricalAssetData = INITIAL_STATE,
    action: ActionType
): IElectricalAssetData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_ELECTRICALASSET_DATA:
            return updateElectricalAssetDataAction(action);
        case ActionTypeKey.UPDATE_ELECTRICALASSET_DATA_LOADING:
            return updateElectricalAssetDataLoadingAction(action, state);
        default:
            return state;
    }
}

function updateElectricalAssetDataAction(
    action: IUpdateElectricalAssetDataAction
): IElectricalAssetData {
    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        siteId: action.payload.siteId
    };
}

function updateElectricalAssetDataLoadingAction(
    action: IUpdateElectricalAssetDataLoadingAction,
    currentState: IElectricalAssetData
): IElectricalAssetData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}
