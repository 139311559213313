import { useEffect } from "react";
import ComparisonBreakdown from "./ComparisonBreakdown";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { loadFinancialModelData } from "data/finance/financial-model/services";
import { IFinancialModelData } from "data/finance/financial-model/models";
import { IFinancialMetricData } from "data/finance/financial-metric/models";

interface IFinancialModelProps {
    financialModelData: IFinancialModelData;
}

function FinancialModel(props: IFinancialModelProps) {
    useEffect(() => {
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ComparisonBreakdown />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    financialModelData: state.financialModelData,
});

export default connect(mapStateToProps)(FinancialModel);
