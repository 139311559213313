import { Tab, Tabs } from "@mui/material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { TabPanel } from "components/shared/tab/TabPanel";
import { Link, useLocation } from "react-router-dom";
import FinancialModel from "./financial-models";
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import Billing from "../financial/billing";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

function Financial() {
    const location = useLocation();
    const path = "/" + location.pathname.split("/", 2)[1];
    const tab = location.pathname.split("/", 3)[2];

    return (
        <>
            <Tabs value={tab} textColor="secondary" indicatorColor="secondary">
                <Tab
                    icon={<BarChartOutlinedIcon />}
                    iconPosition="start"
                    label="Models"
                    value="models"
                    to={path + "/models"}
                    component={Link}
                />
                <Tab
                    icon={<AttachMoneyIcon />}
                    iconPosition="start"
                    label="Billing"
                    value="billing"
                    to={path + "/billing"}
                    component={Link}
                />
            </Tabs>

            <TabPanel value={tab} index="models">
                <FinancialModel />
            </TabPanel>
            <TabPanel value={tab} index="billing">
                <Billing />
            </TabPanel>
        </>
    );
}

export default Financial;
