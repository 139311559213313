import {
    ActionType,
    ActionTypeKey,
    IUpdateSecScoreGroupDataAction,
    IUpdateSecScoreGroupDataFilterAction,
    IUpdateSecScoreGroupDataLoadingAction,
} from "./actions";
import { ISecScoreGroupData, SecScoreType } from "./models";

const INITIAL_STATE: ISecScoreGroupData = {
    data: undefined,
    requireLoad: true,
    hasData: false,
    isLoading: false,
    accountId: 0,
    groupFilter: SecScoreType.CURRENT,
};

export default function secScoreGroupReducer(
    state: ISecScoreGroupData = INITIAL_STATE,
    action: ActionType
): ISecScoreGroupData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_SEC_SCORE_GROUP_DATA:
            return updateSecScoreGroupDataAction(action, state);
        case ActionTypeKey.UPDATE_SEC_SCORE_GROUP_DATA_LOADING:
            return updateSecScoreGroupDataLoadingAction(action, state);
        case ActionTypeKey.UPDATE_SEC_SCORE_GROUP_DATA_FILTER:
            return updateSecScoreGroupDataFilterAction(action, state);
        default:
            return state;
    }
}

function updateSecScoreGroupDataAction(
    action: IUpdateSecScoreGroupDataAction,
    currentState: ISecScoreGroupData
): ISecScoreGroupData {
    return {
        ...currentState,
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data !== undefined,
        isLoading: false,
        accountId: action.payload.accountId,
    };
}

function updateSecScoreGroupDataLoadingAction(
    action: IUpdateSecScoreGroupDataLoadingAction,
    currentState: ISecScoreGroupData
): ISecScoreGroupData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}

function updateSecScoreGroupDataFilterAction(
    action: IUpdateSecScoreGroupDataFilterAction,
    currentState: ISecScoreGroupData
): ISecScoreGroupData {
    return {
        ...currentState,
        groupFilter: action.payload.groupFilter,
    };
}
