import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
    IUpdateGlobalFilterSiteIdAction,
    updateGlobalFilterSiteId,
} from "data/global-filter/actions";
import IStoreState from "store/IStoreState";
import { IGlobalFilter } from "data/global-filter/models";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { ISite, IUserData } from "data/user/models";
import { getSitesByUser } from "data/user/helpers";

interface ISiteFilterProps {
    globalFilter: IGlobalFilter;
    userData: IUserData;

    updateGlobalFilterSiteId: (
        siteId: number
    ) => IUpdateGlobalFilterSiteIdAction;
}

function SiteFilter(props: ISiteFilterProps) {
    function onChange(event: SelectChangeEvent<number>) {
        props.updateGlobalFilterSiteId(Number(event.target.value));
    }

    return (
        <FormControl>
            <InputLabel id="global-filter-site-label">Site</InputLabel>
            <Select
                labelId="global-filter-site-select"
                id="global-filter-site"
                value={props.globalFilter.siteId}
                label="Site"
                onChange={onChange}
            >
                {getSitesByUser(props.userData.data).map((s: ISite) => {
                    return (
                        <MenuItem key={s.id} value={s.id}>
                            {s.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
    userData: state.userData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateGlobalFilterSiteId: bindActionCreators(
        updateGlobalFilterSiteId,
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteFilter);
