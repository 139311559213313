import { ILightingAsset } from "./models";

export enum ActionTypeKey {
    UPDATE_LIGHTINGASSET_DATA = "UPDATE_LIGHTINGASSET_DATA",
    UPDATE_LIGHTINGASSET_DATA_LOADING = "UPDATE_LIGHTINGASSET_DATA_LOADING",
}

export type ActionType =
    | IUpdateLightingAssetDataAction
    | IUpdateLightingAssetDataLoadingAction;

export interface IUpdateLightingAssetDataAction {
    type: ActionTypeKey.UPDATE_LIGHTINGASSET_DATA;
    payload: {
        siteId: number;
        data: ILightingAsset[];
    };
}

export interface IUpdateLightingAssetDataLoadingAction {
    type: ActionTypeKey.UPDATE_LIGHTINGASSET_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateLightingAssetData(
    siteId: number,
    data: ILightingAsset[]
): IUpdateLightingAssetDataAction {
    return {
        type: ActionTypeKey.UPDATE_LIGHTINGASSET_DATA,
        payload: {
            siteId: siteId,
            data: data,
        },
    };
}

export function updateLightingAssetDataLoading(
    isLoading: boolean
): IUpdateLightingAssetDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_LIGHTINGASSET_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
