import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Stack,
    SxProps,
} from "@mui/material";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { IEnergyPointData } from "data/energy/energy-point/models";
import ParkIcon from "@mui/icons-material/Park";
import PeopleIcon from "@mui/icons-material/People";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

interface IMetricsProps {
    energyPointData: IEnergyPointData;
}

function Metrics(props: IMetricsProps) {
    function createMetric(
        isLoading: boolean,
        metric: string,
        value: string,
        text: string,
        text2: string
    ) {
        let color = "var(--max-color-primary)";

        if (metric === "Energy Resilience") {
            color = "var(--max-color-accent)";
        }

        if (metric === "Circular Energy Economy") {
            color = "var(--max-color-grey)";
        }

        return (
            <div
                style={{
                    width: "100%",
                    marginBottom: "var(--max-length-medium1)",
                }}
            >
                <div
                    style={{
                        fontSize: "var(--max-font-size-title)",
                        // fontWeight: "bold",
                        color: "var(--max-color-title)",
                        marginBottom: "var(--max-length-medium1)",
                    }}
                >
                    {metric}
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            height: "4rem",
                        }}
                    >
                        <div
                            style={{
                                marginRight: "var(--max-length-medium3)",
                                height: "100%",
                                aspectRatio: "1",
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    border: `0.5rem ${color} solid`,
                                    borderRadius: "50%",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                {createIcon(metric)}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            // position: "relative",
                            // minWidth: "10rem",
                        }}
                    >
                        <div
                            style={
                                {
                                    // position: "absolute",
                                    // top: "50%",
                                    // transform: "translate(0, -50%)",
                                }
                            }
                        >
                            {isLoading ? (
                                <Box
                                    sx={{
                                        height: "9rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CircularProgress color="primary" />
                                </Box>
                            ) : (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <div
                                            style={{
                                                fontSize:
                                                    "var(--max-font-size-title)",
                                                color: "var(--max-color-title)",
                                            }}
                                        >
                                            {value}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginLeft:
                                                    "var(--max-length-small3)",
                                                color: "var(--max-color-title)",
                                            }}
                                        >
                                            {text}
                                        </div>
                                    </div>
                                    <div>{text2}</div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function createIcon(metric: string) {
        let sx: SxProps = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        };

        if (metric === "Real Net Zero Energy") {
            return <AutorenewIcon color="primary" sx={sx} fontSize="large" />;
        } else if (metric === "Energy Resilience") {
            return (
                <AttachMoneyIcon color="secondary" sx={sx} fontSize="large" />
            );
        } else if (metric === "Circular Energy Economy") {
            return <PeopleIcon color="info" sx={sx} fontSize="large" />;
        } else if (metric === "Emission Reduction") {
            return <ParkIcon color="primary" sx={sx} fontSize="large" />;
        }
    }

    return (
        <Card sx={{ height: "100%" }}>
            {/* <CardHeader title="Key Performance Indicators" /> */}
            <CardContent>
                <Stack direction="row" spacing={4}>
                    {createMetric(
                        false,
                        "Real Net Zero Energy",
                        "73.91 %",
                        "matched renewables",
                        "This is a 39.91% improvement from last year"
                    )}
                    {createMetric(
                        false,
                        "Energy Resilience",
                        "$3,500",
                        "average monthly spend",
                        "Your plan has saved you $649 per month this financial year"
                    )}
                    {createMetric(
                        false,
                        "Circular Energy Economy",
                        "10",
                        "parties involved",
                        "Your virtual energy community is supporting 6 more people than this time last year"
                    )}
                    {createMetric(
                        false,
                        "Emission Reduction",
                        "30.1 t",
                        "emissions saved",
                        "This is the equivalent of 533 trees"
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    energyPointData: state.energyPointData,
});

export default connect(mapStateToProps)(Metrics);
