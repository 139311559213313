import { Auth } from "aws-amplify";
import axios, { AxiosInstance } from "axios";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const ApiService: AxiosInstance = axios.create({
    baseURL: `${baseURL}`,
    headers: {
        "Content-type": "application/x-www-form-urlencoded"
    },
});

ApiService.interceptors.request.use(
    async (config) => {
        const session = await Auth.currentSession();
        const token = session.getAccessToken().getJwtToken();

        config.headers = {
            Authorization: `Bearer ${token}`,
        };

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export default ApiService;
