import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import SiteFilter from "components/shared/global-filter/SiteFilter";
import { Grid, Stack } from "@mui/material";
import Summary from "./Summary";
import { useEffect } from "react";
import { loadEnergyPoints } from "data/energy/energy-point/services";
import { PointAggregation } from "data/energy/PointAggregation";
import MatchedRenewables from "./MatchedRenewables";
import { loadEnergyLoadPoints } from "data/energy/energy-load-point/services";
import SubMeter from "./SubMeter";
import YearFilter from "components/shared/global-filter/YearFilter";
import { IGlobalFilter } from "data/global-filter/models";
import ElectricalCapacity from "./ElectricalCapacity";

interface IEnergyProps {
    globalFilter: IGlobalFilter;
}

function Energy(props: IEnergyProps) {
    useEffect(() => {
        loadEnergyPoints(
            true,
            PointAggregation.MONTH,
            props.globalFilter.siteId,
            new Date(props.globalFilter.yearStart),
            new Date(props.globalFilter.yearEnd)
        );

        loadEnergyLoadPoints(
            true,
            PointAggregation.YEAR,
            props.globalFilter.siteId,
            new Date(props.globalFilter.yearStart),
            new Date(props.globalFilter.yearEnd)
        );
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                    <SiteFilter />
                    <YearFilter />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Summary />
            </Grid>
            <Grid item xs={12}>
                <div className="sectionBreak" />
                <div className="sectionTitle">Electricity</div>
            </Grid>
            <Grid item xs={12} xl={6}>
                <Stack direction="column" spacing={2}>
                    <MatchedRenewables />
                    <ElectricalCapacity />
                </Stack>
            </Grid>
            <Grid item xs={12} xl={6}>
                <SubMeter />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
});

export default connect(mapStateToProps)(Energy);
