import qs from "qs";
import ApiService from "services/ApiService";
import { IFinancialMetric } from "./models";
import { store } from "store/store";
import {
    updateFinancialMetricData,
    updateFinancialMetricDataLoading,
} from "./actions";

export function loadFinancialMetricData(useCache: boolean, accountId: number) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const financialMetricData = store.getState().financialMetricData;

    if (
        !financialMetricData.isLoading &&
        (!useCache ||
            financialMetricData.requireLoad ||
            financialMetricData.accountId !== accountId)
    ) {
        store.dispatch(updateFinancialMetricDataLoading(true));

        ApiService.get(`/finance/GetFinancialMetric`, {
            params: {
                accountId: accountId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                console.log(res);
                let data: IFinancialMetric = {
                    npv: res.data.npv,
                    irr: res.data.irr,
                    carbonAbatementProjects: res.data.carbonAbatementProjects,
                    capitalInvestment: res.data.capitalInvestment,
                };

                console.log(data);
                store.dispatch(updateFinancialMetricData(accountId, data));
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(updateFinancialMetricDataLoading(false));
            });
    }
}
