import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import SiteFilter from "components/shared/global-filter/SiteFilter";
import { Grid, Stack } from "@mui/material";
import { useEffect } from "react";
import { loadEnergyPoints } from "data/energy/energy-point/services";
import { PointAggregation } from "data/energy/PointAggregation";
import YearFilter from "components/shared/global-filter/YearFilter";
import { IGlobalFilter } from "data/global-filter/models";
import EmissionSources from "./EmissionSources";
import Metrics from "./Metrics";
import Comparison from "./Comparison";

interface INetCarbonProps {
    globalFilter: IGlobalFilter;
}

function NetCarbon(props: INetCarbonProps) {
    useEffect(() => {
        loadEnergyPoints(
            true,
            PointAggregation.MONTH,
            props.globalFilter.siteId,
            new Date(props.globalFilter.yearStart),
            new Date(props.globalFilter.yearEnd)
        );
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                    <SiteFilter />
                    <YearFilter />
                </Stack>
            </Grid>
            <Grid item xs={12} xl={6}>
                <Metrics />
            </Grid>
            <Grid item xs={12} xl={6}>
                <EmissionSources />
            </Grid>
            <Grid item xs={12}>
                <Comparison />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
});

export default connect(mapStateToProps)(NetCarbon);
