import { styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

export const NoHoverDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.columnHeader}`]: {
        backgroundColor: "var(--max-color-table-header)",
    },
    [`& .${gridClasses.row}`]: {
        "&:hover, &.Mui-hovered": {
            backgroundColor: "white",
        },
        "&.Mui-selected": {
            backgroundColor: "white",
            border: "white",
            "&:hover, &.Mui-hovered": {
                backgroundColor: "white",
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: "white",
                },
            },
        },
    },
}));
