import qs from "qs";
import ApiService from "services/ApiService";
import { IProject } from "./models";
import { store } from "store/store";
import { updateProjectData, updateProjectDataLoading } from "./actions";

export function loadProjectData(useCache: boolean, accountId: number) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const projectData = store.getState().projectData;

    if (
        !projectData.isLoading &&
        (!useCache ||
            projectData.requireLoad ||
            projectData.accountId !== accountId)
    ) {
        store.dispatch(updateProjectDataLoading(true));

        ApiService.get(`/projects`, {
            params: {
                accountId: accountId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = res.data.map(
                    (x: any) =>
                        ({
                            name: x.name,
                            code: x.code,
                            category: x.category,
                            budget: x.budget,
                            ratedValue: x.ratedValue,
                            ratedComplexity: x.ratedComplexity,
                            status: x.status,
                            startDate: x.startDate,
                            endDate: x.endDate,
                            estimatedEndDate: x.estimatedEndDate,
                            accountId: x.accountId,
                            type: x.type,
                            percentCompleted: x.percentCompleted,
                            stage: x.stage,
                            esgImpact: x.esgImpact,
                            lead: x.lead,
                            notes: x.notes,
                            budgetString: x.budgetString,
                            dateString: x.dateString,
                            priorityString: x.priorityString,
                            esgE: x.esgE,
                            esgS: x.esgS,
                            esgG: x.esgG,
                            categories: x.categories
                        } as IProject)
                );

                store.dispatch(updateProjectData(accountId, data));
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(updateProjectDataLoading(false));
            });
    }
}
