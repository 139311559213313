import { Card, CardContent, Stack } from "@mui/material";
import { EsgChartComponent } from "./EsgChartComponent";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { ISecScoreGroupData } from "data/sec/secScore/models";
import { getSecScore, getSecScoreComparison } from "data/sec/secScore/services";
import { InfoLabel } from "components/shared/typography/InfoLabel";

export interface IScoreSummaryProps {
    secScoreGroupData: ISecScoreGroupData;
}

function EsgChart(props: IScoreSummaryProps) {
    // const data = () => {
    //     let source = getSecScore(
    //         props.secScoreGroupData.groupFilter,
    //         props.secScoreGroupData.data
    //     );
    //     let comparison = getSecScoreComparison(
    //         props.secScoreGroupData.groupFilter,
    //         props.secScoreGroupData.data
    //     );

    //     return [
    //         source.circularEnergyEconomy,
    //         comparison.circularEnergyEconomy,
    //         source.communityLeadership,
    //         comparison.communityLeadership,
    //         source.safetySustainability,
    //         comparison.safetySustainability,

    //         source.policyStrategyTargets,
    //         comparison.policyStrategyTargets,
    //         source.organisationalCapability,
    //         comparison.organisationalCapability,
    //         source.toolsMeasurement,
    //         comparison.toolsMeasurement,

    //         source.baselineEnergyData,
    //         comparison.baselineEnergyData,
    //         source.energyResilience,
    //         comparison.energyResilience,
    //         source.netZeroEnergy,
    //         comparison.netZeroEnergy,
    //     ];
    // };

    const data = () => {
        let source = getSecScore(
            props.secScoreGroupData.groupFilter,
            props.secScoreGroupData.data
        );

        return [
            source.circularEnergyEconomy,
            source.communityLeadership,
            source.safetySustainability,

            source.policyStrategyTargets,
            source.organisationalCapability,
            source.toolsMeasurement,

            source.baselineEnergyData,
            source.energyResilience,
            source.netZeroEnergy,
        ];
    };

    const data2 = () => {
        let comparison = getSecScoreComparison(
            props.secScoreGroupData.groupFilter,
            props.secScoreGroupData.data
        );

        return [
            comparison.circularEnergyEconomy,
            comparison.communityLeadership,
            comparison.safetySustainability,

            comparison.policyStrategyTargets,
            comparison.organisationalCapability,
            comparison.toolsMeasurement,

            comparison.baselineEnergyData,
            comparison.energyResilience,
            comparison.netZeroEnergy,
        ];
    };

    const score = () => {
        return getSecScore(
            props.secScoreGroupData.groupFilter,
            props.secScoreGroupData.data
        ).scorePercent;
    };

    const isSecValid = () => {
        return getSecScore(
            props.secScoreGroupData.groupFilter,
            props.secScoreGroupData.data
        ).secCompliant;
    };

    function createChart() {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <EsgChartComponent
                    score={score()}
                    data={data()}
                    data2={data()}
                    isSecValid={isSecValid()}
                />
            </div>
        );
    }

    return (
        <Card style={{ height: "100%" }}>
            <CardContent>
                <Stack direction="column" spacing={4}>
                    {createChart()}
                    <InfoLabel label="Toggle scores between baseline, current, and commitment using control on the bottom right" />
                </Stack>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    secScoreGroupData: state.secScoreGroupData,
});

export default connect(mapStateToProps)(EsgChart);
