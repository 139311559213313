import { IFinancialModel } from "./models";

export enum ActionTypeKey {
    UPDATE_FINANCIAL_MODEL_DATA = "UPDATE_FINANCIAL_MODEL_DATA",
    UPDATE_FINANCIAL_MODEL_DATA_LOADING = "UPDATE_FINANCIAL_MODEL_DATA_LOADING",
}

export type ActionType =
    | IUpdateFinancialModelDataAction
    | IUpdateFinancialModelDataLoadingAction;

export interface IUpdateFinancialModelDataAction {
    type: ActionTypeKey.UPDATE_FINANCIAL_MODEL_DATA;
    payload: {
        accountId: number;
        growthPercent: number;
        greenPowerPricing: number;
        offsetPricing: number;
        data: IFinancialModel[];
    };
}

export interface IUpdateFinancialModelDataLoadingAction {
    type: ActionTypeKey.UPDATE_FINANCIAL_MODEL_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateFinancialModelData(
    accountId: number,
    growthPercent: number,
    greenPowerPricing: number,
    offsetPricing: number,
    data: IFinancialModel[]
): IUpdateFinancialModelDataAction {
    return {
        type: ActionTypeKey.UPDATE_FINANCIAL_MODEL_DATA,
        payload: {
            accountId: accountId,
            growthPercent: growthPercent,
            greenPowerPricing: greenPowerPricing,
            offsetPricing: offsetPricing,
            data: data,
        },
    };
}

export function updateFinancialModelDataLoading(
    isLoading: boolean
): IUpdateFinancialModelDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_FINANCIAL_MODEL_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
