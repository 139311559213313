import ApiService from "services/ApiService";
import { IUser, IAccount, ISite } from "./models";
import { store } from "store/store";
import { updateUserData, updateUserDataLoading } from "./actions";
import { updateGlobalFilterAccount, updateGlobalFilterSiteId } from "data/global-filter/actions";
import { getFirstAccountByUser, getFirstSiteByUser } from "./helpers";

export function loadUserData(useCache: boolean) {
    const userData = store.getState().userData;

    if (!userData.isLoading && (!useCache || userData.requireLoad)) {
        store.dispatch(updateUserDataLoading(true));

        ApiService.get(`/user/GetUser`)
            .then((res) => {
                let data: IUser = {
                    accounts: res.data.accounts.map(
                        (acc: any) =>
                            ({
                                id: acc.id,
                                name: acc.name,
                                sites: acc.sites.map(
                                    (s: any) =>
                                        ({
                                            id: s.id,
                                            name: s.name,
                                        } as ISite)
                                ),
                            } as IAccount)
                    ),
                };

                store.dispatch(
                    updateGlobalFilterAccount(getFirstAccountByUser(data))
                );

                store.dispatch(
                    updateGlobalFilterSiteId(getFirstSiteByUser(data).id)
                );

                store.dispatch(updateUserData(data));
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(updateUserDataLoading(false));
            });
    }
}
