import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    Box,
    Divider,
} from "@mui/material";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import LogoutIcon from "@mui/icons-material/Logout";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Link, useLocation } from "react-router-dom";
import "./NavMain.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ElectricMeterOutlined } from "@mui/icons-material";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import logo from "assets/image/logo.png";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { routeNames } from "components/router/RouteNames";

const drawerWidth = "var(--max-length-drawerwidth)";
const iconSize = "var(--max-font-size-nav-icon)";

export default function NavMain() {
    const { signOut } = useAuthenticator((context) => [context.user]);

    const { pathname } = useLocation();

    function IsActivePath(target: string) {
        return pathname.split("/")[1] === target;
    }

    const iconStyle = {
        minWidth: 0,
        mr: 2,
        justifyContent: "center",
        paddingLeft: "var(--max-length-small1)",
    };

    const itemStyle = {
        minHeight: 48,
        justifyContent: "initial",
        px: 2.5,
    };

    function NavItem(to: string) {
        let isActive = IsActivePath(to);

        let navItemStyle = {
            itemStyle,
            background: isActive ? "var(--max-color-nav-selected)" : "",
            borderLeft: isActive
                ? "var(--max-length-small1) solid var(--max-color-accent)"
                : "",
            borderRadius: "var(--max-length-button-radius)",
        };

        let thisIconStyle = {
            ...iconStyle,
            color: isActive ? "var(--max-color-accent)" : "",
            paddingLeft: isActive ? "0" : "var(--max-length-small1)",
        };

        return (
            <ListItem key={to} component={Link} to={to} sx={navItemStyle}>
                <ListItemIcon sx={thisIconStyle}>{GetNavIcon(to)}</ListItemIcon>
                <ListItemText primary={routeNames[to]} />
            </ListItem>
        );
    }

    function NavLink(to: string, title: string, url: string) {
        return (
            <ListItem key={to} component="a" href={url} target="_blank">
                <ListItemIcon sx={iconStyle}>{GetNavIcon(to)}</ListItemIcon>
                <ListItemText primary={title} />
            </ListItem>
        );
    }

    function GetNavIcon(to: string) {
        switch (to) {
            case "dashboard":
                return <DashboardOutlinedIcon sx={{ fontSize: iconSize }} />;

            case "yourcommitment":
                return <CheckCircleOutlineIcon sx={{ fontSize: iconSize }} />;

            case "energybreakdown":
                return <DonutLargeIcon sx={{ fontSize: iconSize }} />;

            case "assetregister":
                return <ElectricMeterOutlined sx={{ fontSize: iconSize }} />;

            case "assetperformance":
                return <MultilineChartIcon sx={{ fontSize: iconSize }} />;

            case "projectmanagement":
                return (
                    <AssignmentTurnedInOutlinedIcon
                        sx={{ fontSize: iconSize }}
                    />
                );

            case "financial":
                return <PaidOutlinedIcon sx={{ fontSize: iconSize }} />;

            case "marketplace":
                return <StorefrontIcon sx={{ fontSize: iconSize }} />;

            case "communication":
                return (
                    <ChatBubbleOutlineOutlinedIcon
                        sx={{ fontSize: iconSize }}
                    />
                );

            default:
                return <DashboardOutlinedIcon sx={{ fontSize: iconSize }} />;
        }
    }

    return (
        <Drawer
            className="navMainDrawer"
            variant="permanent"
            sx={{
                color: "var(--max-color-text)",
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                },
                [`& .Mui-selected`]: {
                    backgroundColor: "var(--max-color-nav-selected)",
                    borderRadius: "4px",
                },
            }}
            PaperProps={{
                sx: {
                    backgroundColor: "var(--max-color-background)",
                    border: "0",
                },
            }}
        >
            <Box
                style={{
                    width: "100%",
                    // height: "6rem",
                    // background: "white"
                    // background:
                    //     "linear-gradient(white, var(--max-color-background))",
                }}
            >
                <img
                    className="headerLogoImage"
                    src={logo}
                    alt="Maximum Energy"
                    style={{
                        width: "inherit",
                        padding: "var(--max-length-medium1)",
                    }}
                />
            </Box>

            <Divider variant="middle" />

            <div style={{ marginLeft: "var(--max-length-small1)" }}>
                <List className="navMainList">
                    {NavItem("dashboard")}
                    {NavItem("yourcommitment")}
                    {NavItem("energybreakdown")}
                    {NavItem("assetregister")}
                    {NavItem("assetperformance")}
                    {NavItem("projectmanagement")}
                    {NavItem("financial")}
                    {/* {NavItem("marketplace")} */}
                    {NavLink(
                        "communication",
                        "Communications",
                        "https://maximumenergy.sharepoint.com/:f:/s/maximum/EttzRmg4suhFnSQOnOppvNUBaSU50mJI-CL_h9n8ZnFR4Q?e=5RDnHR"
                    )}
                </List>

                <Divider variant="middle" />

                <List className="navMainList">
                    <ListItem
                        key="navLogout"
                        component={Link}
                        to="/"
                        onClick={signOut}
                        sx={itemStyle}
                    >
                        <ListItemIcon sx={iconStyle}>
                            <LogoutIcon sx={{ fontSize: iconSize }} />
                        </ListItemIcon>
                        <ListItemText primary="Sign out" />
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
}
