import {
    Card,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import MoodOutlinedIcon from "@mui/icons-material/MoodOutlined";

export default function Notification() {
    return (
        <Card>
            <List sx={{ margin: 0, padding: 0 }}>
                <ListItem
                    sx={{
                        fontSize: "var(--max-font-size-title)",
                        color: "var(--max-color-title)",
                        // fontWeight: "bold",
                        // border: "1px red solid",
                    }}
                >
                    <ListItemIcon>
                        <MoodOutlinedIcon
                            color="primary"
                            sx={{
                                fontSize: "var(--max-font-size-nav-icon-large)",
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Congratulations, you are on schedule to achieve your commitment"
                        disableTypography={true}
                    />
                </ListItem>
            </List>
        </Card>
    );
}
