export enum SecScoreType {
    BASELINE = 1,
    CURRENT = 2,
    COMMITMENT = 3
}

export interface ISecScoreGroupData {
    data?: ISecScoreGroup;
    requireLoad: boolean;
    hasData: boolean;
    isLoading: boolean;
    accountId: number;
    groupFilter: SecScoreType;
}

export interface ISecScoreGroup {
    baseline: ISecScore;
    current: ISecScore;
    commitment: ISecScore;
    baselineComparison: ISecScore;
    currentComparison: ISecScore;
    commitmentComparison: ISecScore;
}

export interface ISecScore {
    secCompliant: boolean;
    scorePercent: number;
    governance: number;
    environment: number;
    social: number;
    policyStrategyTargets: number;
    organisationalCapability: number;
    toolsMeasurement: number;
    baselineEnergyData: number;
    energyResilience: number;
    netZeroEnergy: number;
    circularEnergyEconomy: number;
    communityLeadership: number;
    safetySustainability: number;
    policy: number;
    strategies: number;
    targets: number;
    rolesResponsibilities: number;
    trainingCompetency: number;
    collaboration: number;
    metering: number;
    maintenance: number;
    monitoring: number;
    assetRegister: number;
    baselineIntervalData: number;
    emissionScopes: number;
    energyEfficiency: number;
    demandManagement: number;
    energyElectrification: number;
    netZero: number;
    financialAccounting: number;
    localEnergyUse: number;
    energyMarketParticipation: number;
    sourcing: number;
    industryEngagement: number;
    employeeEngagement: number;
    safety: number;
    design: number;
    reporting: number;
}
