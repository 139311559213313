import { connect } from "react-redux";
import { IProject, IProjectData } from "data/projects/project/models";
import IStoreState from "store/IStoreState";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from "@mui/material";
import { useState, useEffect } from "react";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { renderCellWrapped } from "components/shared/data-grid/CellWrapped";
import { TrueOnlyButton } from "components/shared/icons/TrueFalseIcon";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";
import { IGlobalFilter } from "data/global-filter/models";

interface IProjectsProps {
    globalFilter: IGlobalFilter;
    projectData: IProjectData;
}

function Projects(props: IProjectsProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const [stageFilterOptionLabels] = useState([
        "All",
        "Stage 1",
        "Stage 2",
        "Stage 3",
    ]);
    const [stageFilterOptions] = useState(["All", "1", "2", "3"]);
    const [statusFilterOptions] = useState([
        "All",
        "Planned",
        "In Progress",
        "Completed",
    ]);
    const [stageFilterValue, setStageFilterValue] = useState(0);
    const [statusFilterValue, setStatusFilterValue] = useState(0);

    const projectColumns: GridColDef[] = [
        {
            field: "stage",
            headerName: "Stage",
            minWidth: 75,
            flex: 0.75,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
        },
        {
            field: "project",
            headerName: "Project",
            minWidth: 100,
            flex: 3,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWrapped,
        },
        {
            field: "id",
            headerName: "Code",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "esgE",
            headerName: "E",
            minWidth: 50,
            flex: 0.5,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
        {
            field: "esgS",
            headerName: "S",
            minWidth: 50,
            flex: 0.5,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
        {
            field: "esgG",
            headerName: "G",
            minWidth: 50,
            flex: 0.5,
            headerAlign: "center",
            align: "center",
            renderHeader: renderColumnHeader,
            renderCell: (params: GridRenderCellParams) => {
                return <TrueOnlyButton true={params.value} />;
            },
        },
        {
            field: "budget",
            headerName: "Budget",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "priority",
            headerName: "Priority",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "date",
            headerName: "Date",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
    ];

    function getProjectRows() {
        if (!props.projectData.hasData) {
            return [];
        }

        let filteredData = props.projectData.data;

        if (stageFilterOptions[stageFilterValue] !== "All") {
            filteredData = filteredData.filter(
                (x) => x.stage === stageFilterOptions[stageFilterValue]
            );
        }

        if (statusFilterOptions[statusFilterValue] !== "All") {
            filteredData = filteredData.filter(
                (x) => x.status === statusFilterOptions[statusFilterValue]
            );
        }

        let rows = filteredData.map((x: IProject) => ({
            stage: x.stage,
            project: x.name,
            id: x.code,
            category: x.category,
            esgE: x.esgE,
            esgS: x.esgS,
            esgG: x.esgG,
            budget: x.budgetString,
            priority: x.priorityString,
            date: x.dateString,
            status: x.status,
        }));

        return rows;
    }

    const [pageSize, setPageSize] = useState(50);

    const projectGrid = (
        <Box
            sx={{
                width: "100%",
                "& .project-theme--completed": {
                    backgroundColor: "var(--max-color-table-highlight-primary)",
                },
                "& .project-theme--inprogress": {
                    backgroundColor: "var(--max-color-table-highlight-accent)",
                },
            }}
        >
            <StyledDataGrid
                key={dataGridKey}
                rows={getProjectRows()}
                columns={projectColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                checkboxSelection={false}
                hideFooterSelectedRowCount={true}
                density="compact"
                autoHeight={true}
                loading={props.projectData.isLoading}
                getRowHeight={() => "auto"}
                getRowClassName={(params) =>
                    `project-theme--${params.row.status
                        .toLowerCase()
                        .replace(/\s/g, "")}`
                }
            />
        </Box>
    );

    const stageFilter = (
        <FormControl>
            <InputLabel id="filter-stage-label">Stage</InputLabel>
            <Select
                labelId="filter-stage-select"
                id="filter-stage"
                value={stageFilterValue}
                label="Stage"
                onChange={(event) => {
                    setStageFilterValue(Number(event.target.value));
                    setDataGridKey(dataGridKey + 1);
                }}
            >
                {stageFilterOptionLabels.map(
                    (option: string, index: number) => {
                        return (
                            <MenuItem key={index} value={index}>
                                {option}
                            </MenuItem>
                        );
                    }
                )}
            </Select>
        </FormControl>
    );

    const statusFilter = (
        <FormControl>
            <InputLabel id="filter-status-label">Status</InputLabel>
            <Select
                labelId="filter-status-select"
                id="filter-status"
                value={statusFilterValue}
                label="Status"
                onChange={(event) => {
                    setStatusFilterValue(Number(event.target.value));
                    setDataGridKey(dataGridKey + 1);
                }}
            >
                {statusFilterOptions.map((option: string, index: number) => {
                    return (
                        <MenuItem key={index} value={index}>
                            {option}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );

    const filters = (
        <Stack direction="row" spacing={2}>
            {stageFilter}
            {statusFilter}
        </Stack>
    );

    return (
        <Card>
            {/* <CardHeader title="Projects" /> */}
            <CardContent>
                <Stack direction="column" spacing={2}>
                    {filters}
                    {projectGrid}
                </Stack>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
    projectData: state.projectData,
});

export default connect(mapStateToProps)(Projects);
