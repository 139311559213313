import qs from "qs";
import ApiService from "services/ApiService";
import { IFinancialModel } from "./models";
import { store } from "store/store";
import {
    updateFinancialModelData,
    updateFinancialModelDataLoading,
} from "./actions";

export function loadFinancialModelData(
    useCache: boolean,
    accountId: number,
    growthPercent: number,
    greenPowerPricing: number,
    offsetPricing: number
) {
    if (store.getState().globalFilter.requireLoad) {
        return;
    }

    const financialModelData = store.getState().financialModelData;

    if (
        !financialModelData.isLoading &&
        (!useCache ||
            financialModelData.requireLoad ||
            financialModelData.accountId !== accountId ||
            financialModelData.growthPercent !== growthPercent ||
            financialModelData.greenPowerPricing !== greenPowerPricing ||
            financialModelData.offsetPricing !== offsetPricing)
    ) {
        store.dispatch(updateFinancialModelDataLoading(true));

        ApiService.get(`/finance/GetFinancialModel`, {
            params: {
                accountId: accountId,
                growthPercent: growthPercent,
                greenPowerPricing: greenPowerPricing,
                offsetPricing: offsetPricing,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then((res) => {
                let data = res.data.map(
                    (x: any) =>
                        ({
                            year: x.year,
                            greenPowerCashflow: x.greenPowerCashflow,
                            secCashflow: x.secCashflow,
                        } as IFinancialModel)
                );

                console.log(data);
                store.dispatch(
                    updateFinancialModelData(
                        accountId,
                        growthPercent,
                        greenPowerPricing,
                        offsetPricing,
                        data
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(updateFinancialModelDataLoading(false));
            });
    }
}
