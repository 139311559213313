import {
    ActionType,
    ActionTypeKey,
    IUpdateDiselInvoiceDataAction,
    IUpdateDiselInvoiceDataLoadingAction,
    IUpdateGasInvoiceDataAction,
    IUpdateGasInvoiceDataLoadingAction,
    IUpdateInvoiceDataAction,
    IUpdateInvoiceDataLoadingAction,
} from "./actions";
import { IDiselInvoiceData, IGasInvoiceData, IInvoiceData } from "./models";

const INITIAL_STATE: IInvoiceData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    accountId: 0,
};

export default function invoiceReducer(
    state: IInvoiceData = INITIAL_STATE,
    action: ActionType
): IInvoiceData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_INVOICE_DATA:
            return updateInvoiceDataAction(action);
        case ActionTypeKey.UPDATE_INVOICE_DATA_LOADING:
            return updateInvoiceDataLoadingAction(action, state);
        default:
            return state;
    }
}

function updateInvoiceDataAction(
    action: IUpdateInvoiceDataAction
): IInvoiceData {
    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        accountId: action.payload.accountId,
    };
}

function updateInvoiceDataLoadingAction(
    action: IUpdateInvoiceDataLoadingAction,
    currentState: IInvoiceData
): IInvoiceData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}



const GAS_INITIAL_STATE: IGasInvoiceData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    accountId: 0,
};

export function gasInvoiceReducer(
    state: IGasInvoiceData = GAS_INITIAL_STATE,
    action: ActionType
): IGasInvoiceData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_GAS_INVOICE_DATA:
            return updateGasInvoiceDataAction(action);
        case ActionTypeKey.UPDATE_GAS_INVOICE_DATA_LOADING:
            return updateGasInvoiceDataLoadingAction(action, state);
        default:
            return state;
    }
}

function updateGasInvoiceDataAction(
    action: IUpdateGasInvoiceDataAction
): IGasInvoiceData {
    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        accountId: action.payload.accountId,
    };
}

function updateGasInvoiceDataLoadingAction(
    action: IUpdateGasInvoiceDataLoadingAction,
    currentState: IGasInvoiceData
): IGasInvoiceData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}



const DISEL_INITIAL_STATE: IDiselInvoiceData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    accountId: 0,
};

export function diselInvoiceReducer(
    state: IDiselInvoiceData = DISEL_INITIAL_STATE,
    action: ActionType
): IDiselInvoiceData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_DISEL_INVOICE_DATA:
            return updateDiselInvoiceDataAction(action);
        case ActionTypeKey.UPDATE_DISEL_INVOICE_DATA_LOADING:
            return updateDiselInvoiceDataLoadingAction(action, state);
        default:
            return state;
    }
}

function updateDiselInvoiceDataAction(
    action: IUpdateDiselInvoiceDataAction
): IDiselInvoiceData {
    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        accountId: action.payload.accountId,
    };
}

function updateDiselInvoiceDataLoadingAction(
    action: IUpdateDiselInvoiceDataLoadingAction,
    currentState: IDiselInvoiceData
): IDiselInvoiceData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}
