import { PointAggregation } from "../PointAggregation";
import { IEnergyLoadPoint } from "./models";

export enum ActionTypeKey {
    UPDATE_ENERGY_LOAD_POINT_DATA = "UPDATE_ENERGY_LOAD_POINT_DATA",
    UPDATE_ENERGY_LOAD_POINT_DATA_LOADING = "UPDATE_ENERGY_LOAD_POINT_DATA_LOADING",
}

export type ActionType =
    | IUpdateEnergyLoadPointDataAction
    | IUpdateEnergyLoadPointDataLoadingAction;

export interface IUpdateEnergyLoadPointDataAction {
    type: ActionTypeKey.UPDATE_ENERGY_LOAD_POINT_DATA;
    payload: {
        pointAggregation: PointAggregation;
        fromDate: string;
        toDate: string;
        data: IEnergyLoadPoint[];
    };
}

export interface IUpdateEnergyLoadPointDataLoadingAction {
    type: ActionTypeKey.UPDATE_ENERGY_LOAD_POINT_DATA_LOADING;
    payload: {
        isLoading: boolean;
    };
}

export function updateEnergyLoadPointData(
    pointAggregation: PointAggregation,
    fromDate: string,
    toDate: string,
    data: IEnergyLoadPoint[]
): IUpdateEnergyLoadPointDataAction {
    return {
        type: ActionTypeKey.UPDATE_ENERGY_LOAD_POINT_DATA,
        payload: {
            pointAggregation: pointAggregation,
            fromDate: fromDate,
            toDate: toDate,
            data: data,
        },
    };
}

export function updateEnergyLoadPointDataLoading(
    isLoading: boolean
): IUpdateEnergyLoadPointDataLoadingAction {
    return {
        type: ActionTypeKey.UPDATE_ENERGY_LOAD_POINT_DATA_LOADING,
        payload: {
            isLoading: isLoading,
        },
    };
}
