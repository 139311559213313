import { GridRenderCellParams } from "@mui/x-data-grid";

export function renderCellChartBarPrimary(
    params: GridRenderCellParams<string>
) {
    return <ChartBar value={Number(params.value)} isPrimary={true} />;
}

export function renderCellChartBarAccent(params: GridRenderCellParams<string>) {
    return <ChartBar value={Number(params.value)} isPrimary={false} />;
}

export function renderCellChartBarPrimaryWithTextDp0(
    params: GridRenderCellParams<string>
) {
    return (
        <div style={{ width: "100%", display: "flex" }}>
            <Text value={Number(params.value)} decimalPlaces={0} />
            <ChartBar value={Number(params.value)} isPrimary={true} />
        </div>
    );
}

export function renderCellChartBarAccentWithTextDp0(
    params: GridRenderCellParams<string>
) {
    return (
        <div style={{ width: "100%", display: "flex" }}>
            <Text value={Number(params.value)} decimalPlaces={0} />
            <ChartBar value={Number(params.value)} isPrimary={false} />
        </div>
    );
}

export function renderCellChartBarPrimaryWithTextDp2(
    params: GridRenderCellParams<string>
) {
    return (
        <div style={{ width: "100%", display: "flex" }}>
            <Text value={Number(params.value)} decimalPlaces={2} />
            <ChartBar value={Number(params.value)} isPrimary={true} />
        </div>
    );
}

export function renderCellChartBarAccentWithTextDp2(
    params: GridRenderCellParams<string>
) {
    return (
        <div style={{ width: "100%", display: "flex" }}>
            <Text value={Number(params.value)} decimalPlaces={2} />
            <ChartBar value={Number(params.value)} isPrimary={false} />
        </div>
    );
}

interface ITextProps {
    value: number;
    decimalPlaces: number;
}

function Text(props: ITextProps) {
    return (
        <div style={{ width: "50%", marginRight: 20, textAlign: "right" }}>
            {props.value.toFixed(props.decimalPlaces)}%
        </div>
    );
}

interface IChartBarProps {
    value: number;
    isPrimary: boolean;
}

function ChartBar(props: IChartBarProps) {
    let color = props.isPrimary
        ? "var(--max-color-chart-primary)"
        : "var(--max-color-chart-accent)";
    let size = props.value + "%";
    let remainderSize = "0%";
    if (100 - props.value > 0) {
        remainderSize = 100 - props.value + "%";
    }

    return (
        <div
            style={{
                width: "100%",
                height: "1.5rem",
                position: "relative",
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    border: `1px solid ${color}`,
                    height: "1rem",
                    margin: 0,
                    top: "50%",
                    position: "absolute",
                    transform: "translate(0, -50%)",
                }}
            >
                <div
                    style={{
                        background: color,
                        width: size,
                        height: "100%",
                    }}
                />
                <div
                    style={{
                        // background: "var(--max-color-chart-grey-secondary)",
                        background: "white",
                        width: remainderSize,
                        height: "100%",
                    }}
                />
            </div>
        </div>
    );
}
