import { Tab, Tabs } from "@mui/material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { TabPanel } from "../../shared/tab/TabPanel";
import Solar from "./solar";
import { Link, useLocation } from "react-router-dom";
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import HvacIcon from '@mui/icons-material/Hvac';
import MonitorIcon from '@mui/icons-material/Monitor';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';

function AssetPerformance() {
    const location = useLocation();
    const path = "/" + location.pathname.split("/", 2)[1];
    const tab = location.pathname.split("/", 3)[2];

    return (
        <>
            <Tabs value={tab} textColor="secondary" indicatorColor="secondary">
                <Tab
                    icon={<WbSunnyIcon />}
                    iconPosition="start"
                    label="Solar"
                    value="solar"
                    to={path + "/solar"}
                    component={Link}
                />
                <Tab
                    icon={<BatteryChargingFullIcon />}
                    iconPosition="start"
                    label="Battery"
                    value="1"
                    to={path + "/solar"}
                    component={Link}
                />
                <Tab
                    icon={<HvacIcon />}
                    iconPosition="start"
                    label="HVAC"
                    value="1"
                    to={path + "/solar"}
                    component={Link}
                />
                <Tab
                    icon={<ElectricCarIcon />}
                    iconPosition="start"
                    label="EV Fleet"
                    value="1"
                    to={path + "/solar"}
                    component={Link}
                />
                <Tab
                    icon={<MonitorIcon />}
                    iconPosition="start"
                    label="EMS"
                    value="1"
                    to={path + "/solar"}
                    component={Link}
                />
            </Tabs>

            <TabPanel value={tab} index="solar">
                <Solar />
            </TabPanel>
        </>
    );
}

export default AssetPerformance;
