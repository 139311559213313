import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store/store";
import Main from "./components/layout/main/Main";
import NavMain from "./components/layout/navigation/NavMain";
import getDefaultMuiTheme from "./themes/MuiTheme";
import FilterInit from "components/shared/global-filter/FilterInit";

const theme = getDefaultMuiTheme();

function InternalApp() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <FilterInit />
                <BrowserRouter>
                    <Box className="body" sx={{ display: "flex" }}>
                        <NavMain />
                        <Main />
                    </Box>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    );
}

export default InternalApp;
