import { styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.columnHeader}`]: {
        backgroundColor: "var(--max-color-table-header)",
    },
    [`& .${gridClasses.columnSeparator}`]: {
        color: "var(--max-color-table-header-seperator)"
    },
    
    [`& .${gridClasses.row}`]: {
        "&:hover, &.Mui-hovered": {
            backgroundColor: "var(--max-color-table-hover)",
        },
        "&.Mui-selected": {
            backgroundColor: "var(--max-color-table-selected)",
            "&:hover, &.Mui-hovered": {
                backgroundColor: "var(--max-color-table-hover)",
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: "var(--max-color-table-selected)",
                },
            },
        },
    },
}));
