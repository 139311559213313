import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { IEnergyPointData } from "data/energy/energy-point/models";
import { Card, CardContent, CardHeader } from "@mui/material";
import DataUnits from "components/shared/label/DataUnits";
import { useEffect, useState } from "react";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import { renderCellChartBarAccentWithTextDp0 } from "components/shared/data-grid/CellChartBar";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface IEmissionSourcesProps {
    energyPointData: IEnergyPointData;
}

function EmissionSources(props: IEmissionSourcesProps) {
    const gasTotal = 6099.738093 / 1000;
    const dieselTotal = 4050 / 1000;

    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Type",
            type: "string",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "emissions",
            headerName: "Emissions",
            type: "number",
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderHeader: renderColumnHeader,
        },
        {
            field: "ratio",
            headerName: "Ratio",
            headerAlign: "center",
            type: "number",
            minWidth: 200,
            flex: 2,
            sortable: false,
            renderHeader: renderColumnHeader,
            renderCell: renderCellChartBarAccentWithTextDp0,
        },
    ];

    function getRows() {
        if (props.energyPointData.data.length < 1) {
            return [];
        }

        let total =
            gasTotal +
            dieselTotal +
            (3452.691529) / 1000;

        let rows = [];
        rows.push({
            id: "Electricity",
            emissions: (
                (3452.691529) /
                1000
            ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio:
                ((3452.691529) /
                    1000 /
                    total) *
                100,
        });
        rows.push({
            id: "Gas",
            emissions: gasTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: (gasTotal / total) * 100,
        });
        rows.push({
            id: "Diesel",
            emissions: dieselTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: (dieselTotal / total) * 100,
        });
        rows.push({
            id: "Total",
            emissions: total.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            ratio: 100,
        });

        return rows;
    }

    return (
        <Card sx={{ height: "100%" }}>
            <CardHeader title="Emission Sources" />
            <CardContent>
                <StyledDataGrid
                    key={dataGridKey}
                    rows={getRows()}
                    columns={columns}
                    disableColumnMenu={true}
                    hideFooter={true}
                    checkboxSelection={false}
                    hideFooterSelectedRowCount={true}
                    density="compact"
                    autoHeight={true}
                    loading={props.energyPointData.isLoading}
                />

                {DataUnits("Table Units: t")}
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    energyPointData: state.energyPointData,
});

export default connect(mapStateToProps)(EmissionSources);
