import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Stack,
    SxProps,
} from "@mui/material";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { IEnergyPointData } from "data/energy/energy-point/models";
import ParkIcon from "@mui/icons-material/Park";
import FactoryIcon from "@mui/icons-material/Factory";
import Co2Icon from "@mui/icons-material/Co2";
import DataUnits from "components/shared/label/DataUnits";

interface IMetricsProps {
    energyPointData: IEnergyPointData;
}

function Metrics(props: IMetricsProps) {
    function createMetric(
        isLoading: boolean,
        metric: string,
        value: string,
        units: string,
        text: string
    ) {
        let color = "var(--max-color-primary)";

        if (metric === "production") {
            color = "var(--max-color-accent)";
        }

        if (metric === "co2") {
            color = "var(--max-color-grey)";
        }

        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    position: "relative",
                    height: "4rem",
                }}
            >
                <div
                    style={{
                        marginRight: "var(--max-length-medium3)",
                        height: "100%",
                        aspectRatio: "1",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            border: `0.5rem ${color} solid`,
                            borderRadius: "50%",
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        {createIcon(metric)}
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        position: "relative",
                        minWidth: "10rem",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translate(0, -50%)",
                        }}
                    >
                        {isLoading ? (
                            <Box
                                sx={{
                                    height: "9rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress color="primary" />
                            </Box>
                        ) : (
                            <>
                                <div style={{ display: "flex" }}>
                                    <div
                                        style={{
                                            fontSize:
                                                "var(--max-font-size-title)",
                                            // fontWeight: "bold",
                                            color: "var(--max-color-title)",
                                        }}
                                    >
                                        {value}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "var(--max-length-small3)",
                                            // fontWeight: "bold"
                                            color: "var(--max-color-title)",
                                        }}
                                    >
                                        {units}
                                    </div>
                                </div>
                                <div>{text}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    function createIcon(metric: string) {
        let sx: SxProps = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        };

        if (metric === "production") {
            return <FactoryIcon color="secondary" sx={sx} fontSize="large" />;
        } else if (metric === "co2") {
            return <Co2Icon color="info" sx={sx} fontSize="large" />;
        } else if (metric === "trees") {
            return <ParkIcon color="primary" sx={sx} fontSize="large" />;
        }
    }

    return (
        <Card>
            <CardHeader title="Key Performance Indicators" />
            <CardContent>
                <Stack direction="column" spacing={2}>
                    {createMetric(
                        props.energyPointData.isLoading,
                        "production",
                        "6.95 kg/hr",
                        "",
                        "CO2 per Production Hour"
                    )}
                    {createMetric(
                        props.energyPointData.isLoading,
                        "co2",
                        // (
                        //     props.energyPointData.consumptionTotal * 0.3 / 1000
                        // ).toLocaleString(undefined, {
                        //     minimumFractionDigits: 0,
                        //     maximumFractionDigits: 2,
                        // }) + " t",
                        "30.1 t",
                        "",
                        "Carbon Footprint Savings"
                    )}
                    {createMetric(
                        props.energyPointData.isLoading,
                        "trees",
                        // (
                        //     (props.energyPointData.consumptionTotal * 0.3) /
                        //     31.55
                        // ).toLocaleString(undefined, {
                        //     minimumFractionDigits: 0,
                        //     maximumFractionDigits: 0,
                        // }) + " Trees",
                        "533 Trees", 
                        "",
                        "Equivalent Trees Planted"
                    )}
                    {DataUnits("Metrics are for the calendar year")}
                </Stack>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    energyPointData: state.energyPointData,
});

export default connect(mapStateToProps)(Metrics);
