import { Card, CardContent, CardHeader } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import {
    IElectricalAsset,
    IElectricalAssetData,
} from "data/assets/electrical-asset/models";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import { renderCellChartBarAccentWithTextDp0 } from "components/shared/data-grid/CellChartBar";
import { renderCellSplitAlignRight2 } from "components/shared/data-grid/CellValueSplit";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";

interface IAssetByGroupProps {
    electricalAssetData: IElectricalAssetData;

    cardHeader: string;
    type: string;
    groups: string[];
    getFilteredData: (group: string) => IElectricalAsset[];
}

function AssetByGroup(props: IAssetByGroupProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: props.type,
            type: "string",
            minWidth: 100,
            flex: 2,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
        {
            field: "consumption",
            headerName: `${props.type} Consumption`,
            headerAlign: "center",
            type: "number",
            minWidth: 200,
            flex: 2,
            description: `${props.type} consumption of total site consumption`,
            renderHeader: renderColumnHeader,
            renderCell: renderCellChartBarAccentWithTextDp0,
        },
        {
            field: "topAsset",
            headerName: `Top Asset for ${props.type}`,
            type: "string",
            minWidth: 100,
            flex: 2,
            description: `Top asset in ${props.type} by consumption`,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "assetQuantity",
            headerName: `Quantity in ${props.type}`,
            headerAlign: "right",
            minWidth: 200,
            flex: 2,
            description: `Number of assets in ${props.type}`,
            renderHeader: renderColumnHeader,
            renderCell: renderCellSplitAlignRight2,
        },
        {
            field: "assetConsumption",
            headerName: "Asset Consumption",
            headerAlign: "center",
            type: "number",
            minWidth: 200,
            flex: 2,
            description: `Asset consumption of total ${props.type} consumption`,
            renderHeader: renderColumnHeader,
            renderCell: renderCellChartBarAccentWithTextDp0,
        },
    ];

    const [pageSize, setPageSize] = useState(5);

    type KeyValue = {
        key: string;
        value: number;
    };

    function getRows() {
        if (props.electricalAssetData.data.length < 1) {
            return [];
        }

        let consumptionTotal = props.electricalAssetData.data
            .map((x) => x.energyKwhPa)
            .reduce((prev: number, curr: number) => {
                return prev + curr;
            });

        let rows = props.groups.map((group) => {
            let filteredData = props.getFilteredData(group);

            let consumption = filteredData
                .map((x) => x.energyKwhPa)
                .reduce((prev: number, curr: number) => {
                    return prev + curr;
                });

            let assetGroups: KeyValue[] = [];
            filteredData.forEach((x) => {
                let assetGroup = assetGroups.find((g) => g.key === x.type);
                if (assetGroup === undefined) {
                    assetGroup = { key: x.type, value: 0 };
                    assetGroups.push(assetGroup);
                }

                assetGroup.value += x.energyKwhPa;
            });

            assetGroups.sort((a, b) => {
                return b.value - a.value;
            });

            let assetQuantity =
                assetGroups.length +
                "-" +
                ((assetGroups.length / filteredData.length) * 100).toFixed(0) +
                "%";

            return {
                id: group,
                quantity: filteredData.length,
                consumption: (consumption / consumptionTotal) * 100,
                topAsset: assetGroups[0].key,
                assetQuantity: assetQuantity,
                assetConsumption: (assetGroups[0].value / consumption) * 100,
            };
        });

        rows.sort((a, b) => {
            return b.consumption - a.consumption;
        });

        return rows;
    }

    return (
        <Card>
            <CardHeader title={props.cardHeader} />
            <CardContent>
                <StyledDataGrid
                    key={dataGridKey}
                    rows={getRows()}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    checkboxSelection={false}
                    hideFooterSelectedRowCount={true}
                    density="compact"
                    autoHeight={true}
                    loading={props.electricalAssetData.isLoading}
                />
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    electricalAssetData: state.electricalAssetData,
});

export default connect(mapStateToProps)(AssetByGroup);
