import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

export function renderCellWrapped(params: GridRenderCellParams<string>) {
    return (
        <Box
            sx={{
                marginTop: "var(--max-length-small3)",
                marginBottom: "var(--max-length-small3)",
            }}
        >
            {params.value}
        </Box>
    );
}
