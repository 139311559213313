import headerReducer from "components/layout/header/reducer";
import electricalAssetReducer from "data/assets/electrical-asset/reducer";
import lightingAssetReducer from "data/assets/lighting-asset/reducer";
import energyLoadPointReducer from "data/energy/energy-load-point/reducer";
import energyPointReducer from "data/energy/energy-point/reducer";
import energyEstimationReducer from "data/energy/energy-estimation/reducer";
import globalFilterReducer from "data/global-filter/reducer";
import projectReducer from "data/projects/project/reducer";
import secScoreGroupReducer from "data/sec/secScore/reducer";
import { combineReducers } from "redux";
import IStoreState from "store/IStoreState";
import userReducer from "data/user/reducer";
import invoiceReducer, { diselInvoiceReducer, gasInvoiceReducer } from "data/finance/invoices/reducer";
import financialModelReducer from "data/finance/financial-model/reducer";
import financialMetricReducer from "data/finance/financial-metric/reducer";

const rootReducer = combineReducers<IStoreState>({
    pageTitle: headerReducer,
    energyPointData: energyPointReducer,
    energyEstimationData: energyEstimationReducer,
    energyLoadPointData: energyLoadPointReducer,
    projectData: projectReducer,
    electricalAssetData: electricalAssetReducer,
    lightingAssetData: lightingAssetReducer,
    userData: userReducer,
    globalFilter: globalFilterReducer,
    secScoreGroupData: secScoreGroupReducer,
    invoiceData: invoiceReducer,
    gasInvoiceData: gasInvoiceReducer,
    diselInvoiceData: diselInvoiceReducer,
    financialModelData: financialModelReducer,
    financialMetricData: financialMetricReducer
});

export default rootReducer;
