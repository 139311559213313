import { PointAggregation } from "../PointAggregation";
import {
    ActionType,
    ActionTypeKey,
    IUpdateEnergyPointDataAction,
    IUpdateEnergyPointDataLoadingAction,
} from "./actions";
import { IEnergyPoint, IEnergyPointData } from "./models";

const INITIAL_STATE: IEnergyPointData = {
    data: [],
    requireLoad: true,
    hasData: false,
    isLoading: false,
    pointAggregation: PointAggregation.YEAR,
    fromDate: "2022-01-01",
    toDate: "2022-12-31",
    generationTotal: 0,
    consumptionTotal: 0,
    exportToGridTotal: 0,
    importFromGridTotal: 0,
    selfConsumptionTotal: 0,
};

export default function energyPointReducer(
    state: IEnergyPointData = INITIAL_STATE,
    action: ActionType
): IEnergyPointData {
    switch (action.type) {
        case ActionTypeKey.UPDATE_ENERGY_POINT_DATA:
            return updateEnergyPointData(action);
        case ActionTypeKey.UPDATE_ENERGY_POINT_DATA_LOADING:
            return updateEnergyPointDataLoading(action, state);
        default:
            return state;
    }
}

function updateEnergyPointData(
    action: IUpdateEnergyPointDataAction
): IEnergyPointData {
    let generationTotal = 0;
    let consumptionTotal = 0;
    let exportToGridTotal = 0;
    let importFromGridTotal = 0;
    let selfConsumptionTotal = 0;

    if (action.payload.data.length > 0) {
        generationTotal = action.payload.data
            .map((x) => x.generation)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        consumptionTotal = action.payload.data
            .map((x) => x.consumption)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        exportToGridTotal = action.payload.data
            .map((x) => x.exportToGrid)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        importFromGridTotal = action.payload.data
            .map((x) => x.importFromGrid)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
        selfConsumptionTotal = action.payload.data
            .map((x) => x.selfConsumption)
            .reduce((prev: number, value: number) => {
                return prev + value;
            });
    }

    return {
        data: action.payload.data,
        requireLoad: false,
        hasData: action.payload.data.length > 0,
        isLoading: false,
        pointAggregation: action.payload.pointAggregation,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
        generationTotal: generationTotal,
        consumptionTotal: consumptionTotal,
        exportToGridTotal: exportToGridTotal,
        importFromGridTotal: importFromGridTotal,
        selfConsumptionTotal: selfConsumptionTotal,
    };
}

function updateEnergyPointDataLoading(
    action: IUpdateEnergyPointDataLoadingAction,
    currentState: IEnergyPointData
): IEnergyPointData {
    return {
        ...currentState,
        requireLoad: false,
        isLoading: action.payload.isLoading,
    };
}
