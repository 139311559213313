import {
    Autocomplete,
    AutocompleteChangeDetails,
    Card,
    CardContent,
    CardHeader,
    Stack,
    TextField,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { renderCellWithTooltip } from "components/shared/data-grid/CellTooltip";
import { renderColumnHeader } from "components/shared/data-grid/ColumnHeader";
import { StyledDataGrid } from "components/shared/data-grid/StyledDataGrid";
import { ILightingAssetData } from "data/assets/lighting-asset/models";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";

interface IAssetListProps {
    lightingAssetData: ILightingAssetData;
}

function AssetList(props: IAssetListProps) {
    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        // DataGrid has a bug where resizing causes end columns to get dropped
        // Workaround is to force component to rerender by updating key
        let timeout: any;
        const handleResize = () => {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setDataGridKey(dataGridKey + 1);
            }, 100);
        };
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    });

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Type",
            type: "string",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "tech",
            headerName: "Tech",
            type: "string",
            minWidth: 100,
            flex: 3,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "room",
            headerName: "Room",
            type: "string",
            minWidth: 100,
            flex: 3,
            renderHeader: renderColumnHeader,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            type: "number",
            minWidth: 100,
            flex: 1,
            renderHeader: renderColumnHeader,
        },
    ];

    const [pageSize, setPageSize] = useState(10);
    const [roomFilter, setRoomFilter] = useState("");
    const [techFilter, setTechFilter] = useState("");

    function getRows() {
        let filteredData = props.lightingAssetData.data
            .filter((x) => x.room.trim() === roomFilter || roomFilter === "")
            .filter((x) => x.tech.trim() === techFilter || techFilter === "");

        if (filteredData.length < 1) {
            return [];
        }

        let id = 0;
        let techs = Array.from(
            new Set(filteredData.map((x) => x.tech))
        );
        let data: any[] = [];

        techs.forEach((tech) => {
            let rooms = Array.from(
                new Set(
                    filteredData
                        .filter((x) => x.tech === tech)
                        .map((x) => x.room)
                )
            );

            rooms.forEach((room) => {
                let quantity = filteredData
                    .filter((x) => x.tech === tech && x.room === room)
                    .map((x) => x.noOfLamps)
                    .reduce((prev: number, curr: number) => {
                        return prev + curr;
                    });

                id += 1;
                data.push({
                    id: id,
                    tech: tech,
                    room: room,
                    quantity: quantity,
                });
            });
        });

        return data;
    }

    function onRoomFilterChange(
        event: React.SyntheticEvent,
        value: string | null,
        reason: string,
        details?: AutocompleteChangeDetails<string> | undefined
    ) {
        if (value === null) {
            setRoomFilter("");
        } else {
            setRoomFilter(value);
        }
    }

    function onTechFilterChange(
        event: React.SyntheticEvent,
        value: string | null,
        reason: string,
        details?: AutocompleteChangeDetails<string> | undefined
    ) {
        if (value === null) {
            setTechFilter("");
        } else {
            setTechFilter(value);
        }
    }

    return (
        <Card>
            <CardHeader title="Assets List" />
            <CardContent>
                <Stack
                    spacing={2}
                    direction="row"
                    sx={{ marginBottom: "1rem" }}
                >
                    <Autocomplete
                        disablePortal
                        id="assetRegisterAssetListTechFilter"
                        options={Array.from(
                            new Set(
                                props.lightingAssetData.data.map((x) =>
                                    x.tech.trim()
                                )
                            )
                        ).sort()}
                        sx={{ width: 300 }}
                        size="small"
                        renderInput={(params) => (
                            <TextField {...params} label="Tech" />
                        )}
                        onChange={onTechFilterChange}
                    />
                    <Autocomplete
                        disablePortal
                        id="assetRegisterAssetListRoomFilter"
                        options={Array.from(
                            new Set(
                                props.lightingAssetData.data.map((x) =>
                                    x.room.trim()
                                )
                            )
                        ).sort()}
                        sx={{ width: 300 }}
                        size="small"
                        renderInput={(params) => (
                            <TextField {...params} label="Room" />
                        )}
                        onChange={onRoomFilterChange}
                    />
                </Stack>
                <StyledDataGrid
                    key={dataGridKey}
                    rows={getRows()}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    checkboxSelection={false}
                    hideFooterSelectedRowCount={true}
                    density="compact"
                    autoHeight={true}
                    loading={props.lightingAssetData.isLoading}
                    columnVisibilityModel={{ id: false }}
                />
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    lightingAssetData: state.lightingAssetData,
});

export default connect(mapStateToProps)(AssetList);
