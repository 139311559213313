import { styled, ToggleButton } from "@mui/material";

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    "&.MuiToggleButton-root": {
        backgroundColor: "white",
    },
    "&.MuiToggleButton-primary, &.MuiToggleButton-standard": {
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: "var(--max-color-primary)",
            border: "1px solid var(--max-color-primary)",
        },
    },
    "&.MuiToggleButton-secondary": {
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: "var(--max-color-accent)",
            border: "1px solid var(--max-color-accent)",
        },
    },
}));
