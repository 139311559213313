import { GridColumnHeaderParams } from "@mui/x-data-grid";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from "@mui/material";

var style = getComputedStyle(document.body);
var iconColor = style.getPropertyValue("--max-color-text");
var iconSize = style.getPropertyValue("--max-font-size-icon");

export function renderColumnHeader(params: GridColumnHeaderParams) {
    const desc = params.colDef.description ?? "";

    return params.colDef.description === undefined ? (
        <div>{params.colDef.headerName}</div>
    ) : (
        <Tooltip title={desc}>
            <div style={{ display: "flex" }}>
                <div>{params.colDef.headerName}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <InfoOutlinedIcon
                        style={{
                            color: iconColor,
                            fontSize: iconSize,
                            marginLeft: "var(--max-length-small1)"
                        }}
                    />
                </div>
            </div>
        </Tooltip>
    );
}
