import { Stack, Tab, Tabs } from "@mui/material";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { TabPanel } from "../../shared/tab/TabPanel";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { loadProjectData } from "data/projects/project/services";
import Projects from "./Projects";
import { IGlobalFilter } from "data/global-filter/models";
import { connect } from "react-redux";
import IStoreState from "store/IStoreState";
import Gantt from "./Gantt";

interface IProjectManagementProps {
    globalFilter: IGlobalFilter;
}

function ProjectManagement(props: IProjectManagementProps) {
    useEffect(() => {
        loadProjectData(true, props.globalFilter.account.id);
    });

    const location = useLocation();
    const path = "/" + location.pathname.split("/", 2)[1];
    const tab = location.pathname.split("/", 3)[2];

    return (
        <>
            <Tabs value={tab} textColor="secondary" indicatorColor="secondary">
                <Tab
                    icon={<AssignmentTurnedInOutlinedIcon />}
                    iconPosition="start"
                    label="Projects"
                    value="projects"
                    to={path + "/projects"}
                    component={Link}
                />
            </Tabs>

            <TabPanel value={tab} index="projects">
                <Stack spacing={2}>
                    <Gantt />
                    <Projects />
                </Stack>
            </TabPanel>
        </>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    globalFilter: state.globalFilter,
});

export default connect(mapStateToProps)(ProjectManagement);
