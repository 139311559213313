import { connect } from "react-redux";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Stack,
    SxProps,
    TextField,
} from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import IStoreState from "store/IStoreState";
import { useState, useEffect } from "react";
import { dataGridFix } from "components/shared/data-grid/DataGridHelper";
import { IFinancialModelData } from "data/finance/financial-model/models";
import { loadFinancialModelData } from "data/finance/financial-model/services";
import DataUnits from "components/shared/label/DataUnits";
import { loadFinancialMetricData } from "data/finance/financial-metric/services";
import { IFinancialMetricData } from "data/finance/financial-metric/models";
import ParkIcon from "@mui/icons-material/Park";
import PeopleIcon from "@mui/icons-material/People";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EngineeringIcon from '@mui/icons-material/Engineering';
import BalanceIcon from '@mui/icons-material/Balance';

interface IComparisonBreakdownProp {
    financialModelData: IFinancialModelData;
    financialMetricData: IFinancialMetricData;
}

function ComparisonBreakdown(props: IComparisonBreakdownProp) {
    useEffect(() => {
        loadFinancialModelData(
            true,
            5,
            growthPercent,
            greenPowerPricing,
            offsetPricing
        );
        loadFinancialMetricData(true, 5);
    });

    const [growthPercent, setGrowthPercent] = useState(0);
    const [greenPowerPricing, setGreenPowerPricing] = useState(1);
    const [offsetPricing, setOffsetPricing] = useState(1);

    const [dataGridKey, setDataGridKey] = useState(1);

    useEffect(() => {
        dataGridFix(dataGridKey, setDataGridKey);
    }, [dataGridKey]);

    var style = getComputedStyle(document.body);
    var barColor1 = style.getPropertyValue("--max-color-chart-primary");
    var barColor2 = style.getPropertyValue("--max-color-chart-grey");

    ChartJS.register(...registerables);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top" as const,
            },
        },
    };

    function createChart() {
        return (
            <>
                <div
                    style={{
                        height: "var(--max-size-chart-large)",
                        width: "99%",
                    }}
                >
                    <Chart type="bar" options={options} data={getChartData()} />
                </div>
                {props.financialModelData.isLoading ? (
                    <LinearProgress />
                ) : (
                    <></>
                )}

                {DataUnits("Chart Units: $")}
            </>
        );
    }

    function getChartData() {
        let labels = props.financialModelData.data.map(
            (x: { year: any }) => x.year
        );

        return {
            labels,
            datasets: [
                {
                    type: "bar" as const,
                    label: "SEC Culmative Expenses",
                    data: props.financialModelData.data.map(
                        (x: { secCashflow: number }) => x.secCashflow
                    ),
                    backgroundColor: barColor1,
                },
                {
                    type: "bar" as const,
                    label: "Green Culmative Expenses",
                    data: props.financialModelData.data.map(
                        (x: { greenPowerCashflow: number }) =>
                            x.greenPowerCashflow
                    ),
                    backgroundColor: barColor2,
                },
            ],
        };
    }

    function isNumeric(value: string) {
        return /^\d+$/.test(value);
    }

    const handleGrowthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNumeric(event.target.value)) {
            setGrowthPercent(Number(event.target.value));
        } else {
            setGrowthPercent(0);
        }
    };

    const growthPercentInput = (
        <div style={{ width: "150px" }}>
            <TextField
                id="outlined-basic"
                label="Growth Percent"
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                    ),
                }}
                value={growthPercent}
                onChange={handleGrowthChange}
            />
        </div>
    );

    const greenPowerPricingFilter = (
        <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="filter-greenpower-label">
                Green Power Pricing
            </InputLabel>
            <Select
                labelId="filter-greenpower-select"
                id="filter-greenpower"
                value={greenPowerPricing}
                label="GreenPowerPricing"
                onChange={(event) => {
                    setGreenPowerPricing(Number(event.target.value));
                    setDataGridKey(dataGridKey + 1);
                }}
            >
                <MenuItem key={1} value={1}>
                    Low
                </MenuItem>
                <MenuItem key={2} value={2}>
                    Medium
                </MenuItem>
                <MenuItem key={3} value={3}>
                    High
                </MenuItem>
            </Select>
        </FormControl>
    );

    const offsetPricingFilter = (
        <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="filter-offset-label">Offset Pricing</InputLabel>
            <Select
                autoWidth
                labelId="filter-offset-select"
                id="filter-offset"
                value={offsetPricing}
                label="OffsetPricing"
                onChange={(event) => {
                    setOffsetPricing(Number(event.target.value));
                    setDataGridKey(dataGridKey + 1);
                }}
            >
                <MenuItem key={1} value={1}>
                    Low
                </MenuItem>
                <MenuItem key={2} value={2}>
                    Medium
                </MenuItem>
                <MenuItem key={3} value={3}>
                    High
                </MenuItem>
            </Select>
        </FormControl>
    );

    function createMetric(isLoading: boolean, metric: string, value: string) {
        let color = "var(--max-color-primary)";

        if (metric === "Energy Resilience") {
            color = "var(--max-color-accent)";
        }

        if (metric === "Circular Energy Economy") {
            color = "var(--max-color-grey)";
        }

        return (
            <div
                style={{
                    width: "100%",
                    marginBottom: "var(--max-length-medium1)",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            height: "4rem",
                        }}
                    >
                        <div
                            style={{
                                marginRight: "var(--max-length-medium3)",
                                height: "100%",
                                aspectRatio: "1",
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    border: `0.5rem ${color} solid`,
                                    borderRadius: "50%",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                {createIcon(metric)}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            // position: "relative",
                            // minWidth: "10rem",
                        }}
                    >
                        <div
                            style={{
                                fontSize: "var(--max-font-size-title)",
                                // fontWeight: "bold",
                                color: "var(--max-color-title)",
                                // marginBottom: "var(--max-length-medium1)",
                            }}
                        >
                            {metric}
                        </div>
                        <div
                            style={
                                {
                                    // position: "absolute",
                                    // top: "50%",
                                    // transform: "translate(0, -50%)",
                                }
                            }
                        >
                            {isLoading ? (
                                <Box
                                    sx={{
                                        height: "9rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CircularProgress color="primary" />
                                </Box>
                            ) : (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <div
                                            style={{
                                                fontSize:
                                                    "var(--max-font-size-title)",
                                                color: "var(--max-color-title)",
                                            }}
                                        >
                                            {value}
                                        </div>
                                        {/* <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginLeft:
                                                    "var(--max-length-small3)",
                                                color: "var(--max-color-title)",
                                            }}
                                        >
                                            {text}
                                        </div> */}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function createIcon(metric: string) {
        let sx: SxProps = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        };

        if (metric === "Net Present Value") {
            return <BalanceIcon color="primary" sx={sx} fontSize="large" />;
        } else if (metric === "Internal Rate of Return") {
            return (
                <CurrencyExchangeIcon color="primary" sx={sx} fontSize="large" />
            );
        } else if (metric === "Carbon Abatement Projects") {
            return <EngineeringIcon color="primary" sx={sx} fontSize="large" />;
        } else if (metric === "Capital Investment") {
            return <AttachMoneyIcon color="primary" sx={sx} fontSize="large" />;
        }
    }

    return (
        <Card>
            <CardHeader title="SEC vs Green Power Culmative Expenses" />
            <CardContent>
                <Stack spacing={2} direction="column">
                    {createChart()}
                    <Stack direction="row" spacing={2}>
                        {/* {growthPercentInput} */}
                        {greenPowerPricingFilter}
                        {offsetPricingFilter}
                    </Stack>
                    <Stack direction="row" spacing={4}>
                        {createMetric(
                            false,
                            "Net Present Value",
                            "$"+
                            (
                                props.financialMetricData.data.npv / 1000
                            ).toString() + "K"
                        )}
                        {createMetric(
                            false,
                            "Internal Rate of Return",
                            props.financialMetricData.data.irr.toString() + "%"
                        )}
                        {createMetric(
                            false,
                            "Carbon Abatement Projects",
                            props.financialMetricData.data.carbonAbatementProjects.toString()
                        )}
                        {createMetric(
                            false,
                            "Capital Investment",
                            "$"+
                            (
                                props.financialMetricData.data
                                    .capitalInvestment / 1000
                            ).toString() + "K"
                        )}
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state: IStoreState) => ({
    financialModelData: state.financialModelData,
    financialMetricData: state.financialMetricData,
});

export default connect(mapStateToProps)(ComparisonBreakdown);
